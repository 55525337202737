import ClientConfigurationService from './clientConfigurationService';

const AnalyticsService = {
    sendEventWithClient: (eventName) => {
        ClientConfigurationService.getClientConfiguration2(true, null, (data) => {
            let clientName = 'Public';

            if (data !== '') {
                clientName = data.clientName;
            }
            
            if (eventName && clientName) {
                window.gtag("event", eventName, {
                    client: clientName
                });
            }
        });
    }
}

export default AnalyticsService;