import ErrorDisplay from './errorDisplay';
import Settings from './settings';
import axios from 'axios';

const AuthService = {
    
    getAuthToken: (callback) => {
        getAuthToken(false, callback);
    },

    getAuthToken: (allowAnonymous, callback) => {
        
        if (allowAnonymous) {
            callback("");
            return;
        }
        //get it from localStorage
        var token = localStorage.getItem('user_token');
        if (token && token !== "") {

            try {
                // check token expiration
                const payloadBase64 = token.split('.')[1];
                const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
                const decoded = JSON.parse(decodedJson)
                const exp = decoded.exp;
                const expired = (Date.now() >= exp * 1000)

                if (expired) {
                    //localStorage.clear();
                    localStorage.setItem('user_token','');
                    if (window.location.pathname !== "/") {
                        document.location = "/";
                    } else {
                        callback('');
                    }
                } else {
                    callback(JSON.parse(token));
                }
            
            }
            catch {
                localStorage.setItem('user_token','');
                callback('');
            }

            return;
        }

        // if not valid log them out
        if (!token) {
            //localStorage.clear();
            if (window.location.pathname !== "/") {
                document.location = "/";
            } else {
                callback('');
            }
        } else {
            if (allowAnonymous) {
                callback('');
            } else {
                ErrorDisplay.renderError("Error retrieving authentication token");
            }
        }
    },

    clearAuth: () => {
        //localStorage.clear();
        localStorage.setItem('user_token','');
        //document.location = "/";
    }
};


export default AuthService;