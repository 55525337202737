import React, { useEffect, useState } from 'react';
import logoImage from "../../assets/img/GMMI_FEP_ProviderSearch_Color.png"
import sagicCorImage from "../../assets/img/Sagicor_Logo_Horizontal_SearchEngine.png"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AppLayout from './AppLayout';

const EmptyLayout = (props) => {

  return (
    <div >
      {props.secure
        ?
        <>
          <UnauthenticatedTemplate>
            <AppLayout></AppLayout>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <header className="u-header u-header--sticky-top u-header--toggle-section u-header--change-appearance">
              <div className="u-header__section u-header__section--light g-bg-white-opacity-0_8 g-py-10"
                data-header-fix-moment-exclude="g-bg-white-opacity-0_8 g-py-10" data-header-fix-moment-classes="g-bg-white u-shadow-v18 g-py-0">
                <nav className="navbar navbar-expand-lg">
                  <div style={{ display: 'flex' }}>
                    {props.displaySagicor ? <img src={sagicCorImage} alt="Sagicor_Logo_Horizontal_SearchEngine" /> : null}
                    
                    {props.displayLogoWithLink ? <a href="https://www.gmmi.com/" className="navbar-brand"><img src={logoImage} alt="GMMI Cost Containment" /></a> : <img src={logoImage} alt="GMMI_FEP_ProviderSearch_Color" /> }

                  </div>
                </nav>
              </div>
            </header>
            <div className="body-marginTop">
              {props.children}
            </div>
          </AuthenticatedTemplate>
        </>
        :
        <>
          <header className="u-header u-header--sticky-top u-header--toggle-section u-header--change-appearance">
            <div className="u-header__section u-header__section--light g-bg-white-opacity-0_8 g-py-10"
              data-header-fix-moment-exclude="g-bg-white-opacity-0_8 g-py-10" data-header-fix-moment-classes="g-bg-white u-shadow-v18 g-py-0">
              <nav className="navbar navbar-expand-lg">
                <div style={{ display: 'flex' }}>
                  {props.displaySagicor ? <img src={sagicCorImage} alt="Sagicor_Logo_Horizontal_SearchEngine" /> : null}
                  {props.displayLogoWithLink ? <a href="https://www.gmmi.com/" className="navbar-brand"><img src={logoImage} alt="GMMI Cost Containment" /></a> : <img src={logoImage} alt="GMMI_FEP_ProviderSearch_Color" /> }

                </div>
              </nav>
            </div>
          </header>
          <div className="body-marginTop">
            {props.children}
          </div>
        </>
      }
    </div>
  );
}

export default EmptyLayout;
