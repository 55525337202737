import AuthService from './authService';
import ErrorDisplay from './errorDisplay';
import ClientConfigurationService from './clientConfigurationService'

import axios from 'axios';

const ReportsService = {

    getAvailableReports: (callback) => {
        ClientConfigurationService.getClientConfiguration((data) => {
            console.log(data);

            callback(data.reports);
        });
    },

    getAvailableClients: (callback) => {
        ClientConfigurationService.getClientConfiguration((data) => {
            console.log(data.clientCodes);

            callback(data.clientCodes);
        });
    }

}

export default ReportsService;