import React, { useEffect, useState } from 'react';
import './BasicLayout.css'
import logoImage from "../../assets/img/GMMI_FEP_ProviderSearch_Color.png"
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useAuth } from "../../providers/authProvider";
import Footer from '../Footer/Footer';
import { BrowserUtils } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AppLayout from './AppLayout';

const BasicLayout = (props) => {

  const [currentUser, setCurrentUser] = useState('');
  const { instance, inProgress } = useMsal();
  const { removeToken } = useAuth();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setCurrentUser(instance.getAllAccounts()[0]?.name);
  }, [inProgress, instance]);

  const handleLogout = () => {
    removeToken();
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe()
    })
  }

  return (
    <div >
      <UnauthenticatedTemplate>
        <AppLayout></AppLayout>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <header className="u-header u-header--sticky-top u-header--toggle-section u-header--change-appearance">
          <div className="u-header__section u-header__section--hidden u-header__section--dark g-bg-gmmi-blue g-py-10">
            <div className="container">
              <div className="row flex-column flex-md-row justify-content-between align-items-center text-uppercase g-font-weight-600 g-color-white">
                <div className="col-auto g-px-15">

                </div>
                <div className="col-auto g-pos-rel g-px-15">
                  <ul className="list-inline g-overflow-hidden g-font-size-12 g-mt-minus-10 g-mx-minus-4 mb-0">
                    <li className="list-inline-item g-mx-4 g-mt-10">
                      You are logged in as: {currentUser}
                    </li>
                    <li className="list-inline-item g-mx-4 g-mt-10">
                      |
                    </li>
                    <li className="list-inline-item g-mx-4 g-mt-10">
                      <a onClick={(event) => handleLogout("redirect")} className="g-color-white g-color-gmmi-red--hover g-text-underline--none--hover" style={{ cursor: 'pointer' }} id='cca-reporting-logout'>Logout</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="u-header__section u-header__section--light g-bg-white-opacity-0_8 g-py-10"
            data-header-fix-moment-exclude="g-bg-white-opacity-0_8 g-py-10" data-header-fix-moment-classes="g-bg-white u-shadow-v18 g-py-0">
            <nav className="navbar navbar-expand-lg">
              <div className="container" style={{ display: 'flex' }}>
                <img src={logoImage} alt="GMMI_FEP_ProviderSearch_Color" />
              </div>
            </nav>
          </div>
        </header>
        <div className="body-marginTop">
          {props.children}
        </div>
        <Footer></Footer>
        <div className="cookie-banner" style={{ display: 'none' }}>
          <p> This website uses its own technical and third party cookies in order to improve your browsing experience and provide you a service in line with your preferences.
            If you close this banner or access any element underlying this banner, you'll provide consent regarding cookies. If you want to learn more, read our <a href="https://us.generaliglobalassistance.com/cookies-policy/" target="_new">cookie policy</a>
            <br />
            <button type="button" className="btn btn-primary cookie-banner-close">Agree</button>
          </p>
        </div>
        <a className="js-go-to u-go-to-v1" href="#!" data-type="fixed" data-position='{"bottom": 15, "right": 15}' data-offset-top="400" data-compensation="#js-header" data-show-effect="zoomIn" aria-label="scroll-to-top">
          <i className="hs-icon hs-icon-arrow-top"></i>
        </a>
      </AuthenticatedTemplate>

    </div>
  );
}

export default BasicLayout;
