import React, { useEffect, useState } from 'react';
import { Layout } from "../Layout";
import { useIsAuthenticated } from "@azure/msal-react";
import { useAuth } from "../../providers/authProvider";
import Settings from '../../services/settings';
import CustomerService from '../CustomerService/CustomerService';
import StrapiService from '../../services/strapiService';
import ReactNotification from 'react-notifications-component';

const AccountManagement = () => {
    const { token, clientId } = useAuth();
    const [clientConfig, setClientConfig] = useState('');
    const [accountManagement, setAccountManagement] = useState('');
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const requestAllPictures = async (accountMgmt) => {
            const promises = [];
            for (const item of accountMgmt) {
                promises.push(
                    await new Promise(
                        (resolve, reject) => {
                            StrapiService.getAccountManagerPicture(item.email, token, function (data) {
                                if (data) {
                                    item.photo = data.url;
                                }
                                else {
                                    item.photo = null;
                                }
                                resolve(item);
                            })
                        }
                    )
                )
            }
            await Promise.all([
                promises
            ]).then((responses) => {
                // All fetch calls are done now
                setAccountManagement(responses.flat());
            })
        }

        if (localStorage && token) {
            //get client configuration from local storage
            const clientProfile = localStorage.getItem(Settings.getClientConfigurationKey());
            if (clientProfile) {
                setClientConfig(JSON.parse(clientProfile));
                var accountMgmt = JSON.parse(clientProfile)?.value.accountManagement;
                requestAllPictures(accountMgmt);
            }

        };
    }, [token]);

    return (
        <Layout >
            <ReactNotification />
            <div className="g-ml-25">
                <h1>Account Management</h1>
            </div>
            {/* <!-- Team Block --> */}
            <div className="container">
                <div>

                    {clientConfig?.value?.accountManagement && clientConfig.value?.accountManagement.length > 0
                        ?
                        <div className="u-heading-v1-1 g-mb-20 g-bg-main g-brd-primary text-left">
                            <h2 className="h3 u-heading-v1__title">Your Dedicated Team</h2>
                        </div>
                        : null
                    }
                </div>
                <div className="row">
                    {accountManagement && accountManagement.length > 0
                        ?
                        <>
                            {accountManagement.map((item) => (
                                <div key={item.email} className="col-lg-4 col-sm-6 g-mb-30">
                                    <figure className="g-color-gray-dark-v2">
                                        <div className="row accountMgmt-imgHeight">
                                            <div className="col-12">
                                                {item.photo
                                                    ?
                                                    <img alt={item.name} src={item.photo} className="w-100 g-mb-30" />
                                                    :
                                                    <i class="icon-finance-081 u-line-icon-pro w-100 g-mb-30" style={{ fontSize: '13rem' }}></i>
                                                }
                                                {/* <img alt={item.name} src={item.photo} className="w-100 g-mb-30" /> 
                                                */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="h5 g-color-black-light-v3 g-mb-5">{item.name}</h4>
                                                <p className="g-font-size-13 g-color-gray-dark-v4">{item.title}</p>
                                                <hr className="g-brd-gray-light-v4 g-my-15" />
                                                <ul className="list-unstyled g-font-size-13 g-color-gray-dark-v4">
                                                    <li className="g-mb-5">
                                                        <i className="fa fa-envelope g-mr-10"></i>
                                                        <a href="mailto:@mgr.Email">{item.email}</a>
                                                    </li>
                                                    <li className="g-mb-5">
                                                        <i className="fa fa-phone-square g-mr-10"></i>
                                                        {item.phone}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            ))}
                        </>
                        : null
                    }
                </div>
            </div>
            {/* <!-- End Team Block --> */}
            <CustomerService></CustomerService>
        </Layout>
    )
}

export default AccountManagement;