import Geocode from "react-geocode";
import Settings from "./settings";
import ErrorDisplay from './errorDisplay';
import axios from 'axios';


const googleApiKey = Settings.getGoogleApiKey();

Geocode.setApiKey(googleApiKey);

const GeocodeService = {
    backupGeoCode: (address, callback) => {
        axios.get('/api/_gc?address=' + address).then(res => {
            callback(res);
        }).catch((error => {
            ErrorDisplay.renderError('Unable to geocode location');
        }));

    },
    getGeocode: (address, callback) => {
        Geocode.fromAddress(address).then(
            response => {
                if (response.results.length === 0) {
                    //ErrorDisplay.renderError('Unable to geocode location');
                    GeocodeService.backupGeoCode(address, callback);
                } else {
                    callback(response.results[0].geometry.location);
                }
            },
            error => {
                //ErrorDisplay.renderError('Unable to geocode location');
                GeocodeService.backupGeoCode(address, callback);
            }
        );
    }       

    
}

export default GeocodeService;