import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ScrollToTop from '../../services/scrollToTop';

import PatientSearchForm from './PatientSearchForm';
import PatientSearchResultsListView from './PatientSearchResultsListView';
import MedicalRecords from './MedicalRecords';
import CurrentActivityForm from './CurrentActivityForm';
import Billing from './Billing';
import Notes from './Notes';
import CaseDocuments from './CaseDocuments';
import { Layout } from "../Layout";
import Settings from '../../services/settings';

function isPatientSearch() {
    const url = window.location.href.toLowerCase();
    if (url.indexOf("current-activity") !== -1) {
        return false;
    } else if (url.indexOf("patient-search") !== -1) {
        return true;
    }
}

const PatientSearch = () => {
    const [patientDetails, setPatientDetails] = useState([]);
    const [searchMsg, setSearchMsg] = useState('');
    const [searchActive, setSearchActive] = useState(false);
    const [patientData, setPatientData] = useState([]);
    const [currentActivitySearchParams, setCurrentActivitySearchParams] = useState();
    const [patientSearchSearchParams, setPatientSearchSearchParams] = useState();    
    const [selectedClient, setSelectedClient] = useState('');

    const isCurrentActivity = !isPatientSearch();
    const noResultsMsg = isPatientSearch() ? 'No Patients Found' : 'No Incidents Found';
    const numPerPageKey = isPatientSearch() ? 'PatientSearch_ResultSize' : 'CurrentActivity_ResultSize';
    const baseName = isPatientSearch() ? '/patient-search' : '/current-activity';
    const sortField = 'patientName';
    const sortDir = 'asc';

    const handlePatientDetailsUpdate = (patients) => {
        setPatientDetails(patients);
    }

    const handleSearchActive = (isActive) => {
        setSearchActive(isActive);
    }

    const handleSearchMsg = (msg) => {
        setSearchMsg(msg);
    }

    const handlePatientDataUpdate = (patient) => {
        setPatientData(patient);
    }

    const handleCurrentActivitySearchParams = (params) => {
        if (params && params !== null) {
            setCurrentActivitySearchParams(params);
        }
    }

    const handlePatientSearchSearchParams = (params) => {
        if (params && params !== null) {
            setPatientSearchSearchParams(params);
        }
    }

    useEffect(() => {
        if (localStorage) {
            const currentClient = localStorage.getItem(Settings.getCurrentClientKey());
            setSelectedClient(currentClient);
        }
    }, []);

    return (
        <Layout>
            <ReactNotification />
            <div className="g-mx-25">
                <Router basename={baseName}>
                    <ScrollToTop>
                        <Switch>
                            <Route path="/medical-records">
                                <MedicalRecords
                                    patientData={patientData}
                                    numPerPageKey='MedicalRecords_ResultSize'
                                />
                            </Route>
                            <Route path="/case-documents">
                                <CaseDocuments
                                    patientData={patientData}
                                    numPerPageKey='CaseDocuments_ResultSize' />
                            </Route>
                            <Route path="/billing">
                                <Billing
                                    patientData={patientData}
                                    numPerPageKey='Billing_ResultSize'
                                />
                            </Route>
                            <Route path="/notes">
                                <Notes
                                    patientData={patientData}
                                    numPerPageKey='Notes_ResultSize'
                                />
                            </Route>
                            <Route path="/">
                                {!isCurrentActivity &&
                                    <PatientSearchForm
                                        onPatientDetailsChange={handlePatientDetailsUpdate}
                                        searchActive={handleSearchActive}
                                        searchMsg={handleSearchMsg}
                                        onSearchParamsChange={handlePatientSearchSearchParams}
                                        searchParams={patientSearchSearchParams}
                                    />
                                }
                                {isCurrentActivity &&
                                    <CurrentActivityForm
                                        onPatientDetailsChange={handlePatientDetailsUpdate}
                                        searchActive={handleSearchActive}
                                        searchMsg={handleSearchMsg}
                                        onSearchParamsChange={handleCurrentActivitySearchParams}
                                        searchParams={currentActivitySearchParams}
                                    />
                                }
                                <PatientSearchResultsListView
                                    dataDetails={patientDetails}
                                    noResultsMsg={noResultsMsg}
                                    searchActive={searchActive}
                                    sortField={sortField}
                                    sortDir={sortDir}
                                    searchMsg={searchMsg}
                                    numPerPageKey={numPerPageKey}
                                    onPatientDataChange={handlePatientDataUpdate}
                                />
                            </Route>
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </Layout>
    )
};

export default PatientSearch;
