import React from 'react';
import logoImage from "../../assets/img/login_bg.png"
import loginLogo from "../../assets/img/login_logo.png"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { BrowserUtils } from "@azure/msal-browser";
import { useHistory } from "react-router-dom";

const AppLayout = () => {
    const { instance, inProgress } = useMsal();
    const history = useHistory();

    const handleLogin = (loginType) => {

        instance.loginRedirect({
            ...loginRequest, redirectUri: "/home",
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
        });
    }

    const handleClientMemberLogin = (loginType) => {
        let path = `/client-member-page/login`;
        history.push({
            pathname: path
        })
    }


    return (
        <div>
            <section className="g-bg-cover g-bg-pos-top-center g-bg-img-hero g-min-height-100vh g-flex-centered g-py-25" style={{ backgroundImage: 'url(' + logoImage + ')' }}>
                <header className="u-header u-header--sticky-top u-header--toggle-section u-header--change-appearance">
                    <div className="u-header__section u-header__section--light g-py-10" data-header-fix-moment-exclude="g-bg-white-opacity-0_8 g-py-10" data-header-fix-moment-classes="g-bg-white u-shadow-v18 g-py-0">
                        <nav className="navbar navbar-expand-lg">
                            <div style={{ display: 'flex' }}>
                                <img src={loginLogo} className="gmmi-logo" alt="GMMI_logo_White_neww_CCPortal"></img>
                            </div>
                            <div className="nav-text">
                                <h1></h1>
                            </div>
                        </nav>
                    </div>
                </header>
            </section>
            <div className="text-center g-color-white g-flex-centered-item g-position-rel">
                <div id="errorRender" className="g-absolute-centered mt-50">
                    <div id="loggedOutMessage" className="row" style={{ 'textAlign': 'center', 'paddingBottom': '40px', 'fontWeight': 'bold', 'display': 'none' }}>
                        <div className="col-md-12 col-sm-12">
                            ** You have been logged out because your session has expired. Please log back in. **
                        </div>
                    </div>
                    <h1 className="g-mt-minus-8 mb-0">Welcome to GMMI Online</h1>
                    <hr className="g-brd-white g-my-15" />
                    <div className="g-font-size-20 mb-0 g-z-index-1">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <p style={{ margin: '0in' }}>Please click on ‘Portal Login’ if you have received your migration confirmation email to access the New GMMI Online</p>
                                <div className="container text-center g-z-index-1 g-mt-15">
                                    <button
                                        className='btn btn-md login-u-btn-primary btn'
                                        onClick={(event) => handleLogin("redirect")}
                                    >
                                        Portal Login
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <p style={{ margin: '0in' }}>If you are a Member with a Login and Password, please click on ‘Member Login.’</p>
                                <div className="container text-center g-z-index-1 g-mt-15">
                                    <button
                                        className='btn btn-md login-u-btn-primary btn'
                                        onClick={(event) => handleClientMemberLogin()}
                                    >
                                        Member Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cookie-banner" style={{ display: 'none' }}>
                <p> This website uses its own technical and third party cookies in order to improve your browsing experience and provide you a service in line with your preferences.
                    If you close this banner or access any element underlying this banner, you'll provide consent regarding cookies. If you want to learn more, read our <a href="https://us.generaliglobalassistance.com/cookies-policy/" target="_new">cookie policy</a>
                    <br />
                    <button type="button" className="btn btn-primary cookie-banner-close">Agree</button>
                </p>
            </div>
        </div>

    )
}

export default AppLayout;