import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Modal, Container, Form, Row, Col } from 'react-bootstrap';
import ProviderSearchService from '../../services/providerSearchService';

const EmailProvidersForm = (props) => {
    const [show, setShow] = useState(false);
    const [providers, setProviders] = useState([]);
    const [fromEmail, setFromEmail] = useState('');
    const [toEmail, setToEmail] = useState('');
    const [user, setUser] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [message, setMessage] = useState('');
    const [showComplete, setShowComplete] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        setProviders(props.emailProviders);
    }, [props.emailProviders]);

    const handleClose = () => {
        props.onEmailProvidersClose();
    }

    const emailProviders = (e) => {
        e.preventDefault();

        if (inProgress) {
            return;
        }

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        const postData = {
            user: user,
            toEmail: toEmail,
            providers: props.providers
        };

        ProviderSearchService.emailProviders(props.selectedNetwork, props.providerSearchInfo.code, postData, function (data) {
            if (data && data.message) {
                setMessage(data.message);
                setShowComplete(true);
            }
            setInProgress(false);
        });
    }

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Email Providers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        {!showComplete &&
                            <Form onSubmit={emailProviders} id="emailProviders">
                                <Form.Group as={Row} controlId="user">
                                    <Form.Label column md="4">
                                        Send Providers By User:
                                    </Form.Label>
                                    <Col md="8">
                                        <Form.Control required={true} as="input" onChange={e => setUser(e.target.value)}></Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="toEmail">
                                    <Form.Label column md="4">
                                        Send Providers To Email Address:
                                    </Form.Label>
                                    <Col md="8">
                                        <Form.Control required={true} as="input" type="email" onChange={e => setToEmail(e.target.value)} pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"></Form.Control>
                                    </Col>
                                </Form.Group>
                                <Row className="mt-3">
                                    <Col md="12" className="text-center">
                                        <Button style={{ margin: '0.5rem' }} onClick={handleClose} variant="outline-dark">Cancel</Button>
                                        {!showComplete &&
                                            <Button style={{ margin: '0.5rem' }} form="emailProviders" variant="primary" type="submit" className="btn btn-md u-btn-primary">Submit</Button>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        }
                        {showComplete &&
                            <Row>
                                <Col md="12" className="text-center">
                                    <p className="text-center">{message}</p>
                                    <Button style={{ margin: '0.5rem' }} onClick={handleClose} variant="outline-dark">Close</Button>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default EmailProvidersForm;