var app = app || {};

app = {
    init: function() {
        $.HSCore.components.HSGoTo.init(".js-go-to");
    }
};

$(window).load(function () {
    app.init();
});
