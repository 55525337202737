import React, { useState, useEffect } from "react";
import { Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';

import PatientHeader from './PatientHeader';
import Settings from '../../services/settings';
import PatientSearchService from '../../services/patientSearchService';
import { useAuth } from "../../providers/authProvider";

const Billing = (props) => {
    const [patientData, setPatientData] = useState();
    const [billingData, setBillingData] = useState([]);
    const [billingDataForDisplay, setBillingDataForDisplay] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [totalBillsProcessed, setTotalBillsProcessed] = useState(0);
    const [totalCharges, setTotalCharges] = useState(0);
    const [totalRepricings, setTotalRepricings] = useState(0);
    const [totalSavings, setTotalSavings] = useState(0);
    const [incidentNumber, setIncidentNumber] = useState('');
    const [searchMsg, setSearchMsg] = useState('');
    const [noResultsMsg, setNoResultsMsg] = useState('No results');
    const [searchActive, setSearchActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [numPerPage, setNumPerPage] = useState(10);
    const [printWithClaimImages, setPrintWithClaimImages] = useState(false);
    const { token } = useAuth();

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    const columns = [
        {
            dataField: 'itn',
            text: 'ITN #',
            headerStyle: {
                textAlign: 'center',
                width: '100px'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'policyNumber',
            text: 'Policy #',
            headerStyle: {
                textAlign: 'center',
                width: '100px'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'providerName',
            text: 'Provider Name',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'serviceDate',
            text: 'Date(s) of Service',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center',
                width: '190px'
            },
            sort: true,
            sortValue: (cell, row) => Date.parse(cell.split("-")[0]),
            filter: dateFilter({
                defaultValue: { comparator: Comparator.EQ },
                comparatorStyle: { display: "none" },
                onFilter: filterByDateRange
            })
        },
        {
            dataField: 'totalCharges',
            text: 'Total Charges',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            sortValue: (cell, row) => parseFloat(cell.replace(/[^0-9.-]+/g, "")),
            filter: textFilter({
                onFilter: filterByTotalCharges
            }),
            filterValue: (cell, row) => cell.replace(/[^0-9.-]+/g, "")
        },
        {
            dataField: 'repricedAmount',
            text: 'Repriced Amount',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            sortValue: (cell, row) => parseFloat(cell.replace(/[^0-9.-]+/g, "")),
            filter: textFilter({
                onFilter: filterByRepricedAmount
            }),
            filterValue: (cell, row) => cell.replace(/[^0-9.-]+/g, "")
        },
        {
            dataField: 'savingsAmount',
            text: 'Savings Amount',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            sortValue: (cell, row) => parseFloat(cell.replace(/[^0-9.-]+/g, "")),
            filter: textFilter({
                onFilter: filterBySavingsAmount
            }),
            filterValue: (cell, row) => cell.replace(/[^0-9.-]+/g, "")
        },
        {
            dataField: 'savingsPercent',
            text: 'Savings %',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            sortValue: (cell, row) => parseFloat(cell.replace(/[^0-9.-]+/g, "")),
            filter: textFilter({
                onFilter: filterBySavingsPercent
            }),
            filterValue: (cell, row) => cell.replace(/[^0-9.-]+/g, "")
        },
        {
            dataField: 'processedDate',
            text: 'Date Processed',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            formatter: Settings.dateFormatter,
            sort: true,
            filter: dateFilter({ 
                defaultValue: { comparator: Comparator.EQ }, 
                comparatorStyle: { display: "none" },
                onFilter: fitlerByProcessedDate
            })
        },
        {
            dataField: 'paymentStatus',
            text: 'Provider Payment Status',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'billImage',
            text: 'Bill Image',
            headerStyle: {
                textAlign: 'center',
            },
            style: {
                textAlign: 'center'
            },
            formatter: billImageFormatter
        },
        {
            dataField: 'invoice',
            text: 'Repricing Invoice',
            headerStyle: {
                textAlign: 'center',
            },
            style: {
                textAlign: 'center'
            },
            formatter: invoiceFormatter
        }
    ];

    function filterByDateRange(filterVal, data) {
        if (filterVal && filterVal.date && !isNaN(filterVal.date.valueOf())) {
            var filteredData = new Array();
            var filterDate = Date.parse(filterVal.date.toISOString());

            for (var i = 0; i < data.length; i++) {
                var current = data[i];
                var date1 = Date.parse(current.serviceDate.split("-")[0] + " 00:00:00 GMT");
                var date2;

                if (current.serviceDate.indexOf("-") > -1) {
                    date2 = Date.parse(current.serviceDate.split("-")[1] + " 00:00:00 GMT");
                }

                if (filterDate >= date1 && filterDate <= date2) {
                    filteredData.push(current);
                }
            }

            return filteredData;
        }

        return data;
    }

    function fitlerByProcessedDate(filterVal, data) {
        if (!filterVal.date) return data;
        
        var filteredData = new Array();

        for (var i = 0; i < data.length; i++) {
            var current = data[i];
            var dateToCompare = current.processedDate.split('T')[0];
            
            if (moment.utc(dateToCompare).isSame(filterVal.date.toISOString(), 'day')) {
                filteredData.push(current);
            }
        }

        return filteredData;
    };

    function filterByTotalCharges(filterVal, data) {
        if (filterVal) {
            return data.filter(row => row.totalCharges.replace(/[^0-9.-]+/g, "").indexOf(filterVal) !== -1);
        }

        return data;
    }

    function filterByRepricedAmount(filterVal, data) {
        if (filterVal) {
            return data.filter(row => row.repricedAmount.replace(/[^0-9.-]+/g, "").indexOf(filterVal) !== -1);
        }

        return data;
    }

    function filterBySavingsAmount(filterVal, data) {
        if (filterVal) {
            return data.filter(row => row.savingsAmount.replace(/[^0-9.-]+/g, "").indexOf(filterVal) !== -1);
        }

        return data;
    }

    function filterBySavingsPercent(filterVal, data) {
        if (filterVal) {
            return data.filter(row => row.savingsPercent.replace(/[^0-9.-]+/g, "").indexOf(filterVal) !== -1);
        }

        return data;
    }

    function billImageFormatter(cell, row) {
        if (cell) {
            return (
                <form method="post" action="/api/billimage" target="new">
                    <button className="viewAsLink">View</button>
                    <input type="hidden" name="IncidentNumber" value={incidentNumber}></input>
                    <input type="hidden" name="ITN" value={row.itn}></input>
                    <input type="hidden" name="Token" value={token}></input>
                </form>
            );
        } else {
            return ('');
        }
    }

    function invoiceFormatter(cell, row) {
        if (cell) {
            return (
                <form method="post" action="/api/repricinginvoice" target="new">
                    <button className="viewAsLink">View</button>
                    <input type="hidden" name="IncidentNumber" value={incidentNumber}></input>
                    <input type="hidden" name="ITN" value={row.itn}></input>
                    <input type="hidden" name="ShowImages" value={printWithClaimImages}></input>
                    <input type="hidden" name="Token" value={token}></input>
                </form>
            );
        } else {
            return ('');
        }
    }

    useEffect(() => {
        setPatientData(props.patientData);
        setIncidentNumber(props.patientData.incidentNumber);
        setBillingData([]);
        setSearchActive(false);
        setIsLoading(true);
    }, [props.patientData]);

    useEffect(() => {
        if (patientData) {
            setIncidentNumber(patientData.incidentNumber);
            var postData = {
                patientCode: patientData.patientCode,
                incidentNumber: patientData.incidentNumber,
                client: patientData.client
            };

            PatientSearchService.getBills(postData, function (data) {
                setBillingData(data);
                setBillingDataForDisplay(data);
                setIsLoading(false);
                setSearchActive(true);
            });
        }

        if (isIE11) {
            intitializeDatepicker();
        }
    }, [patientData]);

    useEffect(() => {
        setSearchMsg(props.searchMsg);
    }, [props.searchMsg]);

    useEffect(() => {
        setNoResultsMsg(props.noResultsMsg);
    }, [props.noResultsMsg]);

    useEffect(() => {
        setSearchActive(props.searchActive);
    }, [props.searchActive]);

    useEffect(() => {
        var billsToDisplay = new Array();
        if (startDate && endDate) {

            var parsedStartDate = Date.parse(startDate);
            var parsedEndDate = Date.parse(endDate);

            if (parsedStartDate && parsedEndDate && (parsedEndDate > parsedStartDate))
                billingData.forEach((bill) => {
                    var date1 = Date.parse(bill.serviceDate.split("-")[0]);
                    var date2;

                    if (bill.serviceDate.indexOf("-") > -1) {
                        date2 = Date.parse(bill.serviceDate.split("-")[1]);
                        if (date1 >= parsedStartDate && date1 <= parsedEndDate) {
                            billsToDisplay.push(bill);
                        } else if (date2 >= parsedStartDate && date2 <= parsedEndDate) {
                            billsToDisplay.push(bill);
                        }
                    } else {
                        if (date1 >= parsedStartDate && date1 <= parsedEndDate) {
                            billsToDisplay.push(bill);
                        }
                    }
                });

            setBillingDataForDisplay(billsToDisplay);
        } else if (!startDate || !endDate) {
            setBillingDataForDisplay(billingData);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (billingDataForDisplay.length > 0) {
            setTotalBillsProcessed(billingDataForDisplay.length);
            var totalCharges = 0;
            var totalReprice = 0;
            billingDataForDisplay.forEach((bill) => {
                var billCharges = parseFloat(bill.totalCharges.replace("$", "").replace("%", "").replace(",", "").replace(" ", ""));
                var billRepricings = parseFloat(bill.repricedAmount.replace("$", "").replace("%", "").replace(",", "").replace(" ", ""));
                totalCharges += billCharges;
                totalReprice += billRepricings;
            });
            setTotalCharges(totalCharges);
            setTotalRepricings(totalReprice);
            setTotalSavings(totalCharges - totalReprice);
        }
        console.log(billingDataForDisplay);
    }, [billingDataForDisplay]);


    useEffect(() => {
        var numPerPageStorage = localStorage.getItem(props.numPerPageKey);
        if (numPerPageStorage && numPerPageStorage !== '') {
            setNumPerPage(parseInt(numPerPageStorage));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(props.numPerPageKey, numPerPage);
    }, [numPerPage]);

    const selectRow = {
        mode: 'checkbox',
        hideSelectColumn: true,
    };

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total m-1">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <span className="react-bs-table-sizePerPage-dropdown dropdown">
            <button className="dropdown-toggle btn btn-outline-dark" data-toggle="dropdown" id="dropdownMenuButton" role="group">
                {currSizePerPage + ' per page'}
            </button>
            <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map(option => (
                        <li key={option.text} onClick={() => { onSizePerPageChange(option.page); setNumPerPage(option.page) }}
                            className={'dropdown-item'} role='presentation'>
                            { option.text}
                        </li>
                    ))
                }
            </ul>
        </span>
    );

    const paginationOptions = {
        custom: true,
        showTotal: true,
        totalSize: billingDataForDisplay.length,
        sizePerPageList: [10, 25, 50],
        sizePerPage: numPerPage,
        nextPageText: 'Next',
        prePageText: 'Previous',
        sizePerPageRenderer,
        paginationTotalRenderer: customTotal
    };

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
        e.target.setCustomValidity('');
    }

    const onEndDateChange = (e) => {
        setEndDate(e.target.value);
        e.target.setCustomValidity('');
    }

    const intitializeDatepicker = () => {
        $("input[type='date']#startDate").datepicker({
            onSelect: function (dateText) {
                $(this).each(function () { this.setCustomValidity('') });
                setStartDate(dateText);
            }
        });
        $("input[type='date']#endDate").datepicker({
            onSelect: function (dateText) {
                $(this).each(function () { this.setCustomValidity('') });
                setEndDate(dateText);
            }
        });
    }

    return (
        <>
            <Row className="pt-4">
                <Col xl="6" md="12" className="browser-table billing">
                    <h1 className="h2 mb-3">Billing Information</h1>
                    <PatientHeader patientData={patientData} />
                    <Form.Group as={Row} className="list-view-top" controlId="startDate">
                        <Form.Label column xl="4" md="5" className="offset-md-1"><strong>Start Date:</strong></Form.Label>
                        <Col xl="7" md="6">
                            <Form.Control value={startDate} as="input" type="date" required={true} onChange={(e) => onStartDateChange(e)} onInvalid={(e) => e.target.setCustomValidity('Please enter a date to search by')} onInput={(e) => e.target.setCustomValidity('')}></Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="endDate">
                        <Form.Label column xl="4" md="5" className="offset-md-1"><strong>End Date:</strong></Form.Label>
                        <Col xl="7" md="6">
                            <Form.Control value={endDate} as="input" type="date" required={true} onChange={(e) => onEndDateChange(e)} onInvalid={(e) => e.target.setCustomValidity('Please enter a date to search by')} onInput={(e) => e.target.setCustomValidity('')}></Form.Control>
                        </Col>
                    </Form.Group>
                </Col>
                <Col xl="6" md="12">
                    <Row>
                        <Col md="12">
                            <h2 className="mb-3">Billing Summary</h2>
                            {isLoading &&
                                <div className="col-md-12 justify-content-center providersLoading">
                                    <div className="spinner-border"></div>
                                </div>
                            }
                            {billingDataForDisplay.length > 0 &&
                                <>
                                    <div className="row list-view-top">
                                        <div className="col-xl-4 col-md-5 offset-md-1"><strong>Total Bills Processed:</strong></div>
                                        <div className="col-xl-7 col-md-6">{totalBillsProcessed}</div>
                                    </div>
                                    <div className="row list-view-top">
                                        <div className="col-xl-4 col-md-5 offset-md-1"><strong>Total Charges:</strong></div>
                                        <div className="col-xl-7 col-md-6">${totalCharges.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                    </div>
                                    <div className="row list-view-top">
                                        <div className="col-xl-4 col-md-5 offset-md-1"><strong>Total Repricings:</strong></div>
                                        <div className="col-xl-7 col-md-6">${totalRepricings.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                    </div>
                                    <div className="row list-view-top">
                                        <div className="col-xl-4 col-md-5 offset-md-1"><strong>Total GMMI Savings:</strong></div>
                                        <div className="col-xl-7 col-md-6">${totalSavings.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                    </div>
                                </>
                            }
                            {billingDataForDisplay.length <= 0 && !isLoading &&
                                <p className="text-center">No bills found in selected date range.</p>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr className="mb-4 mt-3" />
            {isLoading &&
                <div className="col-md-12 justify-content-center providersLoading">
                    <div className="spinner-border"></div>
                </div>
            }
            {billingDataForDisplay.length > 0 &&
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <div className="browser-table mt-3">
                                <div className="row list-view-top">
                                    <div className="col-md-4 list-view-pagination">
                                        <SizePerPageDropdownStandalone {...paginationProps} />
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </div>
                                    <div className="col-md-4">
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>
                                    <div className="col-md-4">
                                        <form className="float-right" method="post" action="/api/repricinginvoice" target="new">
                                            <button className="btn btn-outline-dark">Print All Repricings</button>
                                            <input type="hidden" name="IncidentNumber" value={incidentNumber}></input>
                                            <input type="hidden" name="ITN" value="ALL"></input>
                                            <input type="hidden" name="Token" value={token}></input>
                                            <Form.Check className="d-inline-block ml-2" value={printWithClaimImages} name="BillImage" checked={printWithClaimImages} label="Print with claim images" onChange={(e) => setPrintWithClaimImages(!printWithClaimImages)}></Form.Check>
                                        </form>
                                    </div>
                                </div>
                                <BootstrapTable
                                    id="results-list"
                                    bootstrap4
                                    striped
                                    wrapperClasses="table-responsive"
                                    keyField="itn"
                                    data={billingDataForDisplay}
                                    columns={columns}
                                    {...paginationTableProps}
                                    filterPosition="top"
                                    filtersClasses="filter-wrapper"
                                    filter={filterFactory()}
                                />
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        )
                    }
                </PaginationProvider>
            }
            {billingDataForDisplay.length == 0 && searchActive &&
                <p className="text-center"><strong>No bills found</strong></p>
            }
        </>
    );
};

export default Billing;