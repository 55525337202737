
import React, { useEffect, useState } from "react";
import Settings from '../../services/settings';

const IdCardForm = (props) => {
    const [clientName, setClientName] = useState('');

    useEffect(() => {
        const clientMemberConfig = localStorage.getItem(Settings.getClientMemberKey());
        if (clientMemberConfig) {
            setClientName(JSON.parse(clientMemberConfig).clientName);
        }
    }, []);

    return (

        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col col-lg-6 mt-5">
                    {
                        props.showEnglishAndGerman
                            ?
                            <h4 className="text-center">Insurance Card Information / Informationen zum Ausdrucken einer Versicherungskarte</h4>
                            :
                            <h4 className="text-center">Insurance Card Information</h4>
                    }
                    <form className="mt-4" id="idCard" method="post" action="/api/clientMember/idCard.pdf" target="new">
                        <input type="hidden" name="clientName" value={clientName}></input>
                        <div className="form-group row">
                            {
                                props.showEnglishAndGerman
                                    ?
                                    <label htmlFor="policy" className="col-sm-4 col-form-label">Policy/ID # / Versicherungsnummer #</label>
                                    :
                                    <label htmlFor="policy" className="col-sm-4 col-form-label">Policy/ID #</label>
                            }
                            <div className="col-sm-8">
                                <input type="text" className="form-control" name="policy" />
                            </div>
                        </div>
                        <div className="form-group row">
                            {
                                props.showEnglishAndGerman
                                    ?
                                    <label htmlFor="firstName" className="col-sm-4 col-form-label">Patient First Name / Vorname des Patienten</label>
                                    :
                                    <label htmlFor="firstName" className="col-sm-4 col-form-label">Patient First Name</label>
                            }
                            <div className="col-sm-8">
                                <input type="text" className="form-control" name="firstName" />
                            </div>
                        </div>
                        <div className="form-group row">
                            {
                                props.showEnglishAndGerman
                                    ?
                                    <label htmlFor="lastName" className="col-sm-4 col-form-label">Patient Last Name / Nachname des Patienten</label>
                                    :
                                    <label htmlFor="lastName" className="col-sm-4 col-form-label">Patient Last Name</label>
                            }
                            <div className="col-sm-8">
                                <input type="text" className="form-control" name="lastName" />
                            </div>
                        </div>
                        <div className="form-group row">
                            {
                                props.showEnglishAndGerman
                                    ?
                                    <label htmlFor="dateOfBirth" className="col-sm-4 col-form-label">Patient Date of Birth / Geburtsdatum des Patienten</label>
                                    :
                                    <label htmlFor="dateOfBirth" className="col-sm-4 col-form-label">Patient Date of Birth</label>
                            }
                            <div className="col-sm-8">
                                <input type="date" className="form-control" name="dateOfBirth" />
                            </div>
                        </div>
                        <div className="form-group row mt-4">
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn u-btn-primary-gmmi">Display Insurance Card</button>
                            </div>
                        </div>
                        <p>
                            Take the printed card with you to a U.S. medical provider’s office upon your appointment
                            and present the card for billing information. This card does not guarantee that the medical
                            provider will accept you as a patient, nor will it guarantee that the provider will bill your
                            insurance for these services. If you have any problems, please contact GMMI. Please note that
                            this card is not a verification of a valid policy, nor a verification of payable benefits under
                            your policy. In order to confirm eligibility and coverage, please contact GMMI.
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IdCardForm;