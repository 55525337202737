import { store } from 'react-notifications-component';
import $ from 'jquery';

const ErrorDisplay = {
    renderError: (message) => {

        $(".notification-item").click();

        store.addNotification({
            title: "Error",
            message: message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 0,
                showIcon: true,
            }
        });
    },
}

export default ErrorDisplay;