import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Modal, Container, Form, Row, Col } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import ProviderSearchService from '../../services/providerSearchService';
import Settings from "../../services/settings";
import { BsPrefixComponent } from "react-bootstrap/esm/helpers";

const ProviderSearchFeedback = (props) => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [captchaToken, setCaptchaToken] = useState('');
    const [showSubmit, setShowSubmit] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailure, setShowFailure] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const handleClose = () => {
        setShow(false);
        props.onProviderFeedbackClose();
    }

    const onCaptchaChange = (value) => {
        setCaptchaToken(value);
        setShowSubmit(true);
    }

    const onCaptchaExpired = () => {
        setShowSubmit(false);
    }

    const sendFeedback = (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        setShowSubmit(false);

        const postData = {
            providerName: props.provider.name,
            name: name,
            email: email,
            message: message,
            captchatoken: captchaToken,
            itemId: Settings.getItemId()
        }

        ProviderSearchService.sendFeedback(props.selectedNetwork, props.providerSearchInfo.code, postData, function (data) {
            if (data.success) {
                setShowSuccess(true);
                setShowFailure(false);
            } else {
                setShowSuccess(false);
                setShowFailure(true);
            }
        });
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Provider Experience Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!showSuccess &&
                    <>
                        <Row>
                            <Col md="12">
                                <p>You've selected...</p>
                                <p>Provider Name: {props.provider.name}</p>
                            </Col>
                        </Row>
                        <Form onSubmit={sendFeedback} id="providerFeedback">
                            <Row>
                                <Col md="12">
                                    <Form.Group as={Row} controlId="name">
                                        <Form.Label column md="4">
                                            Your Name:
                                    </Form.Label>
                                        <Col md="8">
                                            <Form.Control required={true} as="input" onChange={e => setName(e.target.value)}></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group as={Row} controlId="email">
                                        <Form.Label column md="4">
                                            Your Email:
                                    </Form.Label>
                                        <Col md="8">
                                            <Form.Control required={true} as="input" type="email" onChange={e => setEmail(e.target.value)} pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <p>What did you think about your overall experience with the provider?</p>
                                    <Form.Control required={true} as="textarea" rows="4" onChange={e => setMessage(e.target.value)}></Form.Control>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-3">
                                <ReCAPTCHA
                                    sitekey={Settings.getReCaptchaSiteKey()}
                                    onChange={onCaptchaChange}
                                    onExpired={onCaptchaExpired}
                                />
                            </Row>
                            <Row className="justify-content-right feedbackButtons mt-3">
                                <Button onClick={handleClose} variant="outline-dark">{showSuccess ? "Close" : "Cancel"}</Button>
                                {showSubmit &&
                                    <Button form="providerFeedback" variant="primary" type="submit" className="btn btn-md u-btn-primary">Submit</Button>
                                }
                            </Row>
                        </Form>
                    </>
                }
                {showSuccess &&
                    <Row>
                        <Col md="12">
                            <p>Thank you for submitting feedback!</p>
                        </Col>
                    </Row>
                }
                {showFailure &&
                    <Row>
                        <Col md="12">
                            <p>There was an issue submitting your feedback. Please ensure all form fields entered correctly.</p>
                        </Col>
                    </Row>
                }
            </Modal.Body>
            {showSuccess &&
                <Modal.Footer>
                    <Button onClick={handleClose} variant="outline-dark">Close</Button>
                </Modal.Footer>
            }
        </Modal>
    );
};

export default ProviderSearchFeedback;