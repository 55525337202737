import ProviderSearch from "../ProviderSearch";
import React, { useState, useEffect } from "react";
import { EmptyLayout } from "../../Layout";
import StrapiService from '../../../services/strapiService';
import { useAuth } from "../../../providers/authProvider";

const ProviderSearchWrapper = (props) => {
    const [pageContent, setPageContent] = useState('');
    const { token, clientId } = useAuth();

    useEffect(() => {
        if (localStorage) {
            const url = window.location.href.toLowerCase();
            const splitUrl = url.split('/');
            const urlSlug = splitUrl.pop();
            StrapiService.getProviderSearchDetail(urlSlug, token, function (data) {
                if (data) {
                    setPageContent(data);
                }
            })
        }
    }, []);
    return (
        <EmptyLayout secure={props.secure}>
            <div className="search-body g-mx-25">
                <div>
                    <div className="u-heading-v5-3 g-mb-20 u-heading-v5-color-gray-light-v2 text-center">
                        <h2 className="h3 text-uppercase u-heading-v5__title g-color-gmmi-blue">
                            {props.title}
                        </h2>
                    </div>
                </div>
                <div className="lead g-mb-40">
                    <div className="text-center">
                        <div dangerouslySetInnerHTML={{ __html: pageContent?.content }}></div>
                    </div>
                </div>
            </div>
            <ProviderSearch />


        </EmptyLayout>
    );
}

export default ProviderSearchWrapper;