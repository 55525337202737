import React, { } from 'react';
import { Container } from 'reactstrap';
import { MsalAuthenticationTemplate } from "@azure/msal-react";

import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { useAuth } from "../providers/authProvider";
import { BasicLayout } from "../components/Layout";
import { AuthLoading, MsalError } from './Auth';

const Token = () => {
  const authRequest = {
    ...loginRequest
  };
  const { getAuthToken } = useAuth();

  return (
    <BasicLayout >
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={MsalError}
        loadingComponent={AuthLoading}
      >
        <Container>
          {getAuthToken()}
        </Container>
      </MsalAuthenticationTemplate>
    </BasicLayout >
  );
}

export default Token;