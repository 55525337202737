
import React, { useState } from 'react';
import { EmptyLayout } from '../Layout';
import { useHistory } from "react-router-dom";
import ReactNotification from 'react-notifications-component'
import ClientMemberService from '../../services/clientMemberService';
import Settings from '../../services/settings';

import './ClientMemberLogin.css'

const ClientMemberLogin = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const history = useHistory();

    const onUserNameChange = (e) => {
        setUserName(e.target.value);
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (inProgress) {
            return;
        }

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        const postData = {
            UserName: userName,
            Password: password
        };

        ClientMemberService.login(postData, function (data) {
            if (data) {
                localStorage.setItem(Settings.getClientMemberKey(), JSON.stringify(data));

                let path = `/client-member-page`;
                history.push({
                    pathname: path
                })
            }
            setInProgress(false);
        });
    }

    return (
        <EmptyLayout displayLogoWithLink={true}>
            <ReactNotification />
            <div className="row justify-content-md-center g-pt-65">
                <div className="col col-md-4">
                    <div>
                        <h2 className="text-center mb-5" style={{color: '#555'}}>Client Member Page Login</h2>

                        <form method="POST" action="" onSubmit={onFormSubmit}>
                            <div className="form-group row">
                                <label htmlFor="policy" className="col-sm-4 col-form-label label">Username</label>
                                <div className="col-sm-8">
                                    <input required type="text" className="form-control" name="username" onChange={(e) => onUserNameChange(e)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="firstName" className="col-sm-4 col-form-label label">Password</label>
                                <div className="col-sm-8">
                                    <input required type="password" className="form-control" name="password" onChange={(e) => onPasswordChange(e)} />
                                </div>
                            </div>
                            <div className="pt-2 pb-4 text-center">
                                <button className="btn u-btn-primary-gmmi" type="submit">Log In</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </EmptyLayout >
    );
}


export default ClientMemberLogin;