import React, { useState, useEffect } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import { PatchCheckFll } from 'react-bootstrap-icons';
import Settings from "../../services/settings";

const ProviderSearchResultsListView = (props) => {

    const [providers, setProviders] = useState([]);
    const [distanceUnit, setDistanceUnit] = useState('mi');
    const [selectedProviders, setSelectedProviers] = useState([]);
    const [showTable, setShowTable] = useState(true);
    const [searchByType, setSearchByType] = useState('City');
    const [numPerPage, setNumPerPage] = useState(10);
    const [isUsa, setIsUsa] = useState(true);
    const [enableFeedback, setEnableFeedback] = useState(false);
    const [showPreferredProviders, setShowPreferredProvider] = useState(false);
    const [showSpanishLabels, setShowSpanishLabels] = useState(false);

    useEffect(() => {
        var numPerPageStorage = localStorage.getItem(Settings.getNumPerPageKey());
        if (numPerPageStorage && numPerPageStorage !== '') {
            setNumPerPage(parseInt(numPerPageStorage));
        }
        setEnableFeedback(Settings.enableFeedback());
    }, []);

    useEffect(() => {
        setProviders(props.providerDetails);
        setDistanceUnit(props.distanceUnit);
        setSelectedProviers([]);
    }, [props.providerDetails]);

    useEffect(() => {
        setDistanceUnit(props.distanceUnit);
    }, [props.distanceUnit]);

    useEffect(() => {
        setSearchByType(props.searchByType);
    }, [props.searchByType]);

    useEffect(() => {
        setIsUsa(props.selectedCountryName.toLowerCase().indexOf('united states') !== -1)
    }, [props.selectedCountryName]);

    useEffect(() => {
        setShowPreferredProvider(props.showPreferredProviders);
    }, [props.showPreferredProviders]);

    useEffect(() => {
        setShowSpanishLabels(props.selectedNetwork.indexOf('aetnaLDS') !== -1);
    }, [props.selectedNetwork]);

    useEffect(() => {
        localStorage.setItem(Settings.getNumPerPageKey(), numPerPage);
    }, [numPerPage]);

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedProviers([...selectedProviders, row]);
            return true;
        } else {
            setSelectedProviers(selectedProviders.filter(x => x.id !== row.id));
            return true;
        }
    }

    const launchFeedback = (provider) => {
        props.onProviderFeedbackOpen(provider);
    }

    const createVOB = (provider) => {
        props.onCreateVOBOpen(provider);
    }

    const hideVOB = () => {
        if (props.providerSearchInfo) {
            return !props.providerSearchInfo.isVOB
        } else {
            return false;
        }
    }

    const launchEmail = () => {
        if (selectedProviders.length > 0) {
            props.onEmailProvidersOpen(selectedProviders);
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            setSelectedProviers(rows);
            return rows.map(r => r.hash);
        } else {
            setSelectedProviers([]);
            return [];
        }
    }

    const nameFormatter = (cell, row) => {
        return (
            <>
                {cell}&nbsp;{row.preferred && <PatchCheckFll className="g-color-gmmi-blue preferredProvider" size={19} />}
            </>
        );
    }

    const feedbackFormatter = (cell, row) => {
        if (showSpanishLabels) {
            return (
                <a className="providerFeedback" onClick={() => launchFeedback(row)}>Leave Feedback/<wbr/>Comentarios</a>
            );
        } else {
            return (
                <a className="providerFeedback" onClick={() => launchFeedback(row)}>Leave Feedback</a>
            );
        }
    }

    const vobFormatter = (cell, row) =>  {
        return (
            <a className="createVOB" onClick={() => createVOB(row)}>Create VOB</a>
        );
    }

    const addressFormatter = (cell, row) => {
        return (
            <a className="addressLink" target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + encodeURI(row.name) + "%20" + encodeURI(row.addressDisplay)}>{cell}</a>
        );
    }

    function rangeFormatter(cell, row) {
        var dist = cell.toFixed(2);
        var unit = ' miles';
        if (distanceUnit === 'km') {
            unit = ' km'
        }

        return (
            <span>{dist + unit}</span>
        );
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            formatter: nameFormatter,
            headerFormatter: () => showSpanishLabels ? <>Provider/<wbr/>Proveedor</> : 'Name',
            style: {
                whiteSpace: 'nowrap'
            }
        },
        {
            dataField: 'tin',
            text: 'Tax ID',
            headerStyle: {
                width: '6%'
            },
            hidden: (!isUsa || props.selectedNetwork.indexOf('publicDomestic') !== -1)
        },
        {
            dataField: 'id',
            text: 'Provider ID',
            headerStyle: {
                width: '6%'
            },
            headerFormatter: () => showSpanishLabels ? <>Provider ID/<wbr/>Proveedor Identificacion</> : 'Provider ID',
            hidden: isUsa
        },
        {
            dataField: 'addressDisplay',
            text: 'Address',
            formatter: addressFormatter,
            headerFormatter: () => showSpanishLabels ? <>Address/<wbr/>Dirección</> : 'Address'
        },
        {
            dataField: 'phone',
            text: 'Phone',
            hidden: false,
            headerStyle: {
                width: '10rem'
            }
        },
        {
            dataField: 'providerType',
            text: 'Provider Type',
            headerFormatter: () => showSpanishLabels ? <>Provider Type/<wbr/>Tipo de Proveedor</> : 'Provider Type'
        },
        {
            dataField: 'specialty',
            text: 'Specialty',
            hidden: (props.selectedNetwork.toLowerCase().indexOf('domestic') !== -1 || props.selectedNetwork.indexOf('sagicorCore') !== -1),
            headerFormatter: () => showSpanishLabels ? <>Specialty/<wbr/>Especialidad Del Proveedor</> : 'Specialty'
        },
        {
            dataField: 'distance',
            text: 'Range',
            sort: true,
            formatter: rangeFormatter,
            headerStyle: {
                width: '6%'
            },
            headerFormatter: () => showSpanishLabels ? <>Range/<wbr/>Distancia</> : 'Range',
            hidden: searchByType === 'name',
        },
        {
            dataField: 'networkCode',
            text: 'Network Code',
            headerStyle: {
                width: '9%'
            },
            hidden: (props.selectedNetwork.indexOf('sagicor') !== -1 || props.selectedNetwork.indexOf('publicInternational') !== -1 || props.selectedNetwork.indexOf('aetnaLDS') !== -1)
        },
        {
            dataField: 'Create VOB',
            text: 'Create VOB',
            hidden: hideVOB(),
            formatter: vobFormatter,
            headerStyle: {
                width: '10rem'
            }
        },
        {
            dataField: 'Feedback',
            text: 'Feedback',
            hidden: !enableFeedback,
            formatter: feedbackFormatter,
            headerFormatter: () => showSpanishLabels ? <>Feedback/<wbr/>Comentario</> : 'Feedback',
            headerStyle: {
                width: '10rem'
            }
        }
    ];

    const printColumns = [
        {
            dataField: 'name',
            text: 'Name',
            headerFormatter: () => showSpanishLabels ? <>Provider/<wbr/>Proveedor</> : 'Name'
        },
        {
            dataField: 'tin',
            text: 'Tax ID',
            hidden: (!isUsa || props.selectedNetwork.indexOf('publicDomestic') !== -1)
        },
        {
            dataField: 'id',
            text: 'Provider ID',
            hidden: isUsa,
            headerFormatter: () => showSpanishLabels ? <>Provider ID/<wbr/>Proveedor Identificacion</> : 'Provider ID'
        },
        {
            dataField: 'addressDisplay',
            text: 'Address',
            headerFormatter: () => showSpanishLabels ? <>Address/<wbr/>Dirección</> : 'Address'
        },
        {
            dataField: 'phone',
            text: 'Phone',
            hidden: !isUsa
        },
        {
            dataField: 'providerType',
            text: 'Provider Type',
            headerFormatter: () => showSpanishLabels ? <>Provider Type/<wbr/>Tipo de Proveedor</> : 'Provider Type'
        },
        {
            dataField: 'distance',
            text: 'Range',
            formatter: rangeFormatter,
            hidden: searchByType === 'name',
            headerFormatter: () => showSpanishLabels ? <>Range/<wbr/>Distancia</> : 'Range'
        },
        {
            dataField: 'networkCode',
            text: 'Network Code',
            headerStyle: {
                width: '9%'
            },
            hidden: (props.selectedNetwork.indexOf('sagicor') !== -1 || props.selectedNetwork.indexOf('publicInternational') !== -1 || props.selectedNetwork.indexOf('aetnaLDS') !== -1)
        }
    ];

    const selectionHeaderRenderer = ({indeterminate, ...rest}) => (
        <><input type="checkbox" ref={ (input) => {if (input) input.indeterminate = indeterminate;} } className='' onChange={()=>{}} { ...rest } /> {showSpanishLabels ? <>Select/<wbr/>Seleccione</> : 'Select'}</>
    );

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectColumnPosition: 'right',
        selectionHeaderRenderer: selectionHeaderRenderer,
        headerColumnStyle: {
            width: '5%'
        },
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total m-1">
            Showing { from } to { to } of { size } entries
        </span>
    );
    
    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
            <span className="react-bs-table-sizePerPage-dropdown dropdown">
                <button className="dropdown-toggle btn btn-outline-dark" data-toggle="dropdown" id="dropdownMenuButton" role="group">
                    {currSizePerPage + ' per page'}
                </button>
                <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map(option => (
                        <li key={option.text} onClick={ () => {onSizePerPageChange(option.page); setNumPerPage(option.page)} } 
                            className={ 'dropdown-item' } role='presentation'>
                            { option.text }
                        </li>
                    ))
                }
                </ul>
            </span>
        );

    const paginationOptions = {
        custom: true,
        showTotal: true,
        totalSize: providers.length,
        sizePerPageList: [10, 25, 50],
        sizePerPage: numPerPage,
        nextPageText: 'Next',
        prePageText: 'Previous',
        sizePerPageRenderer,
        paginationTotalRenderer: customTotal
    };

    const printForm = (e) => {
        e.preventDefault();

        window.print();
    }

    return (
        
        <span>{ providers.length > 0 && 
            <PaginationProvider pagination={ paginationFactory(paginationOptions) }>
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                    <div className="browser-table">
                        <div className="row list-view-top">
                            <div className="col-md-4 list-view-pagination">
                                <SizePerPageDropdownStandalone { ...paginationProps } />
                                <PaginationTotalStandalone { ...paginationProps } />
                            </div>
                            <div className="col-md-4">
                                <PaginationListStandalone { ...paginationProps } />
                            </div>
                            <div className="col-md-4">
                                <button onClick={ printForm } className="float-right" id="print" title="Print Selected"><i className="fa fa-print"></i></button>
                                {props.selectedNetwork.indexOf('domesticLocal') >= 0 &&
                                    <button onClick={ launchEmail } className="mr-2 float-right" id="emailProvidersButton" title="Email Selected"><i className="fa fa-envelope"></i></button>
                                }
                            </div>
                        </div>
                        <BootstrapTable bootstrap4 striped wrapperClasses="table-responsive" keyField="hash" data={ providers } columns={ columns } { ...paginationTableProps } selectRow={ selectRow } />
                        <PaginationListStandalone { ...paginationProps } />
                    </div>
                    )
                }
            </PaginationProvider>
        }
        { showTable &&
            <BootstrapTable striped wrapperClasses="table-responsive print-table" keyField="hash" data={ selectedProviders } columns={ printColumns } />
        }
        </span>
    );
};

export default ProviderSearchResultsListView;