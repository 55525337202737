import React, {  } from "react";

const PdfRenderError = () => {

    return (
        <>
        No Report Data Found
        </>
    )
}

export default PdfRenderError;