import AuthService from './authService';
import ErrorDisplay from './errorDisplay';
import Settings from './settings';

import axios from 'axios';

const ClientConfigurationService = {

    getClientConfiguration: (value, callback) => {
        if (typeof value === 'function') {
            ClientConfigurationService.getClientConfiguration2(false, '', value);
        }
        else if (typeof value === 'string') {
            ClientConfigurationService.getClientConfiguration2(false, value, callback);
        }
    },

    getClientConfiguration2: (allowAnonymous, clientId, callback) => {
        var clientConfig = getWithExpiry(Settings.getClientConfigurationKey());

        if (!callback && !clientId && clientConfig) {
            return clientConfig;
        }

        if (clientConfig && clientConfig !== '') {
            callback(clientConfig);
            return;
        }
        if (!clientConfig && !clientId) {
            //get from local storage the client to get client config again
            const currentClient = localStorage.getItem(Settings.getCurrentClientKey());
            if (currentClient) {
                clientId = JSON.parse(currentClient).id;
            }
            else{
                callback("");
                return;
            }

        }
        let token;
        AuthService.getAuthToken(false, (data) => {
            token = data
        });

        axios.get('/api/clientconfiguration/' + clientId, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {
            if (res.data.clientName && res.data.clientName !== '') {
                setWithExpiry(Settings.getClientConfigurationKey(), res.data, 3600000);
                callback(res.data);
            } else {
                if (allowAnonymous === false) {
                    ErrorDisplay.renderError('An error occurred retrieving configuration data needed to process your request. Please try again.');
                    
                }
            }
        }).catch((error => {
            if (allowAnonymous === false) {
                ErrorDisplay.renderError('An error occurred retrieving configuration data needed to process your request. Please try again.');
                
            }
            callback(error);
        }));
    }
}

function setWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export default ClientConfigurationService;