import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, FormLabel } from 'react-bootstrap';
import axios from 'axios';
import MD5 from 'object-hash';
import Dialog from 'react-bootstrap-dialog'

import PatientSearchService from '../../services/patientSearchService';
import Settings from '../../services/settings'
import AnalyticsService from "../../services/analyticsService";

const PatientSearchForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchTypes, setSearchTypes] = useState([]);
    const [selectedSearchType, setSelectedSearchType] = useState('');
    const [selectedSearchTypeText, setSelectedSearchTypeText] = useState('');
    const [isDateField, setIsDateField] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientCodeList, setClientCodeList] = useState([]);
    const [cancelToken, setCancelToken] = useState();
    const [currentSearchHash, setCurrentSearchHash] = useState('');

    let dialogRef = useRef();

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    useEffect(() => {
        var searchTypes = PatientSearchService.getSearchTypes();
        setSearchTypes(searchTypes);
        setClientId($("#clientId").val());

        if (props.searchParams) {
            setSelectedSearchType(props.searchParams.selectedSearchType);
            setSelectedSearchTypeText(props.searchParams.selectedSearchTypeText);
            setSearchTerm(props.searchParams.searchTerm);
        } else {
            setSelectedSearchType(searchTypes[0].code);
            setSelectedSearchTypeText(searchTypes[0].name);
        }

        PatientSearchService.getAvailableClients((data) => {
            setClientCodeList(Object.keys(data));
        });
    }, []);

    useEffect(() => {
        switch (selectedSearchType) {
            case 'DOB': {
                setIsDateField(true);
                break;
            }
            default: {
                setIsDateField(false);
                break;
            }
        }
    }, [selectedSearchType]);

    useEffect(() => {
        if (isIE11 && selectedSearchType == "DOB") {
            intitializeDatepicker();
        }
    }, [isDateField]);

    const intitializeDatepicker = () => {
        $("input[type='date']").datepicker({
            onSelect: function (dateText) {
                $(this).each(function () { this.setCustomValidity('') });
                setSearchTerm(dateText);
            }
        });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        props.searchActive(false);
        props.onPatientDetailsChange([]);
        setIsLoading(true);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        var postData = {
            searchTerm: searchTerm,
            searchType: selectedSearchType,
            client: clientCodeList.join(",")
        };

        var hashVal = MD5(postData);

        if (hashVal === currentSearchHash) {
            return;
        } else if (currentSearchHash !== '' && currentSearchHash !== hashVal) {
            showNewSearchModal(postData);
            return;
        }

        getPatients(postData);
    }

    const getPatients = (request) => {
        if (cancelToken) {
            cancelToken.cancel("Patient search reset");
        }

        setIsLoading(true);

        var searchParams = {
            selectedSearchType,
            selectedSearchTypeText,
            searchTerm
        }

        props.onSearchParamsChange(searchParams);
        setCurrentSearchHash(MD5(request));
        var instanceToken = axios.CancelToken.source();
        setCancelToken(instanceToken);

        PatientSearchService.getPatients(request, instanceToken, function (data) {
            var msgSearchTerm = searchTerm;

            if (selectedSearchType == "DOB") {
                msgSearchTerm = Settings.dateFormatter(msgSearchTerm);
            }

            setCurrentSearchHash('');
            var msg = "Search by " + selectedSearchTypeText.toUpperCase() + " for " + msgSearchTerm.toUpperCase();
            props.onPatientDetailsChange(data);
            props.searchActive(true);
            props.searchMsg(msg);
            setIsLoading(false);
            AnalyticsService.sendEventWithClient("cc_patient_search");
        });
    }

    const resetForm = (e) => {
        if (cancelToken) {
            cancelToken.cancel("Patient search reset");
        }

        setCurrentSearchHash('');
        setIsLoading(false);
        setSelectedSearchType(searchTypes[0].code);
        setSelectedSearchTypeText(searchTypes[0].name);
        setSearchTerm('');
        props.onPatientDetailsChange([]);
        props.searchActive(false);
    }

    const onSelectChange = (e) => {
        setSelectedSearchType(e.target.value);
        setSelectedSearchTypeText(e.target.options[e.target.selectedIndex].innerHTML);
        setSearchTerm('');
    }

    const onDateChange = (e) => {
        setSearchTerm(e.target.value);
        e.target.setCustomValidity('');
    }

    const onTextInvalid = (e) => {
        if (selectedSearchType === 'CLAIMNO') {
            e.target.setCustomValidity('Please enter an 11-digit Indicent #');
        } else {
            e.target.setCustomValidity('Please enter a value to search by');
        }
    }

    const showNewSearchModal = (data) => {
        const customBody = (
            <p>
                Your previous search is still running. Would you like to proceed with these new search parameters instead?
            </p>
        )

        dialogRef.show({
            body: customBody,
            bsSize: 'medium',
            actions: [
                Dialog.Action(
                    'No',
                    () => { },
                    'btn btn-outline-dark'
                ),
                Dialog.Action(
                    'Yes',
                    () => getPatients(data),
                    'btn btn-outline-dark'
                )
            ],
        });
    }

    return (
        <>
            <Dialog ref={(component) => { dialogRef = component }} />
            <div className="g-ml-25">
                <h1>Patient Search</h1>
            </div>
            <div className="lead g-mb-40 g-ml-25">
                <p>Use the drop down and field below for all your patient &amp; case search.</p>
            </div>
            <div className="row justify-content-center patientSearchForm">
                <div className="col-md-6">
                    <Form onSubmit={onFormSubmit}>
                        <Form.Group as={Row} controlId="searchType">
                            <Form.Label column md="4" className="required">
                                Search By:
                    </Form.Label>
                            <Col md="6">
                                <Form.Control value={selectedSearchType} as="select" onChange={e => onSelectChange(e)}>
                                    {
                                        searchTypes.map((type, index) => {
                                            return (
                                                <option key={index} value={type.code}>{type.name}</option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="searchTerm">
                            <Form.Label column md="4" className="required">
                                Search For:
                        </Form.Label>
                            <Col md="6">
                                {!isDateField &&
                                    <Form.Control minLength={selectedSearchType === 'CLAIMNO' ? 11 : ''} maxLength={selectedSearchType === 'CLAIMNO' ? 11 : ''} value={searchTerm} as="input" required={true} onChange={e => setSearchTerm(e.target.value)} onInvalid={onTextInvalid} onInput={(e) => e.target.setCustomValidity('')}></Form.Control>
                                }
                                {isDateField &&
                                    <Form.Control value={searchTerm} as="input" type="date" placeholder="mm/dd/yyyy" required={true} onChange={(e) => onDateChange(e)} onInvalid={(e) => e.target.setCustomValidity('Please enter a date to search by')} onInput={(e) => e.target.setCustomValidity('')}></Form.Control>
                                }
                            </Col>
                        </Form.Group>

                        <Button variant="primary" type="submit" className="btn btn-md u-btn-primary">Search</Button>
                        <Button variant="primary" type="reset" className="gmmi-red" onClick={resetForm}>Reset</Button>
                    </Form>
                </div>
                {isLoading &&
                    <Col md="12" className="justify-content-center providersLoading">
                        <div className="spinner-border"></div>
                    </Col>
                }
            </div>
        </>
    );
};

export default PatientSearchForm;