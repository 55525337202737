import React, { useState, useEffect } from "react";
import Settings from '../../services/settings';

const PatientHeader = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.patientData) {
            setData(props.patientData);
        }
    }, [props.patientData]);

    return (
        <>
            <div className="row list-view-top">
                <div className="col-xl-4 col-md-5 offset-md-1"><strong>Patient Name:</strong></div>
                <div className="col-xl-7 col-md-6">{data.patientName}</div>
            </div>
            <div className="row list-view-top">
                <div className="col-xl-4 col-md-5 offset-md-1"><strong>D.O.B.:</strong></div>
                <div className="col-xl-7 col-md-6">{Settings.dateFormatter(data.dateOfBirth)}</div>
            </div>
            <div className="row list-view-top">
                <div className="col-xl-4 col-md-5 offset-md-1"><strong>Policy #:</strong></div>
                <div className="col-xl-7 col-md-6">{data.policyNumber}</div>
            </div>
            <div className="row list-view-top">
                <div className="col-xl-4 col-md-5 offset-md-1"><strong>Policy Effective Date:</strong></div>
                <div className="col-xl-7 col-md-6">{Settings.dateFormatter(data.effectiveDate)}</div>
            </div>
            <div className="row list-view-top">
                <div className="col-xl-4 col-md-5 offset-md-1"><strong>Incident #:</strong></div>
                <div className="col-xl-7 col-md-6">{data.incidentNumber}</div>
            </div>
            <div className="row list-view-top">
                <div className="col-xl-4 col-md-5 offset-md-1"><strong>Date of Incident:</strong></div>
                <div className="col-xl-7 col-md-6">{Settings.dateFormatter(data.dateOfIncident)}</div>
            </div>
            <div className="row list-view-top">
                <div className="col-xl-4 col-md-5 offset-md-1"><strong>Description:</strong></div>
                <div className="col-xl-7 col-md-6">{data.incidentDescription}</div>
            </div>
        </>
    );
};

export default PatientHeader;