import React from 'react';

import './Footer.css'

const Footer = () => {

    return (
        <div className="entry-content content">

            <div className="x-section e665-e9 mih-0 mih-2">
                <div className="x-row e665-e10 mih-3 mih-4 mih-6">
                    <div className="x-row-inner">
                        <div className="x-col e665-e11 mih-7">
                            <div className="cs-content x-global-block x-global-block-92 e665-e12">
                                <div className="x-section e92-e2 m2k-0 m2k-1">
                                    <div className="x-row x-container max width e92-e3 m2k-3 m2k-4 m2k-5">
                                        <div className="x-row-inner">
                                            <div className="x-col e92-e4 m2k-7">
                                                <div className="x-text x-content e92-e5 m2k-8 m2k-9 m2k-a m2k-b">
                                                    <p><a href="https://www.gmmi.com/"><img decoding="async"
                                                        alt="GMMI logo white" width="224" height="72"
                                                        style={{textAlign: 'left'}}
                                                        data-src="/wp-content/uploads/2022/09/logo-footer.png"
                                                        className=" ls-is-cached lazyloaded"
                                                        src="https://www.gmmi.com/wp-content/uploads/2022/09/logo-footer.png" /><noscript><img
                                                            decoding="async"
                                                            src="https://www.gmmi.com/wp-content/uploads/2022/09/logo-footer.png"
                                                            alt="GMMI logo white" width="224" height="72"
                                                            style={{textAlign: 'left'}} /></noscript></a></p>
                                                    <p style={{fontSize: '12px', lineHeight: '14px', color: '#fff'}}>GMMI is a
                                                        leading ISO 9001:2015 &amp; ISO/IEC 27001:2013 certified provider of
                                                        cost containment and medical risk management solutions.</p>
                                                    <p style={{fontSize: '12px', lineHeight: '14px', color: 'rgb(221, 221, 221)'}}>We
                                                        are Passionate for People. <br />
                                                        Focused on Client Results. <br />
                                                        Driven by Legendary Service.</p>
                                                </div>
                                            </div>
                                            <div className="x-col e92-e6 m2k-7">
                                                <div className="x-text x-content e92-e7 m2k-8 m2k-9 m2k-c">
                                                    <h5><span style={{color: '#ffffff', fontSize:'17px'}}>Follow Us</span></h5>
                                                </div>
                                                <hr className="x-line e92-e8 m2k-f m2k-g" /><a
                                                    className="x-anchor x-anchor-button has-graphic e92-e9 m2k-i m2k-j" tabIndex="0"
                                                    href="https://www.linkedin.com/authwall?trk=bf&amp;trkInfo=AQH7UnUnNvXZAgAAAYNgnjLggOnaU_KtjnrdxgRxCUfRVeDAEkf_kK8kDfeEAe6lZ3FqgwX-b5WjiQPPW6wbNn6ji3S5whESCBTJNKFcFyrQJybwLx0XxSDuRiQ9-avdBrs20Gc=&amp;original_referer=&amp;sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fgmmi-inc-%2F"
                                                    target="_blank">
                                                    <div className="x-anchor-content"><span className="x-graphic" aria-hidden="true"><i
                                                        className="x-icon x-graphic-child x-graphic-icon x-graphic-primary"
                                                        aria-hidden="true" data-x-icon-b=""><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" className="bi bi-linkedin"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                        </svg></i></span>
                                                        <div className="x-anchor-text"><span
                                                            className="x-anchor-text-primary">LinkedIn</span></div>
                                                    </div>
                                                </a>
                                                <hr className="x-line e92-e10 m2k-g m2k-h" /><a
                                                    className="x-anchor x-anchor-button has-graphic e92-e11 m2k-i m2k-j"
                                                    tabIndex="0" href="https://www.youtube.com/channel/UCzyC_w5ZAgxRoPpN7BrZfXQ"
                                                    target="_blank">
                                                    <div className="x-anchor-content"><span className="x-graphic" aria-hidden="true"><i
                                                        className="x-icon x-graphic-child x-graphic-icon x-graphic-primary"
                                                        aria-hidden="true" data-x-icon-b=""><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" className="bi bi-youtube"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z" />
                                                        </svg></i></span>
                                                        <div className="x-anchor-text"><span
                                                            className="x-anchor-text-primary">YouTube</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="x-col e92-e12 m2k-7">
                                                <div className="x-text x-content e92-e13 m2k-8 m2k-9 m2k-c">
                                                    <h5><span style={{color: '#ffffff', fontSize:'17px'}}>Our SOlutions</span></h5>
                                                </div>
                                                <hr className="x-line e92-e14 m2k-f m2k-g" />
                                                <ul className="x-menu-first-level x-menu x-menu-layered x-current-layer e92-e15 m2k-l"
                                                    data-x-toggle-layered-root="1" style={{height: '196px'}}>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-81"
                                                        id="menu-item-81"><a className="x-anchor x-anchor-menu-item m2k-i m2k-k"
                                                            tabIndex="0"
                                                            href="https://www.gmmi.com/solutions-overview/">
                                                            <div className="x-anchor-content">
                                                                <div className="x-anchor-text"><span
                                                                    className="x-anchor-text-primary">Solutions Overview</span>
                                                                </div>
                                                            </div>
                                                        </a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-87"
                                                        id="menu-item-87"><a className="x-anchor x-anchor-menu-item m2k-i m2k-k"
                                                            tabIndex="0"
                                                            href="https://www.gmmi.com/medical-cost-containment-claims-management/">
                                                            <div className="x-anchor-content">
                                                                <div className="x-anchor-text"><span
                                                                    className="x-anchor-text-primary">Medical Cost Containment
                                                                    &amp; Claims Management</span></div>
                                                            </div>
                                                        </a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-86"
                                                        id="menu-item-86"><a className="x-anchor x-anchor-menu-item m2k-i m2k-k"
                                                            tabIndex="0"
                                                            href="https://www.gmmi.com/24-7-365-worldwide-assistance/">
                                                            <div className="x-anchor-content">
                                                                <div className="x-anchor-text"><span
                                                                    className="x-anchor-text-primary">24/7/365 Worldwide
                                                                    Assistance</span></div>
                                                            </div>
                                                        </a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-85"
                                                        id="menu-item-85"><a className="x-anchor x-anchor-menu-item m2k-i m2k-k"
                                                            tabIndex="0"
                                                            href="https://www.gmmi.com/best-in-class-medical-provider-networks/">
                                                            <div className="x-anchor-content">
                                                                <div className="x-anchor-text"><span
                                                                    className="x-anchor-text-primary">Best-in-Class Medical
                                                                    Provider Networks</span></div>
                                                            </div>
                                                        </a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84"
                                                        id="menu-item-84"><a className="x-anchor x-anchor-menu-item m2k-i m2k-k"
                                                            tabIndex="0"
                                                            href="https://www.gmmi.com/evidence-based-medical-case-management-utilization-review/">
                                                            <div className="x-anchor-content">
                                                                <div className="x-anchor-text"><span
                                                                    className="x-anchor-text-primary">Evidence-Based Medical
                                                                    Case Management &amp; Utilization&nbsp;Review</span>
                                                                </div>
                                                            </div>
                                                        </a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-83"
                                                        id="menu-item-83"><a className="x-anchor x-anchor-menu-item m2k-i m2k-k"
                                                            tabIndex="0"
                                                            href="https://www.gmmi.com/international-tpa/">
                                                            <div className="x-anchor-content">
                                                                <div className="x-anchor-text"><span
                                                                    className="x-anchor-text-primary">International TPA</span>
                                                                </div>
                                                            </div>
                                                        </a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-82"
                                                        id="menu-item-82"><a className="x-anchor x-anchor-menu-item m2k-i m2k-k"
                                                            tabIndex="0"
                                                            href="https://www.gmmi.com/gmmi-online-portal/">
                                                            <div className="x-anchor-content">
                                                                <div className="x-anchor-text"><span
                                                                    className="x-anchor-text-primary">GMMI Online Portal</span>
                                                                </div>
                                                            </div>
                                                        </a></li>
                                                </ul>
                                            </div>
                                            <div className="x-col e92-e16 m2k-7">
                                                <div className="x-text x-content e92-e17 m2k-8 m2k-9 m2k-c">
                                                    <h5><span style={{color: '#ffffff', fontSize:'17px'}}>Contact Us</span></h5>
                                                </div>
                                                <hr className="x-line e92-e18 m2k-f m2k-g" />
                                                <div className="x-text x-content e92-e19 m2k-8 m2k-9 m2k-a m2k-d">
                                                    <p style={{fontSize: '12px', lineHeight: '14px', color: '#dddddd'}}>Phone Number:
                                                        +1 (954) 370-6404<br />
                                                        Toll-Free (in the U.S. &amp; Canada):<br />
                                                        800-682-6065</p>
                                                    <p>Email: <span style={{color: '#dddddd'}}><a href="mailto:info@gmmi.com"
                                                        style={{color: '#dddddd'}}>info@gmmi.com</a></span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="x-section e92-e20 m2k-0 m2k-2">
                                    <div className="x-row x-container max width e92-e21 m2k-3 m2k-4 m2k-6">
                                        <div className="x-row-inner">
                                            <div className="x-col e92-e22 m2k-7">
                                                <div className="x-text x-content e92-e23 m2k-8 m2k-a m2k-b m2k-e">
                                                    <p style={{textAlign: 'center'}}><span style={{color: '#888888'}}>2024 © All
                                                        Rights Reserved. &nbsp;</span></p>
                                                    <p style={{textAlign: 'center'}}><span style={{color: '#999999'}}><a
                                                        style={{color: '#999999'}}
                                                        href="https://www.gmmi.com/code-of-conduct/">Code
                                                        of Conduct</a> | <a style={{color: '#999999'}}
                                                            href="https://www.gmmi.com/gdpr/">GDPR</a> | <a
                                                                style={{color: '#999999'}}
                                                                href="https://www.gmmi.com/pipeda/">PIPEDA</a>
                                                        &nbsp;| <a style={{color: '#999999'}}
                                                            href="https://www.gmmi.com/terms-of-service/">Terms
                                                            of Use</a> | <a style={{color: '#999999'}}
                                                                href="https://www.gmmi.com/privacy-policy/">Privacy
                                                            Policy</a> | <a style={{color: '#999999'}}
                                                                href="https://www.gmmi.com/california-privacy-notice/">California
                                                            Privacy Notice</a> | <a style={{color: '#999999'}}
                                                                href="https://survey.alchemer.com/s3/7453462/personal-data-request"
                                                                target="_blank" rel="noopener">Personal Data Access Form</a> |<a
                                                                    style={{color: '#999999'}}
                                                                    href="https://forms.office.com/Pages/ResponsePage.aspx?id=crA9xtW3T0qqSIOpa39vl7mmg6QhMmFGpunRh38plJ5UOEZQUzNPRkE2UExGSUlNMTFST0Y0RU1CWC4u"
                                                                    target="_blank" rel="noopener"> Responsible Data Leak Disclosure
                                                            Form</a></span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Footer;