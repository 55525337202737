import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import React from 'react'
import Settings from '../services/settings';
import { loginRequest } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    // State to hold the authentication token
    const [token, setToken_] = useState('');
    const [clientId, setClientId] = useState('');  

    // Function to set the authentication token
    const setToken = (newToken) => {
        setToken_(newToken);
        localStorage.setItem('user_token', JSON.stringify(newToken))
    };

    const getAuthToken = (allowAnonymous) => {
         if (allowAnonymous) {
            return "";
        }
        //get it from react context
        if (token && token !== "") {
            return token;
        }
    }

    const removeToken = () => {
        setToken("");  
        localStorage.removeItem(Settings.getClientConfigurationKey());
        localStorage.removeItem(Settings.getCurrentClientKey());     
        localStorage.removeItem(Settings.getIsGMMIKey());
    };

    useEffect(() => {
            if (account) {
                instance.acquireTokenSilent({
                    scopes: ["User.Read"],
                    account: account
                }).then((response) => {
                    if (response) {
                        // Function to set the authentication token
                        setToken_(response.idToken);
                        setClientId(response.idTokenClaims['extn.ClientId'].toString());
                        localStorage.setItem('user_token', JSON.stringify(response.idToken))
                    }
                }).catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({
                            ...loginRequest,
                            account: instance.getActiveAccount()
                        });
                    }
                });
            }
        
    }, [account, instance]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            getAuthToken,
            removeToken,
            clientId
        }),
        [token, clientId]
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;