import 'react-notifications-component/dist/theme.css'
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Form, Row, Col, Button, FormLabel, Modal } from 'react-bootstrap';
import ProviderSearchService from '../../services/providerSearchService';
import ClientConfigurationService from '../../services/clientConfigurationService';
import AnalyticsService from '../../services/analyticsService';
import ErrorDisplay from '../../services/errorDisplay';
import $ from 'jquery';
import { useAuth } from "../../providers/authProvider";

const CreateVOBForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [provider, setProvider] = useState();
    const [renderForm, setRenderForm] = useState(false);
    const [vobData, setVobData] = useState();
    const [previewImageUrl, setPreviewImageUrl] = useState();
    const [previewDisplayed, setPreviewDisplayed] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [policyFromDate, setPolicyFromDate] = useState(null);
    const [policyToDate, setPolicyToDate] = useState(null);
    const { token } = useAuth();

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    useEffect(() => {
    }, []);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        setProvider(props.provider);

        ClientConfigurationService.getClientConfiguration(function (clientConfig) {
            var postData = {
                providerSearchType: props.providerSearchInfo.code,
                client: Object.keys(clientConfig.clientCodes).join()
            };

            ProviderSearchService.getVOBData(props.selectedNetwork, props.providerSearchInfo.code, postData, function (data) {
                setVobData(data);
            });
        });
    }, [props.provider]);

    useEffect(() => {
        if (vobData) {
            setRenderForm(true);
        }
    }, [vobData]);

    useEffect(() => {
        if (renderForm && isIE11) {
            $("input[type='date']").datepicker({
                beforeShow: function (input, inst) {
                    var offset = $(input).offset();
                    var height = $(input).height();
                    window.setTimeout(function () {
                        $(inst.dpDiv).css({ top: (offset.top + height) + 'px' })
                    }, 1);
                }
            });
        }
    }, [renderForm]);

    const handleClose = () => {
        setShow(false);
        props.onCreateVOBClose();
    }

    const handleClosePreview = () => {
        setPreviewDisplayed(false);
        setPreviewImageUrl('');
    }

    const previewVOB = (e) => {
        const form = document.querySelector("#createVOB");
        var isValid = form.checkValidity();

        if (!isValid) {
            ErrorDisplay.renderError((
                <ul><li>All required fields must be entered</li></ul>
            ));

            return;
        }

        var startDateParsed = new Date(policyFromDate);
        var endDateParsed = new Date(policyToDate);

        if (startDateParsed > endDateParsed) {
            isValid = false;

            ErrorDisplay.renderError((
                <ul><li>Policy From date must be before Policy To date</li></ul>
            ));
        }

        if (!isValid) {

            return;
        }

        if (previewLoading) {
            return;
        }

        setPreviewLoading(true);

        ProviderSearchService.getVOBPreview(props.selectedNetwork, props.providerSearchInfo.code, $('#createVOB').serialize(), function (data) {
            if (data) {
                setPreviewDisplayed(true);
                setPreviewLoading(false);
                setPreviewImageUrl(URL.createObjectURL(data));
            }
        })
    }

    const handleSubmit = (e) => {
        AnalyticsService.sendEventWithClient('cc_vob_self_issue');
    }

    return (
        <Modal size="xl" show={show} onHide={handleClose} dialogClassName="vob" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title className="h1">Create VOB</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!renderForm &&
                    <div className="text-center providersLoading">
                        <div className="spinner-border"></div>
                    </div>
                }
                {renderForm &&
                    <Form id="createVOB" method="post" action="/api/createVOB" target="new" className={previewDisplayed ? 'hidden' : ''}>
                        <input type="hidden" name="Token" value={token}></input>
                        <Row className="vobFormRow">
                            <Col md="1">

                            </Col>
                            <Col md="10">
                                <Row>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="providerName">
                                            <Form.Label column md="5" className="required">
                                                Provider Name:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="providerName" required={true} as="input" defaultValue={provider.name || ""} ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="providerContact">
                                            <Form.Label column md="5" className="required">
                                                Provider Contact:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="providerContact" required={true} as="input" defaultValue={provider.contact || ""} ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="providerPhone">
                                            <Form.Label column md="5" className="required">
                                                Provider Phone:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="providerPhone" required={true} as="input" defaultValue={provider.phone || ""} ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="providerTaxId">
                                            <Form.Label column md="5" className="required">
                                                Provider Tax ID:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="providerTaxId" required={true} as="input" defaultValue={provider.tin || ""} ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="providerAddress">
                                            <Form.Label column md="5" className="required">
                                                Provider Address:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="providerAddress" required={true} as="input" defaultValue={provider.addressOne + (provider.addressTwo ? ' ' + provider.addressTwo : '')} ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="providerCityStateZip">
                                            <Form.Label column md="5" className="required">
                                                Provider City State Zip:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="providerCityStateZip" required={true} as="input" defaultValue={provider.city + ', ' + provider.state + ' ' + provider.zipcode} ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="providerFax">
                                            <Form.Label column md="5" className="required">
                                                Provider Fax:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="providerFax" required={true} as="input" defaultValue={provider.fax || ""} ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-4 vobFormRow">
                            <Col md="12">
                                <p>Enter the values below to create a VOB. All fields with an asterisk(*) are required.</p>
                                <h4>Patient Information</h4>
                            </Col>
                            <Col md="10" className="offset-md-1">
                                <Row>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="patientLastName">
                                            <Form.Label column md="5" className="required">
                                                Patient Last Name:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="patientLastName" required={true} as="input"></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="patientFirstName">
                                            <Form.Label column md="5" className="required">
                                                Patient First Name:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="patientFirstName" required={true} as="input"></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="patientDOB">
                                            <Form.Label column md="5" className="required">
                                                Patient DOB:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="patientDOB" type="date" required={true} as="input"></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="policyFrom">
                                            <Form.Label column md="5" className="required">
                                                Policy From:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="policyFrom" type="date" required={true} as="input" onChange={(e) => setPolicyFromDate(e.target.value)}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="policyTo">
                                            <Form.Label column md="5" className="required">
                                                Policy To:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="policyTo" type="date" required={true} as="input" onChange={(e) => setPolicyToDate(e.target.value)}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="patientPolicyNumber">
                                            <Form.Label column md="5" className="required">
                                                Patient Policy #:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="patientPolicyNumber" required={true} as="input"></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    {Object.keys(vobData.groups).length > 0 &&
                                        <Col md="6" xl="4">
                                            <Form.Group as={Row} controlId="groupId">
                                                <Form.Label column md="5" className="required">
                                                    Group ID:
                                                </Form.Label>
                                                <Col md="7">
                                                    <Form.Control as="select" name="groupId" required={true}>
                                                        {Object.keys(vobData.groups).map((group, index) => {
                                                            return (
                                                                <option key={index} value={group}>{vobData.groups[group]}</option>
                                                            );
                                                        })
                                                        }
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-4 vobFormRow">
                            <Col md="12">
                                <h4>GMMI Fields</h4>
                            </Col>
                            <Col md="10" className="offset-md-1">
                                <Row>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="dateOfService">
                                            <Form.Label column md="5" className="required">
                                                Date of Service:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="dateOfService" type="date" required={true} as="input"></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="copayment">
                                            <Form.Label column md="5" className="required">
                                                Co-Payment:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="copayment" required={true} as="input"></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="reference">
                                            <Form.Label column md="5" className="required">
                                                Medical Complaint:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="reference" required={true} as="input" maxLength={40}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="deductible">
                                            <Form.Label column md="5" className="required">
                                                Deductible:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="deductible" required={true} as="input"></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="coverageLimit">
                                            <Form.Label column md="5" className="required">
                                                Coverage Limit:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="coverageLimit" required={true} as="input"></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    {Object.keys(vobData.carriers).length > 0 &&
                                        <Col md="6" xl="4">
                                            <Form.Group as={Row} controlId="clientName">
                                                <Form.Label column md="5" className="required">
                                                    Carrier:
                                                </Form.Label>
                                                <Col md="7">
                                                    <Form.Control as="select" name="clientName" required={true}>
                                                        {Object.keys(vobData.carriers).map((carrier, index) => {
                                                            return (
                                                                <option key={index} value={carrier}>{vobData.carriers[carrier]}</option>
                                                            );
                                                        })
                                                        }
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    }
                                    {Object.keys(vobData.mailing).length > 0 &&
                                        <Col md="6" xl="4">
                                            <Form.Group as={Row} controlId="clientMailingAddress">
                                                <Form.Label column md="5" className="required">
                                                    Mailing address:
                                                </Form.Label>
                                                <Col md="7">
                                                    <Form.Control as="select" name="clientMailingAddress" required={true}>
                                                        {Object.keys(vobData.mailing).map((mailing, index) => {
                                                            return (
                                                                <option key={index} value={mailing}>{vobData.mailing[mailing]}</option>
                                                            );
                                                        })
                                                        }
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    }
                                    {Object.keys(vobData.phoneNumbers).length > 0 &&
                                        <Col md="6" xl="4">
                                            <Form.Group as={Row} controlId="clientPhone">
                                                <Form.Label column md="5" className="required">
                                                    Phone:
                                                </Form.Label>
                                                <Col md="7">
                                                    <Form.Control as="select" name="clientPhone" required={true}>
                                                        {Object.keys(vobData.phoneNumbers).map((phone, index) => {
                                                            return (
                                                                <option key={index} value={phone}>{vobData.phoneNumbers[phone]}</option>
                                                            );
                                                        })
                                                        }
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    }
                                    {Object.keys(vobData.faxNumbers).length > 0 &&
                                        <Col md="6" xl="4">
                                            <Form.Group as={Row} controlId="clientFax">
                                                <Form.Label column md="5" className="required">
                                                    Fax:
                                                </Form.Label>
                                                <Col md="7">
                                                    <Form.Control as="select" name="clientFax" required={true}>
                                                        {Object.keys(vobData.faxNumbers).map((fax, index) => {
                                                            return (
                                                                <option key={index} value={fax}>{vobData.faxNumbers[fax]}</option>
                                                            );
                                                        })
                                                        }
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    }
                                    <Col md="6" xl="4">
                                        <Form.Group as={Row} controlId="notes">
                                            <Form.Label column md="5" className="notes">
                                                Notes:
                                            </Form.Label>
                                            <Col md="7">
                                                <Form.Control name="notes" as="input" maxLength={200}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    {vobData.sincerely &&
                                        <Col md="6" xl="4">
                                            <Form.Group as={Row} controlId="sincerely">
                                                <Form.Label column md="5" className="required">
                                                    Sincerely:
                                                </Form.Label>
                                                <Col md="7">
                                                    <Form.Control name="sincerely" required={true} as="input"></Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                            <input type="hidden" name="ProviderDiscountNetwork" value={provider.network || ""}></input>
                            <input type="hidden" name="ProviderSearchType" value={props.selectedNetwork}></input>
                            <input type="hidden" name="ProviderSearchCode" value={props.providerSearchInfo.code}></input>
                            <input type="hidden" name="Token" value={props.providerSearchInfo.code}></input>
                        </Row>
                        {previewDisplayed &&
                            <img src={previewImageUrl} />
                        }
                        {previewLoading &&
                            <div className="text-center providersLoading">
                                <div className="spinner-border"></div>
                            </div>
                        }
                        <Row className="mt-3">
                            <Col md="12" className="text-center">
                                {previewDisplayed &&
                                    <>
                                        <Button onClick={handleSubmit} type="submit" variant="primary" className="btn btn-md u-btn-primary mr-2">Create VOB</Button>
                                        <Button onClick={handleClosePreview} variant="primary" className="btn btn-md u-btn-primary mr-2">Back</Button>
                                    </>
                                }
                                {!previewDisplayed &&
                                    <Button onClick={previewVOB} variant="primary" className="btn btn-md u-btn-primary mr-2">Preview</Button>
                                }
                                <Button onClick={handleClose} variant="primary" className="gmmi-red">Cancel</Button>
                            </Col>

                        </Row>
                    </Form>
                }
            </Modal.Body>
        </Modal>
    )
};

export default CreateVOBForm;