import React, { useEffect, useState } from "react";
import { AppLayout, Layout } from './Layout';
import { useAuth } from "../providers/authProvider";
import { useHistory } from "react-router-dom";
import AuthService from "../services/authService";

const ErrorPage = (props) => {


  return (
    <>
        <div>An error has occured</div>
    </>
  );
}

export default ErrorPage;