import ErrorDisplay from './errorDisplay';
import axios from 'axios';

const StrapiService = {

    getClientHomePage: (token, callback) => {
        axios.get('/api/client-home-page', {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then(res => {
            callback(res.data);
        }).catch((error => {
            ErrorDisplay.renderError('An error occurred getting home page');
        }));
    },
    getAccountManagerPicture: (email, token, callback) => {
        axios.get('/api/account-manager-picture/' + email, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {
            callback(res.data);
        }).catch((error => {
            callback(error);
        }));
    },
    getProviderSearchDetail: (urlSlug, token, callback) => {
        axios.get('/api/provider-search-detail/' + urlSlug, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {
            callback(res.data);
        }).catch((error => {
            ErrorDisplay.renderError('An error occurred getting provider search detail');
        }));
    },
}

export default StrapiService;