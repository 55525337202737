import 'react-notifications-component/dist/theme.css'
import React, { useState, useEffect } from "react";
import { Button, Row, Col } from 'react-bootstrap';

const Picklist = (props) => {
    const [initialList, setInitialList] = useState([]);
    const [column1, setColumn1] = useState([]);
    const [column2, setColumn2] = useState([]);
    const [leftSelectedIndexes, setLeftSelectedIndexes] = useState([]);
    const [rightSelectedIndexes, setRightSelectedIndexes] = useState([]);

    useEffect(() => {
        if (props.data) {
            var keys = Object.keys(props.data);

            if (props.initialSelected && props.initialSelected.length > 0) {
                var newList = [...keys];
                var newColumn2 = [...column2];
                var movedItems = [];
    
                for (var i = props.initialSelected.length - 1; i >= 0; i--) {
                    var index = keys.indexOf(props.initialSelected[i]);
                    var item = keys[index];
                    movedItems.push(item);
                    newList.splice(index, 1);
                }
    
                movedItems.reverse();
                setColumn1(getSortedDeDupedItems(newList));
                setColumn2(getSortedDeDupedItems(newColumn2.concat(movedItems)));
                setInitialList(keys);
            } else {
                setColumn1(getSortedDeDupedItems(keys));
                setInitialList(keys);
            }
        }
    }, [props.data]);

    useEffect(() => {
        props.onChange(column2);
    }, [column2]);

    const onLeftDoubleClick = (index) => {
        var item = column1[index];
        var newList = [...column1];
        newList.splice(index, 1);
        setColumn1(getSortedDeDupedItems(newList));
        setColumn2(getSortedDeDupedItems([...column2, item]));
    }

    const onLeftChange = (e) => {
        var selected = [];

        for (var i = 0; i < e.target.length; i++) {
            if (e.target[i].selected) {
                selected.push(i);
            }
        }

        setLeftSelectedIndexes(selected);
    }

    const onRightChange = (e) => {
        var selected = [];

        for (var i = 0; i < e.target.length; i++) {
            if (e.target[i].selected) {
                selected.push(i);
            }
        }

        setRightSelectedIndexes(selected);
    }

    const onRightDoubleClick = (index) => {
        var item = column2[index];
        var indexToInsert = initialList.indexOf(item);
        var newList = [...column2];
        newList.splice(index, 1);
        setColumn2(getSortedDeDupedItems(newList));
        
        var newLeftList = [...column1];
        newLeftList.splice(indexToInsert, 0, item)
        setColumn1(getSortedDeDupedItems(newLeftList));
    }

    const moveRight = () => {
        var newList = [...column1];
        var newColumn2 = [...column2];
        var movedItems = [];

        for (var i = leftSelectedIndexes.length - 1; i >= 0; i--) {
            var index = leftSelectedIndexes[i];
            var item = column1[index];
            movedItems.push(item);
            newList.splice(index, 1);
        }

        movedItems.reverse();
        setColumn1(getSortedDeDupedItems(newList));
        setColumn2(getSortedDeDupedItems(newColumn2.concat(movedItems)));
    }

    const moveLeft = () => {
        var newList = [...column2];
        var newColumn1 = [...column1];

        for (var i = rightSelectedIndexes.length - 1; i >= 0; i--) {
            var index = rightSelectedIndexes[i];
            newList.splice(index, 1);
        }

        for (var i = 0; i < rightSelectedIndexes.length; i++) {
            var index = rightSelectedIndexes[i];
            var item = column2[index];
            var indexToInsert = initialList.indexOf(item);
            newColumn1.splice(indexToInsert, 0, item)
        }

        setColumn2(getSortedDeDupedItems(newList));
        setColumn1(getSortedDeDupedItems(newColumn1));
    }

    const moveAllLeft = () => {
        setColumn1(getSortedDeDupedItems(initialList));
        setColumn2([]);
    }

    const moveAllRight = () => {
        setColumn2(getSortedDeDupedItems(initialList));
        setColumn1([]);
    }

    const getSortedDeDupedItems = (columnList) => {
        var sortedList = [];
        var arrLength = columnList.length;

        // dedupe
        for (var i = 0; i < arrLength; i++) {
            var sortedListLength = sortedList.length;
            var dupFound = false;
            for (var s = 0; s < sortedListLength; s++) {
                if (columnList[i] === sortedList[s]) {
                    dupFound = true;
                }
            }

            if (columnList[i] === "") {
                dupFound = true;
            }

            if (dupFound === false) {
                sortedList.push(columnList[i]);
            }
        }

        //sort
        sortedList.sort((a, b) => (props.data[a].toLowerCase() > props.data[b].toLowerCase() ? 1 : -1));

        return sortedList;
    }

    return (
        <>
            <Col md="12">
                <Row>
                    <Col xs="5">
                        {props.leftLabel &&
                            <p className="mb-1">{props.leftLabel}</p>
                        }
                    </Col>
                    <Col xs="2"></Col>
                    <Col xs="5">
                        {props.rightLabel &&
                            <p className="mb-1">{props.rightLabel}</p>
                        }
                    </Col>
                </Row>
            </Col>
            <Col xs="5">
                <select className="custom-select" multiple onChange={onLeftChange} size="6">
                    {
                        column1.map((code, index) => {
                            return (
                                <option onDoubleClick={() => onLeftDoubleClick(index)} key={index} value={code}>{props.data[code]}</option>
                            );
                        })
                    }
                </select>
            </Col>
            <Col xs="2">
                <Row className="justify-content-center"><Button className="d-block" variant="outline-dark" onClick={moveAllLeft}><small>{"<<"}</small></Button></Row>
                <Row className="justify-content-center"><Button className="d-block" variant="outline-dark" onClick={moveLeft}><small>{"<"}</small></Button></Row>
                <Row className="justify-content-center"><Button className="d-block" variant="outline-dark" onClick={moveRight}><small>{">"}</small></Button></Row>
                <Row className="justify-content-center"><Button className="d-block" variant="outline-dark" onClick={moveAllRight}><small>{">>"}</small></Button></Row>
            </Col>
            <Col xs="5">
                <select className="custom-select" multiple onChange={onRightChange} size="6">
                    {
                        column2.map((code, index) => {
                            return (
                                <option onDoubleClick={() => onRightDoubleClick(index)} key={index} value={code}>{props.data[code]}</option>
                            );
                        })
                    }
                </select>
            </Col>
        </>
    )
};

export default Picklist;
