import React, { useState, useEffect } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import filterFactory, { textFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import moment from 'moment';

import Settings from '../../services/settings'
import { useAuth } from "../../providers/authProvider";

const PatientSearchResultsListView = (props) => {
    const [data, setData] = useState([]);
    const [searchMsg, setSearchMsg] = useState('');
    const [noResultsMsg, setNoResultsMsg] = useState('No results');
    const [searchActive, setSearchActive] = useState(false);
    const [sortField, setSortField] = useState('');
    const [sortDir, setSortDir] = useState('');
    const [numPerPage, setNumPerPage] = useState(10);
    const { token } = useAuth();

    const columns = [
        {
            dataField: 'policyNumber',
            text: 'Policy #',
            headerStyle: {
                textAlign: 'center',
                width: '100px'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'dateOfBirth',
            text: 'DOB',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            formatter: Settings.dateFormatter,
            sort: true,
            filter: dateFilter({ 
                defaultValue: { comparator: Comparator.EQ }, 
                comparatorStyle: { display: "none" },
                onFilter: filterByDateOfBirth
            })
        },
        {
            dataField: 'patientName',
            text: 'Patient Name',
            headerStyle: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'carrier',
            text: 'Client',
            headerStyle: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'incidentNumber',
            text: 'Incident #',
            headerStyle: {
                textAlign: 'center',
                width: '125px'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'enterDate',
            text: 'Enter Date',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            formatter: Settings.dateFormatter,
            sort: true,
            filter: dateFilter({ 
                defaultValue: { comparator: Comparator.EQ }, 
                comparatorStyle: { display: "none" },
                onFilter: filterByEnterDate
            })
        },
        {
            dataField: 'dateOfIncident',
            text: 'DOI',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            formatter: Settings.dateFormatter,
            sort: true,
            filter: dateFilter({ 
                defaultValue: { comparator: Comparator.EQ }, 
                comparatorStyle: { display: "none" },
                onFilter: filterByDateOfIncident
            })
        },
        {
            dataField: 'incidentDescription',
            text: 'Incident Description',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'hasMedicalRecords',
            text: 'Medical Records',
            headerStyle: {
                textAlign: 'center',
                width: '75px'
            },
            style: {
                textAlign: 'center'
            },
            formatter: medRecordsFormatter
        },
        {
            dataField: 'hasCaseSummary',
            text: 'Case Summary',
            hidden: !data.some(e => e.hasCaseSummary),
            headerStyle: {
                textAlign: 'center',
                width: '85px'
            },
            style: {
                textAlign: 'center'
            },
            formatter: caseSumFormatter
        },
        {
            dataField: 'hasCaseDocuments',
            text: 'Case Docs',
            headerStyle: {
                textAlign: 'center',
                width: '55px'
            },
            style: {
                textAlign: 'center'
            },
            formatter: caseDocFormatter
        },
        {
            dataField: 'hasBills',
            text: 'Bills',
            headerStyle: {
                textAlign: 'center',
                width: '50px'
            },
            style: {
                textAlign: 'center'
            },
            formatter: billsFormatter
        },
        {
            dataField: 'hasNotes',
            text: 'Notes',
            headerStyle: {
                textAlign: 'center',
                width: '55px'
            },
            style: {
                textAlign: 'center'
            },
            formatter: notesFormatter
        }
    ];

    function filterByDateOfBirth(filterVal, data) {
        if (!filterVal.date) return data;
        
        var filteredData = new Array();

        for (var i = 0; i < data.length; i++) {
            var current = data[i];
            var dateToCompare = current.dateOfBirth; //.split('T')[0];
            
            if (moment.utc(dateToCompare).isSame(filterVal.date.toISOString(), 'day')) {
                filteredData.push(current);
            }
        }

        return filteredData;
    };

    function filterByEnterDate(filterVal, data) {
        if (!filterVal.date) return data;
        
        var filteredData = new Array();

        for (var i = 0; i < data.length; i++) {
            var current = data[i];
            var dateToCompare = current.enterDate.split('T')[0];
            
            if (moment.utc(dateToCompare).isSame(filterVal.date.toISOString(), 'day')) {
                filteredData.push(current);
            }
        }

        return filteredData;
    };

    function filterByDateOfIncident(filterVal, data) {
        if (!filterVal.date) return data;
        
        var filteredData = new Array();

        for (var i = 0; i < data.length; i++) {
            var current = data[i];
            var dateToCompare = current.dateOfIncident.split('T')[0];
            
            if (moment.utc(dateToCompare).isSame(filterVal.date.toISOString(), 'day')) {
                filteredData.push(current);
            }
        }

        return filteredData;
    };

    function medRecordsFormatter(cell, row) {
        if (cell) {
            return (
                <Link to="/medical-records" onClick={() => props.onPatientDataChange(row)}>View</Link>
            );
        } else {
            return ('');
        }
    }

    function caseSumFormatter(cell, row) {
        if (cell) {
            return (
                <form method="post" action="/api/casesummary" target="new">
                    <button className="viewAsLink">View</button>
                    <input type="hidden" name="IncidentNumber" value={row.incidentNumber}></input>
                    <input type="hidden" name="Token" value={token}></input>
                </form>
            );
        } else {
            return ('');
        }
    }

    function caseDocFormatter(cell, row) {
        if (cell) {
            return (
                <Link to="/case-documents" onClick={() => props.onPatientDataChange(row)}>View</Link>
            );
        } else {
            return ('');
        }
    }

    function billsFormatter(cell, row) {
        if (cell) {
            return (
                <Link to="/billing" onClick={() => props.onPatientDataChange(row)}>View</Link>
            );
        } else {
            return ('');
        }
    }

    function notesFormatter(cell, row) {
        if (cell) {
            return (
                <Link to="/notes" onClick={() => props.onPatientDataChange(row)}>View</Link>
            );
        } else {
            return ('');
        }
    }

    useEffect(() => {
        setData(props.dataDetails);
    }, [props.dataDetails]);

    useEffect(() => {
        if (data.length > 0) {
            var numPerPageStorage = localStorage.getItem(props.numPerPageKey);
            if (numPerPageStorage && numPerPageStorage !== '') {
                setNumPerPage(parseInt(numPerPageStorage));
            }
        }
    }, [data]);

    useEffect(() => {
        setSearchMsg(props.searchMsg);
    }, [props.searchMsg]);

    useEffect(() => {
        setNoResultsMsg(props.noResultsMsg);
    }, [props.noResultsMsg]);

    useEffect(() => {
        setSearchActive(props.searchActive);
    }, [props.searchActive]);

    useEffect(() => {
        setSortField(props.sortField);
    }, [props.sortField]);

    useEffect(() => {
        setSortDir(props.sortDir);
    }, [props.sortDir]);

    useEffect(() => {
        if (data.length > 0 && props.numPerPageKey !== "") {
            localStorage.setItem(props.numPerPageKey, numPerPage);
        }
    }, [numPerPage]);

    const selectRow = {
        mode: 'checkbox',
        hideSelectColumn: true,
    };

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total m-1">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <span className="react-bs-table-sizePerPage-dropdown dropdown">
            <button className="dropdown-toggle btn btn-outline-dark" data-toggle="dropdown" id="dropdownMenuButton" role="group">
                {currSizePerPage + ' per page'}
            </button>
            <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map(option => (
                        <li key={option.text} onClick={() => { onSizePerPageChange(option.page); setNumPerPage(option.page) }}
                            className={'dropdown-item'} role='presentation'>
                            { option.text}
                        </li>
                    ))
                }
            </ul>
        </span>
    );

    const paginationOptions = {
        custom: true,
        showTotal: true,
        totalSize: data.length,
        sizePerPageList: [10, 25, 50],
        sizePerPage: numPerPage,
        nextPageText: 'Next',
        prePageText: 'Previous',
        sizePerPageRenderer,
        paginationTotalRenderer: customTotal
    };

    return (
        <span>{data.length > 0 &&
            <>
                <hr className="mb-4 mt-4" />
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <div className="browser-table">
                                {searchMsg.length > 0 &&
                                    <h5>{searchMsg}</h5>
                                }
                                <div className="row list-view-top">
                                    <div className="col-md-4 list-view-pagination">
                                        <SizePerPageDropdownStandalone {...paginationProps} />
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </div>
                                    <div className="col-md-4">
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>
                                </div>
                                <BootstrapTable
                                    id="results-list"
                                    bootstrap4
                                    striped
                                    wrapperClasses="table-responsive"
                                    keyField="hash"
                                    data={data}
                                    columns={columns}
                                    {...paginationTableProps}
                                    selectRow={selectRow}
                                    sort={{ dataField: sortField, order: sortDir }}
                                    filterPosition="top"
                                    filtersClasses="filter-wrapper"
                                    filter={filterFactory()}
                                />
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        )
                    }
                </PaginationProvider>
            </>
        }
            {data.length == 0 && searchActive &&
                <p className="text-center"><strong>{noResultsMsg}</strong></p>
            }
        </span>
    );
};

export default PatientSearchResultsListView;
