
import React from 'react';
import IconChatMessage from "../../assets/img/IconChatMessage.png";

const CustomerService = () => {
    return (
        <section className="g-bg-primary g-color-white g-py-50 g-pa-30">
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{ textAlign: 'center' }}>

                        <img src={IconChatMessage} style={{ height: '40px', width: '40px' }} alt="IconChatMessage" />
                    </div>
                    <div className="col-md-12" style={{ textAlign: 'center' }}>&nbsp;</div>
                    <div className="col-md-12" style={{ textAlign: 'center' }}>
                        <p style={{ textAlign: 'center', fontSize: '16px' }}><strong><a className="text-white" href="mailto:customerservice@gmmi.com">Contact 24/7/365 Customer Service</a></strong></p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CustomerService;