import React, { useEffect, useState } from "react";
import { AppLayout, Layout } from './Layout';
import { useAuth } from "../providers/authProvider";
import { useHistory } from "react-router-dom";
import AuthService from "../services/authService";


const Home = (props) => {

  const { token, clientId } = useAuth();
  const history = useHistory();


  useEffect(() => {
    if (localStorage) {
      AuthService.getAuthToken(true, (lsToken) => {
        if (lsToken !== "") {
          let path = `/home`;
          history.push(path);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (clientId && token) {
      let path = `/home`;
      history.push(path);
    }
  }, [token, clientId])

  return (
      <AppLayout>
    </AppLayout>
  );
}

export default Home;