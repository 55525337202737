import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, FormLabel, Modal } from 'react-bootstrap';
import MD5 from 'object-hash';
import Dialog from 'react-bootstrap-dialog';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import ProviderSearchService from '../../services/providerSearchService';
import GeocodeService from '../../services/geocodeService';
import Settings from "../../services/settings";
import AnalyticsService from "../../services/analyticsService";

const ProviderSearchForm = (props) => {
    const [searchByType, setSearchByType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showNetworks, setShowNetworks] = useState(false);
    const [networks, setNetworks] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [showSpanishLabels, setShowSpanishLabels] = useState(false);

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCountryName, setSelectedCountryName] = useState('');

    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [hasStates, setHasStates] = useState('');

    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCityForDropdown, setSelectedCityForDropdown] = useState([]);

    const [providerTypes, setProviderTypes] = useState([]);
    const [selectedProviderType, setSelectedProviderType] = useState('');

    const [searchAddress, setSearchAddress] = useState('');

    const [providerName, setProviderName] = useState('');

    const [providerTaxId, setProviderTaxId] = useState('');

    const [distanceUnit, setDistanceUnit] = useState('km');

    const [distance, setDistance] = useState(10);

    const [zipCode, setZipCode] = useState('');

    const [allowSearchByProviderName, setAllowSearchByProviderName] = useState(true);
    const [allowSearchByTaxId, setAllowSearchByTaxId] = useState(false);
    const [allowSearchByZip, setAllowSearchByZip] = useState(true);
    const [allowSearchByRange, setAllowSearchByRange] = useState(true);

    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

    const [cancelToken, setCancelToken] = useState();
    const [currentSearchHash, setCurrentSearchHash] = useState('');
    const [showValidation, setShowValidation] = useState(false);

    let dialogRef = useRef();
    let invalidDialogRef = useRef();
    let networkRef = useRef();
    let countryRef = useRef();
    let stateRef = useRef();
    let cityRef = useRef();
    let providerTypeRef = useRef();
    let rangeRef = useRef();
    const loadingRef = useRef();

    const domesticAllProviderTypesId = "0000000000";
    const rangeValues = [
        { id: 1, label: "1" },
        { id: 2, label: "2" },
        { id: 5, label: "5" },
        { id: 10, label: "10" },
        { id: 15, label: "15" },
        { id: 20, label: "20" },
        { id: 30, label: "30" },
        { id: 40, label: "40" },
        { id: 50, label: "50" }];

    useEffect(() => {
        if (props.providerSearchInfo) {
            var networksArray = new Array();
            for (var key in props.providerSearchInfo.networks) {
                networksArray.push({ code: key, name: props.providerSearchInfo.networks[key] });
            }

            setNetworks(networksArray.map(obj => ({ ...obj, id: obj.code })));

            if (networksArray.length > 1) {
                setShowNetworks(true);
            }
            setSelectedNetwork(networksArray[0].code);
        }
    }, [props.providerSearchInfo]);

    useEffect(() => {
        setCountries([]);
        setStates([]);
        setCities([]);
        setSelectedCountry('');
        setSelectedState('');
        setSelectedCity('');
        setHasStates(false);

        if (countryRef && countryRef.current) {
            countryRef.current.clear();
        }

        if (stateRef && stateRef.current) {
            stateRef.current.clear();
        }
        
        cityRef.current.clear();

        if (isDomestic()) {
            setHasStates(true);
            setSelectedCountry('US');
            setSelectedCountryName('United States');
            props.onSelectedCountryNameChange('United States');
            if (isSecureDomestic() || isLocalDomestic()) {
                setAllowSearchByTaxId(true);
            }
        } else {
            loadCountries();
        }

        loadProviderTypes();

        if (isAetnaLDS()) {
            setShowSpanishLabels(true);
            setAllowSearchByZip(false);
        } 

        props.onSelectedNetworkChange(selectedNetwork);

    }, [selectedNetwork]);

    useEffect(() => {
        setStates([]);
        setCities([]);
        setSelectedState('');
        setSelectedCity('');
        if (hasStates) {
            if (isDomestic()) {
                var domesticStateList = ProviderSearchService.getDomesticStates();
                setStates(domesticStateList);
            } else {
                ProviderSearchService.getStates(selectedNetwork, props.providerSearchInfo.code, selectedCountry, function (data) {
                    if (!data) {
                        data = [];
                    }
                    setStates(data);
                });
            }
        } else {
            if (selectedCountry) {
                loadCities();
            }
        }
    }, [selectedCountry]);

    useEffect(() => {
        cityRef.current.clear();
        setCities([]);
        setSelectedCity('');
        if (selectedCountry && selectedState) {
            loadCities();
        }
    }, [selectedState]);

    useEffect(() => {
        if (isAetnaLDS() && cities.length > 0) {
            setSelectedCity("All Cities");
            setSelectedCityForDropdown(["All Cities"]);
            setAllowSearchByRange(false);
        } else {
            setSelectedCityForDropdown([]); 
        }
    }, [cities]);


    useEffect(() => {
        props.onSearchByTypeChange(searchByType);
    }, [searchByType]);

    const loadCountries = () => {
        if (selectedNetwork !== "") {
            ProviderSearchService.getCountries(selectedNetwork, props.providerSearchInfo.code, function (data) {
                setCountries(data);
            });
        }
    }

    const loadStates = () => {
        ProviderSearchService.getStates(selectedNetwork, props.providerSearchInfo.code, selectedCountry, function (data) {
            if (!data) {
                data = [];
            }
            setStates(data);
        });
    }

    const loadCities = () => {
        ProviderSearchService.getCities(selectedNetwork, props.providerSearchInfo.code, selectedCountry, selectedState, function (data) {
            if (!data) {
                data = [];
                setCities([]);
            } else {

                if (isAetnaLDS()) {
                    let allCities = {
                        "id": "All Cities",
                        "name": "All Cities",
                        "state": null,
                        "latitude": 0.0,
                        "longitude": 0.0
                    };
                    
                    setCities([allCities, ...data]);
                } else {

                    setCities(data);
                }
            }
        });
    }

    const loadProviderTypes = () => {
        if (selectedNetwork !== "") {
            ProviderSearchService.getProviderTypes(selectedNetwork, props.providerSearchInfo.code, selectedState, function (data) {
                if(isDomestic()) {
                    var splitIndex = 0;

                    for (var i = 0; i < data.length; i++) {
                        if (data[i].type === 2) {
                            splitIndex = i;
                            break;
                        }
                    }

                    var specialtiesPlaceholder = { 
                        description: "──────────────Specialties──────────────", 
                        id: "specplaceholder", 
                        type: "2", 
                        disabled: true 
                    };

                    var facilitiesPlaceholder = { 
                        description: "──────────────Facility Types──────────────", 
                        id: "facplaceholder", 
                        type: "1", 
                        disabled: true 
                    };

                    data.splice(splitIndex, 0, specialtiesPlaceholder);
                    data.splice(1, 0, facilitiesPlaceholder);
                }
                setProviderTypes(data);
                setSelectedProviderType(data[0].id);
            });
        }
    }

    const addressDisabled = () => {
        if (isAetnaLDS()) {
            if (!selectedCity || selectedCity === "All Cities") {
                return true;
            } else {
                return false;
            }
        } else {
            return !selectedCity;
        }
    }

    const onCountrySelect = (countries) => {
        if (countries.length < 1) {
            return;
        }

        var country = countries[0];

        if (country.hasStates) {
            setHasStates(true);
        } else {
            setHasStates(false);
        }

        setSelectedCountry(country.id);
        setSelectedCountryName(country.name);

        props.onSelectedCountryNameChange(country.name);
    }

    const onStateSelect = (states, e) => {
        if (states.length < 1) {
            return;
        }

        var selectedState = states[0];
        if (isDomestic()) {
            if (selectedState.showDisclaimer) {
                setShowDisclaimerModal(true);
                setProviderTypes([]);
                setSelectedState('');
                stateRef.current.clear();
            } else {
                setSelectedState(selectedState.id);
            }
        } else {
            setSelectedState(selectedState.id);
        }
    }

    const onCitySelect = (cities) => {
        if (cities.length < 1) {
            return;
        }

        if (isAetnaLDS() && cities[0].name === "All Cities") {
            setAllowSearchByRange(false);
        } else {
            setAllowSearchByRange(true);
        }

        if (isDomestic()) {
            setSelectedCity(cities[0].name);
            setSelectedCityForDropdown([cities[0].name]);
        } else {
            setSelectedCity(cities[0].id);
            setSelectedCityForDropdown([cities[0].id]);
        }
    }

    const onProviderTypeSelect = (types) => {
        if (types.length < 1) {
            return;
        }
        setSelectedProviderType(types[0].id)
    }

    const onNetworkSelect = (networks) => {
        if (networks.length < 1) {
            return;
        }
        setSelectedNetwork(networks[0].id)
    }

    const onRangeSelect = (ranges) => {
        if (ranges.length < 1) {
            return;
        }
        setDistance(ranges[0].id)
    }

    const loadProviders = (data) => {
        if (cancelToken) {
            cancelToken.cancel("Provider search reset");
        }

        setIsLoading(true);
        setCurrentSearchHash(MD5(data));
        var instanceToken = axios.CancelToken.source();
        setCancelToken(instanceToken);

        ProviderSearchService.getProviders(selectedNetwork, props.providerSearchInfo.code, data, instanceToken, function (data) {
            if (distanceUnit === 'mi') {
                data.forEach((provider) => {
                    provider.distance = provider.distance / 1.60934;
                });
            }

            if (isDomestic() && data.length >= 251) {
                data.pop();
                props.onAdditionalResultsChange(true);
            } else {
                props.onAdditionalResultsChange(false);
            }

            setCurrentSearchHash('');
            props.onProviderDetailsChange(data);
            props.onShowNoResults(data.length <= 0);
            setIsLoading(false);
            AnalyticsService.sendEventWithClient('cc_provider_search');
        });
    }

    const isDomestic = () => {
        return selectedNetwork.toLowerCase().indexOf('domestic') !== -1;
    }

    const isSecureDomestic = () => {
        return selectedNetwork.toLowerCase().indexOf('securedomestic') !== -1;
    }

    const isLocalDomestic = () => {
        return selectedNetwork.toLowerCase().indexOf('domesticlocal') !== -1;
    }

    const isSagicor = () => {
        return selectedNetwork.toLowerCase().indexOf('sagicor') !== -1;
    }

    const isSagicorCore = () => {
        return selectedNetwork.toLowerCase().indexOf('sagicorcore') !== -1;
    }

    const isInternational = () => {
        return selectedNetwork.toLowerCase().indexOf('International') !== -1;
    }

    const isAetnaLDS = () => {
        return selectedNetwork.toLowerCase().indexOf('aetnalds') !== -1;
    }

    const isFormValid = () => {
        if (!selectedNetwork || !selectedProviderType) {
            return false;
        }

        if (isDomestic()) {
            if (zipCode) {
                return true;
            }

            if (selectedState && selectedProviderType !== domesticAllProviderTypesId) {
                return true;
            }

            if (selectedState && selectedCity) {
                return true;
            }

            if ((providerTaxId || providerName) && selectedState) {
                return true;
            }

            if ((providerTaxId || providerName) && (zipCode || selectedCity)) {
                return true;
            }

            return false;
        } else {
            if (!selectedCountry) {
                return false;
            }

            if (providerName || selectedCity || zipCode) {
                return true;
            }
            
            return false;
        }
    }

    const requireGeocode = () => {
        return (zipCode || (selectedCity && !isSagicorCore()));
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (!isFormValid()) {
            showInvalidSearchModal();
            return;
        }

        setIsLoading(true);
        setTimeout(() => {
            window.scrollTo(0, loadingRef.current.offsetTop - 100);
        }, 100);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        props.onProviderDetailsChange([]);
        props.onShowNoResults(false);
        props.onAdditionalResultsChange(false);

        var searchDistance = distance;
        if (distanceUnit === 'mi') {
            searchDistance = searchDistance * 1.60934;
        }

        props.onSearchByTypeChange(searchByType);
        props.onDistanceUnitChange(distanceUnit);

        var postData;
        setShowValidation(false);
        
        var cityToSearch = selectedCity;

        if (isAetnaLDS()) {
            if (selectedCity === "All Cities") {
                cityToSearch = null;
                searchDistance = 0;
            }
        }

        if (requireGeocode()) {
            var address = searchAddress;
            setSearchByType("geocode");

            if (selectedCity) {
                address = address + ', ' + selectedCity;
            }
            if (hasStates && selectedState) {
                address = address + ', ' + selectedState;
            }
            if (zipCode) {
                address = address + ' ' + zipCode;
            }
            if (selectedCountryName) {
                address = address + ' ' + selectedCountryName;
            }


            

            GeocodeService.getGeocode(address, function (data) {
                postData = {
                    providerSearchCode: props.providerSearchInfo.code,
                    taxId: providerTaxId,
                    name: providerName,
                    latitude: data.lat,
                    longitude: data.lng,
                    address: searchAddress,
                    city: cityToSearch,
                    state: selectedState,
                    zipCode: zipCode,
                    country: selectedCountry,
                    providerType: selectedProviderType,
                    rangeInKM: searchDistance
                };

                var hashVal = MD5(postData);

                if (hashVal === currentSearchHash) {
                    return;
                } else if (currentSearchHash !== '' && currentSearchHash !== hashVal) {
                    showNewSearchModal(postData);
                    return;
                }
                loadProviders(postData);
            });
        } else {
            setSearchByType("name");

            postData = {
                providerSearchCode: props.providerSearchInfo.code,
                taxId: providerTaxId,
                name: providerName,
                address: searchAddress,
                city: cityToSearch,
                state: selectedState,
                zipCode: zipCode,
                country: selectedCountry,
                providerType: selectedProviderType,
                rangeInKM: searchDistance
            };

            var hashVal = MD5(postData);

            if (hashVal === currentSearchHash) {
                return;
            } else if (currentSearchHash !== '' && currentSearchHash !== hashVal) {
                showNewSearchModal(postData);
                return;
            }

            loadProviders(postData);
        }
    }

    const resetForm = (e) => {
        if (cancelToken) {
            cancelToken.cancel("Provider search reset");
        }

        countryRef.current && countryRef.current.clear();
        cityRef.current && cityRef.current.clear();
        stateRef.current && stateRef.current.clear();
        providerTypeRef.current && providerTypeRef.current.clear();
        networkRef.current && networkRef.current.clear();

        setShowValidation(false);
        setCurrentSearchHash('');
        setIsLoading(false);
        setSelectedCity('');
        setCities([]);
        setSelectedState('');
        setSearchByType('');
        setZipCode('');
        setProviderTaxId('');
        setProviderName('');

        if (!isDomestic()) {
            setStates([]);
            setSelectedCountry('');
            setHasStates(false);
        }
        if (showNetworks) {
            setSelectedNetwork(networks[0].code);
        }
        props.onProviderDetailsChange([]);
        props.onShowNoResults(false);
        props.onAdditionalResultsChange(false);
    }

    const hideDisclaimerModal = () => {
        setShowDisclaimerModal(false);
    }

    const showNewSearchModal = (data) => {
        const customBody = (
            <p>
                Your previous search is still running. Would you like to proceed with these new search parameters instead?
            </p>
        )

        dialogRef.show({
            body: customBody,
            bsSize: 'medium',
            actions: [
                Dialog.Action(
                    'No',
                    () => { },
                    'btn btn-outline-dark'
                ),
                Dialog.Action(
                    'Yes',
                    () => loadProviders(data),
                    'btn btn-outline-dark'
                )
            ],
        });
    }

    const showInvalidSearchModal = (data) => {
        const customBody = (
            <p>
                {(isSecureDomestic() || isLocalDomestic()) ? Settings.getInvalidSearchMessage() : Settings.getInvalidSearchMessagePublic()}
            </p>
        )

        invalidDialogRef.show({
            body: customBody,
            bsSize: 'medium',
            actions: [
                Dialog.Action(
                    'OK',
                    () => { },
                    'btn btn-outline-dark'
                )
            ],
        });
    }

    const ToggleButton = ({ isOpen, onClick }) => (
        <button
            className="toggle-button"
            onClick={onClick}
            onMouseDown={e => {
                e.preventDefault();
            }}>
            {isOpen &&
                <i className="fa fa-chevron-up"></i>
            }
            {!isOpen &&
                <i className="fa fa-chevron-down"></i>
            }
        </button>
    );

    const filterByState = (option, state) => {
        if (state.selected.length) {
            return true;
        }
        return option.name.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
    }

    const filterByProviderType = (option, type) => {
        if (type.selected.length) {
            return true;
        }
        return option.description.toLowerCase().indexOf(type.text.toLowerCase()) > -1;
    }

    const filterByRange = (option, distance) => {
        return true;
    }

    return (
        <>
            <Dialog ref={(component) => { if (component !== null) dialogRef = component }} />
            <Dialog ref={(component) => { if (component !== null) invalidDialogRef = component }} />
            <Modal show={showDisclaimerModal} onHide={hideDisclaimerModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Invalid Selection
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>For assistance in this country/US territory, please contact GMMI directly.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideDisclaimerModal} variant="outline-dark">Close</Button>
                </Modal.Footer>
            </Modal>
            <div className="row justify-content-center providerSearchForm">
                <Col md="12" className="text-center mb-3">
                    <p>{(isSecureDomestic() || isLocalDomestic()) ? Settings.getInstructionMessage() : Settings.getInstructionMessagePublic()}</p>
                </Col>
                <div className="col-md-6">
                    <Form onSubmit={onFormSubmit}>
                        {showNetworks &&
                            <Form.Group as={Row} controlId="network">
                                <Form.Label column md="4">
                                    Network
                            </Form.Label>
                                <Col md="6">
                                    <Typeahead
                                        id="network"
                                        flip={true}
                                        defaultSelected={[networks[0]]}
                                        onChange={onNetworkSelect}
                                        options={networks}
                                        labelKey="name"
                                        placeholder="--select a network--"
                                        filterBy={filterByState}
                                        isInvalid={showValidation ? !selectedNetwork : false}
                                        ref={networkRef}
                                    >
                                        {({ isMenuShown, toggleMenu }) => (
                                            <ToggleButton isOpen={isMenuShown} onClick={e => { e.preventDefault(); toggleMenu(); }} />
                                        )}
                                    </Typeahead>
                                </Col>
                            </Form.Group>
                        }
                        {allowSearchByZip &&
                            <Form.Group as={Row} controlId="zip">
                                <Form.Label column md="4">
                                    {isDomestic() || isSagicorCore() ? "Zip Code" : "Postal Code"}
                                </Form.Label>
                                <Col md="6">
                                    <Form.Control as="input" onChange={e => setZipCode(e.target.value)}></Form.Control>
                                </Col>
                            </Form.Group>
                        }
                        {!isDomestic() &&
                            <Form.Group as={Row} controlId="country">
                                <Form.Label column md="4">
                                    {showSpanishLabels ? "Country/Pais" : "Country"}
                                </Form.Label>
                                <Col md="6">
                                    <Typeahead
                                        id="country"
                                        flip={true}
                                        onChange={onCountrySelect}
                                        options={countries}
                                        labelKey="name"
                                        placeholder="--select a country--"
                                        filterBy={filterByState}
                                        isInvalid={showValidation ? !selectedCountry : false}
                                        ref={countryRef}
                                        maxResults={1000}
                                    >
                                        {({ isMenuShown, toggleMenu }) => (
                                            <ToggleButton isOpen={isMenuShown} onClick={e => { e.preventDefault(); toggleMenu(); }} />
                                        )}
                                    </Typeahead>
                                </Col>
                            </Form.Group>
                        }
                        {hasStates &&
                            <Form.Group as={Row} controlId="state">
                                <Form.Label column md="4">
                                    State
                            </Form.Label>
                                <Col md="6">
                                    <Typeahead
                                    id="state"
                                    flip={true}
                                    onChange={onStateSelect}
                                    options={states}
                                    labelKey="name"
                                    placeholder="--select a state--"
                                    filterBy={filterByState}
                                    isInvalid={showValidation ? !selectedState : false}
                                    ref={stateRef}
                                    >
                                        {({ isMenuShown, toggleMenu }) => (
                                            <ToggleButton isOpen={isMenuShown} onClick={e => { e.preventDefault(); toggleMenu(); }} />
                                        )}
                                    </Typeahead>
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} controlId="city">
                            <Form.Label column md="4">
                                {showSpanishLabels ? "City/Ciudad" : "City"}
                            </Form.Label>
                            <Col md="6">
                                <Typeahead
                                    id="city"
                                    flip={true}
                                    onChange={onCitySelect}
                                    options={cities}
                                    labelKey="name"
                                    placeholder="--select a city--"
                                    filterBy={filterByState}
                                    isInvalid={showValidation ? !selectedCity : false}
                                    ref={cityRef}
                                    maxResults={1000}
                                >
                                    {({ isMenuShown, toggleMenu }) => (
                                        <ToggleButton isOpen={isMenuShown} onClick={e => { e.preventDefault(); toggleMenu(); }} />
                                    )}
                                </Typeahead>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="address">
                            <Form.Label column md="4">
                                {showSpanishLabels ? "Address/Dirección" : "Address"}
                            </Form.Label>
                            <Col md="6">
                                <Form.Control disabled={!selectedCity} as="input" onChange={e => setSearchAddress(e.target.value)}></Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="provider">
                            <Form.Label column md="4">
                                {showSpanishLabels ? "Provider Name/Proveedor" : "Provider Name"}
                            </Form.Label>
                            <Col md="6">
                                <Form.Control as="input" value={providerName} onChange={e => setProviderName(e.target.value)}></Form.Control>
                            </Col>
                        </Form.Group>
                        {(isSecureDomestic() || isLocalDomestic()) &&
                            <Form.Group as={Row} controlId="taxId">
                                <Form.Label column md="4">
                                    Provider Tax Id
                                </Form.Label>
                                <Col md="6">
                                    <Form.Control as="input" value={providerTaxId} onChange={e => setProviderTaxId(e.target.value)}></Form.Control>
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} controlId="providerType">
                            <Form.Label column md="4">
                                {showSpanishLabels ? "Provider Type/Tipo de Proveedor" : "Provider Type"}
                            </Form.Label>
                            <Col md="6">
                                {providerTypes.length > 0 &&

                                    <Typeahead
                                        id="providerType"
                                        flip={true}
                                        defaultSelected={[providerTypes[0]]}
                                        onChange={onProviderTypeSelect}
                                        options={providerTypes}
                                        labelKey="description"
                                        filterBy={filterByProviderType}
                                        isInvalid={showValidation ? !selectedProviderType : false}
                                        ref={providerTypeRef}
                                        maxResults={1000}
                                    >
                                        {({ isMenuShown, toggleMenu }) => (
                                            <ToggleButton isOpen={isMenuShown} onClick={e => { e.preventDefault(); toggleMenu(); }} />
                                        )}
                                    </Typeahead>
                                }
                            </Col>
                        </Form.Group>
                        {allowSearchByRange &&
                            <Form.Group as={Row} controlId="range">
                                <Form.Label column md="4">
                                    {showSpanishLabels ? "Range/Distancia" : "Range"}
                                    <Form.Check inline type="radio" name="distanceUnit" id="distanceUnitKM" label="km" value="km" checked={distanceUnit === 'km'} onChange={e => setDistanceUnit(e.target.value)} />
                                    <Form.Check inline type="radio" name="distanceUnit" id="distanceUnitMile" label="mi" value="mi" checked={distanceUnit === 'mi'} onChange={e => setDistanceUnit(e.target.value)} />
                                </Form.Label>
                                <Col md="6">
                                    <Typeahead
                                        id="range"
                                        flip={true}
                                        defaultSelected={[{ id: 10, label: "10" }]}
                                        onChange={onRangeSelect}
                                        options={rangeValues}
                                        filterBy={filterByRange}
                                        isInvalid={showValidation ? distance < 1 : false}
                                        ref={rangeRef}
                                    >
                                        {({ isMenuShown, toggleMenu }) => (
                                            <ToggleButton isOpen={isMenuShown} onClick={e => { e.preventDefault(); toggleMenu(); }} />
                                        )}
                                    </Typeahead>
                                </Col>
                            </Form.Group>
                        }
                        <Button variant="primary" type="submit" className="btn btn-md u-btn-primary">Search</Button>
                        <Button variant="primary" type="reset" className="gmmi-red" onClick={resetForm}>Reset</Button>
                    </Form>
                </div>
                <span className="invisible" ref={loadingRef}></span>
                <Col md="12" className={isLoading ? "justify-content-center providersLoading" : "d-none"}>
                    <div className="spinner-border"></div>
                </Col>
            </div>
        </>
    );
};

export default ProviderSearchForm;