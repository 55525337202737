

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import React, { useState, useEffect, useRef } from "react";
import { PatchCheckFll } from 'react-bootstrap-icons';

import ProviderSearchForm from './ProviderSearchForm.js'
import ProviderSearchResultsListView from './ProviderSearchResultsListView.js'
import ProviderSearchResultsMapView from './ProviderSearchResultsMapView.js'
import ProviderSearchFeedback from './ProviderSearchFeedback.js';
import ProviderSearchService from '../../services/providerSearchService.js';
import CreateVOBForm from './CreateVOBForm.js';
import Settings from '../../services/settings.js';
import EmailProvidersForm from './EmailProvidersForm.js'

const ProviderSearch = () => {
    
    const [providerDetails, setProviderDetails] = useState([]);
    const [distanceUnit, setDistanceUnit] = useState('mi');
    const [searchByType, setSearchByType] = useState('City');
    const [isListView, setIsListView] = useState(true);
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedbackProvider, setFeedbackProvider] = useState();
    const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);
    const [showPreferredProviders, setShowPreferredProviders] = useState(false);
    const [showCreateVOB, setShowCreateVOB] = useState(false);
    const [vobProvider, setVobProvider] = useState();
    const [providerSearchInfo, setProviderSearchInfo] = useState();
    const [infoLoaded, setInfoLoaded] = useState(false);
    const [showSpanishLabels, setShowSpanishLabels] = useState(false);
    const [additionalResults, setAdditionalResults] = useState(false);
    const [showEmailProvidersForm, setShowEmailProvidersForm] = useState(false);
    const [emailProviders, setEmailProviders] = useState([]);

    const scrollRef = useRef();
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const url = window.location.href.toLowerCase();
        const pathname = window.location.pathname.toLowerCase();
        const splitUrl = url.split('/');
        const providerUrl = splitUrl.pop();

        ProviderSearchService.getProviderSearchInfo(providerUrl, pathname, function(data) {
            setProviderSearchInfo(data);
            setInfoLoaded(true);
        });

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (providerDetails && providerDetails.length > 0) {
                window.scrollTo(0, scrollRef.current.offsetTop - 100);
            }
        }, 500);
    }, [providerDetails]);

    useEffect(() => {
        setShowPreferredProviders(selectedNetwork.indexOf("sagicorCore") <= -1);
        setShowSpanishLabels(selectedNetwork.indexOf('aetnaLDS') !== -1);
    }, [selectedNetwork]);

    let isMobile = (width <= 768);

    const handleProviderDetailsUpdate = (providers) => {
        setProviderDetails(providers);
    }

    const handleShowNoResults = (show) => {
        setShowNoResultsMessage(show);
    }

    const handleDistanceUnitUpdate = (unit) => {
        setDistanceUnit(unit);
    }

    const handleSearchByTypeUpdate = (type) => {
        setSearchByType(type);
    }

    const handleViewToggle = (isList) => {
        setIsListView(isList);
    }

    const handleSelectedNetworkChange = (network) => {
        setSelectedNetwork(network);
    }

    const handleSelectedCountryNameChange = (countryName) => {
        setSelectedCountryName(countryName);
    }

    const handleProviderFeedbackOpen = (provider) => {
        setFeedbackProvider(provider);
        setShowFeedbackModal(true);
    }

    const handleProviderFeedbackClose = () => {
        setFeedbackProvider();
        setShowFeedbackModal(false);
    }

    const handleCreateVOBOpen = (provider) => {
        setVobProvider(provider);
        setShowCreateVOB(true);
    }

    const handleCreateVOBClose = () => {
        setVobProvider();
        setShowCreateVOB(false);
    }

    const handleAdditionalResultsChange = (additional) => {
        setAdditionalResults(additional);
    }

    const handleEmailProvidersOpen = (providers) => {
        setEmailProviders(providers);
        setShowEmailProvidersForm(true);
    }

    const handleEmailProvidersClose = () => {
        setEmailProviders([]);
        setShowEmailProvidersForm(false);
    }

    return (
        <>
            <ReactNotification />
            {showFeedbackModal && 
                <ProviderSearchFeedback 
                    provider={feedbackProvider} 
                    show={showFeedbackModal}
                    selectedNetwork={selectedNetwork}
                    providerSearchInfo={providerSearchInfo}
                    onProviderFeedbackClose={handleProviderFeedbackClose}
                />
            }
            {showCreateVOB && 
                <CreateVOBForm
                    onCreateVOBClose={handleCreateVOBClose}
                    provider={vobProvider}
                    show={showCreateVOB}
                    selectedNetwork={selectedNetwork}
                    providerSearchInfo={providerSearchInfo}
                />
            }
            {showEmailProvidersForm &&
                <EmailProvidersForm
                    providers={emailProviders}
                    show={showEmailProvidersForm}
                    selectedNetwork={selectedNetwork}
                    onEmailProvidersClose={handleEmailProvidersClose}
                    providerSearchInfo={providerSearchInfo}
                />
            }
            <div className="g-mx-25">
                {infoLoaded &&
                    <ProviderSearchForm
                        onProviderDetailsChange={handleProviderDetailsUpdate}
                        onDistanceUnitChange={handleDistanceUnitUpdate}
                        onSearchByTypeChange={handleSearchByTypeUpdate}
                        onSelectedNetworkChange={handleSelectedNetworkChange}
                        onSelectedCountryNameChange={handleSelectedCountryNameChange}
                        onShowNoResults={handleShowNoResults}
                        onAdditionalResultsChange={handleAdditionalResultsChange}
                        providerSearchInfo={providerSearchInfo}
                    />
                }
                
                {!infoLoaded &&
                    <div className="text-center providersLoading">
                        <div className="spinner-border"></div>
                    </div>
                }
                <span className="invisible" ref={scrollRef}></span>
                <p className={showNoResultsMessage ? "text-center" : "d-none"}>{Settings.getNoResultsMessage()}</p>

                {providerDetails.length > 0 &&
                    <>
                        <hr className="mb-4 mt-4" />
                        <div className="viewToggle">
                            {!isMobile && 
                                <>
                                    <button className={isListView ? "btn btn-outline-dark selected" : "btn btn-outline-dark"} onClick={() => handleViewToggle(true)}><i className="fa fa-list"></i> List View</button>
                                    <button className={isListView ? "btn btn-outline-dark" : "btn btn-outline-dark selected"} onClick={() => handleViewToggle(false)}><i className="fa fa-map"></i> Map View</button>
                                </>
                            }
                            {showPreferredProviders && 
                                <span><PatchCheckFll className="g-color-gmmi-blue preferredProvider" size={19} /> {showSpanishLabels ? "Preferred Provider/Proveedores Preferidos" : "Preferred Provider"}</span>
                            }
                            {additionalResults &&
                                <p className="text-center"><i className="fa fa-exclamation-triangle"></i> Your search returned more than 250 results. Please consider refining your search.</p>
                            }
                        </div>
                    </>
                }

                {(!isMobile && isListView) &&
                    <ProviderSearchResultsListView
                        providerDetails={providerDetails}
                        distanceUnit={distanceUnit}
                        searchByType={searchByType}
                        selectedNetwork={selectedNetwork}
                        selectedCountryName={selectedCountryName}
                        showPreferredProviders={showPreferredProviders}
                        onProviderFeedbackOpen={handleProviderFeedbackOpen}
                        onCreateVOBOpen={handleCreateVOBOpen}
                        onEmailProvidersOpen={handleEmailProvidersOpen}
                        providerSearchInfo={providerSearchInfo}
                    />
                }
                {(isMobile || !isListView) &&
                    <ProviderSearchResultsMapView
                        providerDetails={providerDetails}
                        distanceUnit={distanceUnit}
                        searchByType={searchByType}
                        selectedNetwork={selectedNetwork}
                        selectedCountryName={selectedCountryName}
                        showPreferredProviders={showPreferredProviders}
                        onProviderFeedbackOpen={handleProviderFeedbackOpen}
                        onCreateVOBOpen={handleCreateVOBOpen}
                        onEmailProvidersOpen={handleEmailProvidersOpen}
                        providerSearchInfo={providerSearchInfo}
                    />
                }
            </div>
        </>
    );
};

export default ProviderSearch;