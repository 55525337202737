import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Form, Row, Col, Button, FormLabel } from 'react-bootstrap';
import Picklist from '../PatientSearch/Picklist'
import ReportsService from '../../services/reportsService';
import ErrorDisplay from '../../services/errorDisplay';
import AnalyticsService from '../../services/analyticsService';
import { Layout } from "../Layout";
import { useAuth } from "../../providers/authProvider";

const Reports = () => {
    const [allClients, setAllClients] = useState(true);
    const [clientList, setClientList] = useState();
    const [selectedClientList, setSelectedClientList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);
    const [availableReports, setAvailableReports] = useState([]);
    const [showDateError, setShowDateError] = useState(false);
    const [showClientError, setShowClientError] = useState(false);
    const [showClientDropdown, setShowClientDropdown] = useState(false);
    const [showClientPicker, setShowClientPicker] = useState(false);
    const [clientsForDropdown, setClientsForDropdown] = useState([]);
    const [dropdownClient, setDropdownClient] = useState(null);
    const { token } = useAuth();

    useEffect(() => {
        ReportsService.getAvailableReports((data) => {
            setAvailableReports(data);
            ReportsService.getAvailableClients((data) => {
                setClientList(data);
                setSelectedClientList(Object.keys(data));

                var keys = Object.keys(data);
                var dropdownClients = [];

                for (var i = 0; i < keys.length; i++) {
                    var item = {};
                    item.key = keys[i];
                    item.value = data[item.key];
                    dropdownClients.push(item);
                }

                dropdownClients.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));

                if (keys.length > 1) {
                    var item = {};
                    item.key = "";
                    item.value = "-- select a client --";
                    dropdownClients.unshift(item);
                }

                setClientsForDropdown(dropdownClients);

            });
        });

        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        if (isIE11) {
            $("input[type='date']").datepicker();
        }
    }, []);


    useEffect(() => {
        if (allClients && clientList) {
            setSelectedClientList(Object.keys(clientList));
        }

    }, [allClients]);

    const onFormSubmit = (e) => {
        e.preventDefault();

        $(".notification-item").click();

        var errors = [];

        if (selectedClientList.length === 0 && !allClients) {
            errors.push("Clients must be selected");
        }

        if (selectedClientList.length === 1 && !allClients) {
            if (selectedClientList[0] === "") {
                errors.push("A Client must be selected");
            }
        }

        if (!selectedReport || selectedReport === "") {
            errors.push("Report type must be selected");
        }

        if (isStartDateGreater()) {
            errors.push("Start Date cannot be greater than End Date");
        }

        if (errors.length === 0) {
            AnalyticsService.sendEventWithClient("cc_download_report");
            $("#renderForm").submit();
        } else {
            var errorMessage = "";

            var errorMessages = errors.map((e, i) =>
                <li key={i}>{e}</li>
            );

            errorMessage = (
                <ul>{errorMessages}</ul>
            );

            ErrorDisplay.renderError(errorMessage);
        }
    }

    const resetForm = (e) => {
        setAllClients(true);
        selectedReport(null);
        setStartDate(null);
        setEndDate(null);
    }

    const setClientFromDropdown = (client) => {
        if (client === "") {
            setSelectedClientList([]);
        } else {
            setDropdownClient(client);
            setSelectedClientList(client);
        }
    }

    const onReportItemChange = (e) => {
        if (clientsForDropdown.length > 1) {
            for (var i = 0; i < availableReports.length; i++) {
                if (e.target.value === availableReports[i].code) {
                    if (availableReports[i].multiClient === true) {
                        setAllClients(true);
                        setShowClientPicker(true);
                        setShowClientDropdown(false);
                    } else {
                        setSelectedClientList([]);
                        setDropdownClient("")
                        setAllClients(false);
                        setShowClientPicker(false);
                        setShowClientDropdown(true);
                    }
                }
            }
        } else {
            setShowClientPicker(false);
            setShowClientDropdown(true);
        }

        setSelectedReport(e.target.value);
    }

    const onSelectedClientsChange = (clients) => {
        setSelectedClientList(clients);
    }

    const isStartDateGreater = () => {
        if (startDate && startDate !== "" && endDate && endDate !== "") {
            var startDateParsed = new Date(startDate);
            var endDateParsed = new Date(endDate);
            return (startDateParsed > endDateParsed);
        }
    }

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
        e.target.setCustomValidity('');
    }

    const onEndDateChange = (e) => {
        setEndDate(e.target.value);
        e.target.setCustomValidity('');
    }

    return (
        <Layout>
            <ReactNotification />
            <div className="g-ml-25">
                <h1>Reports</h1>
            </div><br /><br /><br />
            <div className="g-mx-25">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Form onSubmit={onFormSubmit}>
                            <Form.Group as={Row} controlId="reportType">
                                <Form.Label column md="4" className="required">Report:</Form.Label>
                                <Col md="6">
                                    <Form.Control as="select" onChange={e => onReportItemChange(e)}>
                                        <option value="">--select a report --</option>
                                        {
                                            availableReports.map((report, index) => {
                                                return (
                                                    <option key={index} value={report.code}>{report.name}</option>
                                                );
                                            })
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            {showClientDropdown &&
                                <Form.Group as={Row} controlId="clientDropdown">
                                    <Form.Label column md="4" className="required">Client:</Form.Label>
                                    <Col md="6">
                                        <Form.Control value={dropdownClient} as="select" onChange={e => setClientFromDropdown(e.target.value)}>
                                            {
                                                clientsForDropdown.map((client, index) => {
                                                    return (
                                                        <option key={index} value={client.key}>{client.value}</option>
                                                    );
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            }
                            {showClientPicker &&
                                <Form.Group as={Row} controlId="allClients">
                                    <Form.Label column md="4" className="required">Clients:</Form.Label>
                                    <Col md="6">
                                        <Form.Check inline label="All Clients" value="1"
                                            checked={allClients} onChange={e => setAllClients(!allClients)} />
                                    </Col>
                                </Form.Group>
                            }
                            {!allClients && showClientPicker &&
                                <Form.Group as={Row} controlId="selectedClients">
                                    <Picklist data={clientList} onChange={onSelectedClientsChange} leftLabel="Available Clients:" rightLabel="Selected Clients:" />
                                    {showClientError &&
                                        <Col md="12">
                                            <span className="float-right mr-2">Please select at least one client.</span>
                                        </Col>
                                    }
                                </Form.Group>
                            }
                            <Form.Group as={Row} controlId="startDate">
                                <Form.Label column md="4" className="required">Start Date:</Form.Label>
                                <Col md="6">
                                    <Form.Control value={startDate} as="input" type="date" required={true} onChange={(e) => onStartDateChange(e)} onInvalid={(e) => e.target.setCustomValidity('Please enter a date to search by')} onInput={(e) => e.target.setCustomValidity('')}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="endDate">
                                <Form.Label column md="4" className="required">End Date:</Form.Label>
                                <Col md="6">
                                    <Form.Control value={endDate} as="input" type="date" required={true} onChange={(e) => onEndDateChange(e)} onInvalid={(e) => e.target.setCustomValidity('Please enter a date to search by')} onInput={(e) => e.target.setCustomValidity('')}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Button variant="primary" type="submit" className="btn btn-md u-btn-primary">Submit</Button>
                            <Button variant="primary" type="reset" className="gmmi-red" onClick={resetForm}>Reset</Button>
                        </Form>
                    </div>
                </div>
                <form id="renderForm" method="post" action="/api/reports/report.pdf" target="new">
                    <input type="hidden" name="Clients" value={selectedClientList}></input>
                    <input type="hidden" name="StartDate" value={startDate}></input>
                    <input type="hidden" name="EndDate" value={endDate}></input>
                    <input type="hidden" name="ReportCode" value={selectedReport}></input>
                    <input type="hidden" name="Token" value={token}></input>
                </form>
            </div><br/><br/>
        </Layout>
    )
};

export default Reports;
