import React, { useEffect, useState, useRef } from "react";
import ClientProfile from "../../services/clientProfile";
import ClientConfigurationService from "../../services/clientConfigurationService";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/authProvider";
import ReactNotification from 'react-notifications-component'
import { Button } from 'react-bootstrap';
import Settings from '../../services/settings';
import { loginRequest } from "../../authConfig";
import { BasicLayout } from "../Layout";

const ClientSelect = () => {
    const { token } = useAuth();
    const history = useHistory();
    const [list, setList] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [displayClientHeader, setDisplayClientHeader] = useState(false);
    const homepageUrl = "/home";
    let stateRef = useRef();

    const loadRecentClients = () => {
        var recentClientsJson = localStorage.getItem(Settings.getRecentClientKey());

        setDisplayClientHeader(false);

        if (recentClientsJson) {
            var recentClients = JSON.parse(recentClientsJson);

            if (recentClients.length > 0) {
                setList(recentClients);
                setDisplayClientHeader(true);
            }
        }
    }

    const addToRecentClientsList = (id, label) => {
        const recentClientsJson = localStorage.getItem(Settings.getRecentClientKey());
        let recentClients = new Array();

        if (recentClientsJson) {
            recentClients = JSON.parse(recentClientsJson);
        }

        var newClientList = new Array();

        newClientList.push({
            id: id,
            label: label
        });

        recentClients.forEach((value) => {
            if (newClientList.length < 5 && value.id !== id) {
                newClientList.push(value);
            }
        });

        console.log(newClientList);

        localStorage.setItem(Settings.getRecentClientKey(), JSON.stringify(newClientList));
    }

    const loadClientListFromAPI = () => {

        ClientProfile.getClientProfiles(token, function (data) {
            setClients(data);
            localStorage.removeItem("clientConfiguration");
        });
    }

    useEffect(() => {
        //Get recent client lists
        loadRecentClients();

    }, []);

    useEffect(() => {
        if (token) {
            loadClientListFromAPI();
        }
    }, [token])

    useEffect(() => {
        if (selectedClient) {
            addToRecentClientsList(selectedClient.id, selectedClient.label);
        }
    }, [selectedClient]);

    const ToggleButton = ({ isOpen, onClick }) => (
        <button
            className="toggle-button"
            onClick={onClick}
            onMouseDown={e => {
                e.preventDefault();
            }}>
            {isOpen &&
                <i className="fa fa-chevron-up"></i>
            }
            {!isOpen &&
                <i className="fa fa-chevron-down"></i>
            }
        </button>
    );

    const onClientSelect = (client, e) => {
        if (client.length < 1) {
            return;
        }

        var selectedClient = client[0];

        setSelectedClient(selectedClient);

    }


    const onClientSelected = () => {
        //After select a client get the client configuration before
        ClientConfigurationService.getClientConfiguration(selectedClient.id, function (data) {
            if (data) {
                let path = `/home`;
                history.push({
                    pathname: path,
                    state: { loadPrevious: true, currentClient: selectedClient }
                  })
            }
        });
    }

    const onPreviousClientSelected = (client) => {
        ClientConfigurationService.getClientConfiguration(client.id, function (data) {
            if (data) {
                let path = `/home`;
                history.push({
                    pathname: path,
                    state: { loadPrevious: true, currentClient: client }
                  })
            }
        });
    }

    const filterByClient = (option, client) => {
        if (client.selected.length) {
            return true;
        }
        return option.label.toLowerCase().indexOf(client.text.toLowerCase()) > -1;
    }

    const authRequest = {
        ...loginRequest
    };
    return (
        <BasicLayout>
            <ReactNotification />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 offset-md-2">
                        <div className="form-group row g-mb-25">
                            <div className="col-md-8">
                                <Typeahead
                                    id="ClientId"
                                    flip={true}
                                    onChange={onClientSelect}
                                    options={clients}
                                    labelKey="label"
                                    placeholder="--Select a client--"
                                    filterBy={filterByClient}
                                    ref={stateRef}
                                >
                                    {({ isMenuShown, toggleMenu }) => (
                                        <ToggleButton isOpen={isMenuShown} onClick={e => { e.preventDefault(); toggleMenu(); }} />
                                    )}
                                </Typeahead>
                            </div>
                            <div className="col-md-3 text-center">
                                <Button id="btnSelectClient" variant="primary" type="submit"  className="btn btn-md u-btn-primary-gmmi rounded-0"
                                    onClick={onClientSelected}
                                >
                                    Go to Selected Client Portal
                                </Button>
                            </div>
                        </div>
                        <div className="form-group row g-mb-25">
                            <div className="col-md-6">
                                {displayClientHeader ? <h3 id="pastClientHeader" >Recently visited clients:</h3> : null}
                                {list && list.length > 0 ?
                                    <ul id="pastClientList">
                                        {list.filter((value) => Object.keys(value).length !== 0).map((item) => (
                                            <li key={item.id}>
                                                <a href={homepageUrl} onClick={(e) => e.preventDefault() & onPreviousClientSelected(item)}>{item.label}</a>
                                            </li>
                                        ))}
                                    </ul> : null}
                            </div>
                            <div className="col-md-6">
                                <h3>Provider searches:</h3>
                                <ul>
                                    <li><a href="/domestic-provider-search" target="_blank">Domestic Public Provider Search</a></li>
                                    <li><a href="/domestic-local-provider-search" target="_blank">Domestic Local Provider Search</a></li>
                                    <li><a href="/international-provider-search" target="_blank">International Provider Search</a></li>
                                    <li><a href="/sagicor-provider-search" target="_blank">Sagicor</a></li>
                                    <li><a href="https://www.aetna.com/dsepublic/#/contentPage?page=providerSearchLanding&amp;site_id=passport" target="_blank">Aetna Provider Search</a></li>
                                    <li><a href="/aetna-international-provider-search" target="_blank">Aetna LDS Provider Search</a></li>
                                    <li><a href="https://providerlocator.firsthealth.com/LocateProvider/SelectNetworkType" target="_blank">First Health Provider Search</a></li>
                                    <li><a href="https://us1.welcometouhc.com/" target="_blank">UHC Provider Search</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BasicLayout>
    );
};

export default ClientSelect;
