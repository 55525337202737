import AuthService from './authService';
import ErrorDisplay from './errorDisplay';
import ClientConfigurationService from './clientConfigurationService'
import axios from 'axios';

const ClientMemberService = {

    login: (data, callback) => {
        axios.post('/api/clientmember/login', data, {
        }).then(res => {
            callback(res.data);
        }).catch((error => {
            ErrorDisplay.renderError('An error occurred on client member page login');
        }));
    },
}

export default ClientMemberService;