import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { dateFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import moment from 'moment';

import PatientHeader from './PatientHeader';
import Settings from '../../services/settings';
import PatientSearchService from '../../services/patientSearchService';

const Notes = (props) => {
    const [patientData, setPatientData] = useState();
    const [notesData, setNotesData] = useState([]);
    const [searchActive, setSearchActive] = useState(false);
    const [sortField, setSortField] = useState('timestamp');
    const [incidentNumber, setIncidentNumber] = useState('');
    const [searchMsg, setSearchMsg] = useState('');
    const [noResultsMsg, setNoResultsMsg] = useState('No results');
    const [sortDir, setSortDir] = useState('asc');
    const [isLoading, setIsLoading] = useState(false);
    const [numPerPage, setNumPerPage] = useState(10);
    const [types, setTypes] = useState([]);
    const [authors, setAuthors] = useState([]);

    const columns = [
        {
            dataField: 'timestamp',
            text: 'Timestamp',
            headerStyle: {
                textAlign: 'center',
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            formatter: Settings.dateTimeFormatter,
            filter: dateFilter({ 
                defaultValue: { comparator: Comparator.EQ }, 
                comparatorStyle: { display: "none" },
                onFilter: filterByDateCustom
            })
        },
        {
            dataField: 'type',
            text: 'Type',
            headerStyle: {
                textAlign: 'center',
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: selectFilter({ options: types })
        },
        {
            dataField: 'author',
            text: 'Author',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: selectFilter({ options: authors })
        },
        {
            dataField: 'noteText',
            text: 'Notes',
            headerStyle: {
                textAlign: 'center',
                width: '50%'
            },
            style: {
                textAlign: 'left'
            },
            headerAttrs: {
                'scope': 'col',
                'tt-style': 'none',
            }
        }
    ];

    function filterByDateCustom(filterVal, data) {
        if (!filterVal.date) return data;
        
        var filteredData = new Array();

        for (var i = 0; i < data.length; i++) {
            var current = data[i];
            var dateToCompare = current.timestamp.split('T')[0];
            
            if (moment.utc(dateToCompare).isSame(filterVal.date.toISOString(), 'day')) {
                filteredData.push(current);
            }
        }

        return filteredData;
    };

    useEffect(() => {
        setPatientData(props.patientData);
        setIncidentNumber(props.patientData.incidentNumber);
        setSearchActive(false);
        setIsLoading(true);
    }, [props.patientData]);

    useEffect(() => {
        if (patientData) {
            setIncidentNumber(patientData.incidentNumber);
            var postData = {
                patientCode: patientData.patientCode,
                incidentNumber: patientData.incidentNumber,
                client: patientData.client
            };

            PatientSearchService.getNotes(postData, function (data) {
                setNotesData(data);
                setIsLoading(false);
                setSearchActive(true);
            });
        }
    }, [patientData]);

    useEffect(() => {
        var typeOptions = new Array();
        var authorOptions = new Array();

        for (var i = 0; i < notesData.length; i++) {
            var currentNote = notesData[i];
            if (!typeOptions.includes(currentNote.type)) {
                typeOptions.push(currentNote.type);
            }
            if (!authorOptions.includes(currentNote.author)) {
                authorOptions.push(currentNote.author);
            }
        }

        typeOptions.sort();
        authorOptions.sort();

        setTypes(typeOptions.map((type, index) => ({ value: type, label: type })));
        setAuthors(authorOptions.map((author, index) => ({ value: author, label: author })));
    }, [notesData]);

    useEffect(() => {
        setSearchMsg(props.searchMsg);
    }, [props.searchMsg]);

    useEffect(() => {
        setNoResultsMsg(props.noResultsMsg);
    }, [props.noResultsMsg]);

    useEffect(() => {
        setSearchActive(props.searchActive);
    }, [props.searchActive]);

    useEffect(() => {
        var numPerPageStorage = localStorage.getItem(props.numPerPageKey);
        if (numPerPageStorage && numPerPageStorage !== '') {
            setNumPerPage(parseInt(numPerPageStorage));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(props.numPerPageKey, numPerPage);
    }, [numPerPage]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total m-1">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <span className="react-bs-table-sizePerPage-dropdown dropdown">
            <button className="dropdown-toggle btn btn-outline-dark" data-toggle="dropdown" id="dropdownMenuButton" role="group">
                {currSizePerPage + ' per page'}
            </button>
            <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map(option => (
                        <li key={option.text} onClick={() => { onSizePerPageChange(option.page); setNumPerPage(option.page) }}
                            className={'dropdown-item'} role='presentation'>
                            { option.text}
                        </li>
                    ))
                }
            </ul>
        </span>
    );

    const paginationOptions = {
        custom: true,
        showTotal: true,
        totalSize: notesData.length,
        sizePerPageList: [10, 25, 50],
        sizePerPage: numPerPage,
        nextPageText: 'Next',
        prePageText: 'Previous',
        sizePerPageRenderer,
        paginationTotalRenderer: customTotal
    };

    return (
        <>
            <div className="g-ml-25">
                <h1>Notes</h1>
            </div>
            <Row>
                <Col md="6">
                    <div className="browser-table">
                        <PatientHeader patientData={patientData} />
                    </div>
                </Col>
            </Row>
            <hr className="mb-4 mt-3" />
            {isLoading &&
                <div className="col-md-12 justify-content-center providersLoading">
                    <div className="spinner-border"></div>
                </div>
            }
            {notesData.length > 0 &&
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <div className="browser-table mt-3">
                                <div className="row list-view-top">
                                    <div className="col-md-4 list-view-pagination">
                                        <SizePerPageDropdownStandalone {...paginationProps} />
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </div>
                                    <div className="col-md-4">
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>
                                </div>
                                <BootstrapTable
                                    id="results-list"
                                    bootstrap4
                                    striped
                                    wrapperClasses="table-responsive"
                                    keyField="hash"
                                    data={notesData}
                                    columns={columns}
                                    {...paginationTableProps}
                                    filterPosition="top"
                                    filtersClasses="filter-wrapper"
                                    filter={filterFactory()}
                                />
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        )
                    }
                </PaginationProvider>
            }
            {notesData.length == 0 && searchActive &&
                <p className="text-center"><strong>No notes found</strong></p>
            }
        </>
    );
};

export default Notes;