import IdCardForm from "./IdCardForm";
import Footer from '../Footer/Footer';
import CustomerService from '../CustomerService/CustomerService';
import { EmptyLayout } from '../Layout';
import React, { useEffect, useState } from "react";
import Settings from '../../services/settings';
import { useHistory } from "react-router-dom";

const ClientMember = () => {
    const [clientMemberData, setClientMemberData] = useState({});
    const history = useHistory();

    useEffect(() => {
        const clientMemberConfig = localStorage.getItem(Settings.getClientMemberKey());
        if (clientMemberConfig) {
            setClientMemberData(JSON.parse(clientMemberConfig));
        }
        else{
            let path = `/client-member-page/login`;
            history.push({
                pathname: path
            })
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem(Settings.getClientMemberKey());
        let path = `/client-member-page/login`;
        history.push({
            pathname: path
        })
    }

    return (
        <EmptyLayout displayLogoWithLink={true}>
            <div className="row justify-content-md-center g-pt-65">
                <div className="col col-md-8">
                    <div>
                        <form method="GET" onSubmit={handleLogout}>
                            <button className="btn btn-secondary">Logout</button>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-10 text-center">
                            <br />
                            <div dangerouslySetInnerHTML={{ __html: clientMemberData?.content }}></div>
                        </div>
                    </div>
                </div>
            </div>
            {clientMemberData?.showIdCard ? <IdCardForm showEnglishAndGerman={clientMemberData?.showEnglishAndGerman}></IdCardForm> : null }
            <CustomerService></CustomerService>
            <Footer></Footer>
        </EmptyLayout>
    )
}

export default ClientMember;