import React, { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import { Row, Col } from 'react-bootstrap';
import filterFactory, { dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import moment from 'moment';

import PatientHeader from './PatientHeader';
import PatientSearchService from '../../services/patientSearchService';
import Settings from "../../services/settings";
import { useAuth } from "../../providers/authProvider";

const MedicalRecords = (props) => {
    const [patientData, setPatientData] = useState([]);
    const [medRecordData, setMedRecordData] = useState([]);
    const [sortField] = useState('dateAdded');
    const [sortDir] = useState('asc');
    const [numPerPage, setNumPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const { token } = useAuth();

    const columns = [
        {
            dataField: 'dateAdded',
            text: 'Date Added',
            headerStyle: {
                textAlign: 'center',
                width: '200px'
            },
            style: {
                textAlign: 'center'
            },
            formatter: Settings.dateTimeFormatter,
            sort: true,
            filter: dateFilter({ 
                defaultValue: { comparator: Comparator.EQ }, 
                comparatorStyle: { display: "none" },
                onFilter: filterByDateCustom
            })
        },
        {
            dataField: 'pageNumbers',
            text: 'Page #',
            headerStyle: {
                textAlign: 'center',
                width: '200px'
            },
            style: {
                textAlign: 'center'
            },
        },
        {
            dataField: 'Document',
            text: 'Document',
            headerStyle: {
                textAlign: 'center',
                width: '75px'
            },
            style: {
                textAlign: 'center'
            },
            formatter: documentFormatter
        }
    ];

    function documentFormatter(cell, row) {
        return (
            <form method="post" action="/api/medicalrecord" target="new">
                <button className="viewAsLink">View</button>
                <input type="hidden" name="IncidentNumber" value={patientData.incidentNumber}></input>
                <input type="hidden" name="PageStartNumber" value={row.pageStartNumber}></input>
                <input type="hidden" name="PageEndNumber" value={row.pageEndNumber}></input>
                <input type="hidden" name="Token" value={token}></input>
            </form>
        );
    }

    function filterByDateCustom(filterVal, data) {
        if (!filterVal.date) return data;
        
        var filteredData = new Array();

        for (var i = 0; i < data.length; i++) {
            var current = data[i];
            var dateToCompare = current.dateAdded.split('T')[0];
            
            if (moment.utc(dateToCompare).isSame(filterVal.date.toISOString(), 'day')) {
                filteredData.push(current);
            }
        }

        return filteredData;
    };

    useEffect(() => {
        setPatientData(props.patientData);
        setMedRecordData([]);
        setSearchActive(false);
        setIsLoading(true);
    }, [props.patientData]);

    useEffect(() => {
        var postData = {
            patientCode: patientData.patientCode,
            incidentNumber: patientData.incidentNumber,
            client: patientData.client
        };

        if (patientData.patientCode && patientData.incidentNumber && patientData.client) {
            PatientSearchService.getMedicalRecords(postData, function (data) {
                setMedRecordData(data);
                setIsLoading(false);
                setSearchActive(true);
            });
        }
    }, [patientData]);

    useEffect(() => {
        var numPerPageStorage = localStorage.getItem(props.numPerPageKey);
        if (numPerPageStorage && numPerPageStorage !== '') {
            setNumPerPage(parseInt(numPerPageStorage));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(props.numPerPageKey, numPerPage);
    }, [numPerPage]);

    const selectRow = {
        mode: 'checkbox',
        hideSelectColumn: true,
    };

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total m-1">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <span className="react-bs-table-sizePerPage-dropdown dropdown">
            <button className="dropdown-toggle btn btn-outline-dark" data-toggle="dropdown" id="dropdownMenuButton" role="group">
                {currSizePerPage + ' per page'}
            </button>
            <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map(option => (
                        <li key={option.text} onClick={() => { onSizePerPageChange(option.page); setNumPerPage(option.page) }}
                            className={'dropdown-item'} role='presentation'>
                            { option.text}
                        </li>
                    ))
                }
            </ul>
        </span>
    );

    const paginationOptions = {
        custom: true,
        showTotal: true,
        totalSize: medRecordData.length,
        sizePerPageList: [10, 25, 50],
        sizePerPage: numPerPage,
        nextPageText: 'Next',
        prePageText: 'Previous',
        sizePerPageRenderer,
        paginationTotalRenderer: customTotal
    };

    return (
        <>
            <div className="g-ml-25">
                <h1>Medical Records</h1>
            </div>
            <Row>
                <Col md="6">
                    <div className="browser-table">
                        <PatientHeader
                            patientData={patientData}
                        />
                    </div>
                </Col>
            </Row>
            <hr className="mb-4 mt-3" />
            {isLoading &&
                <div className="col-md-12 justify-content-center providersLoading">
                    <div className="spinner-border"></div>
                </div>
            }
            {medRecordData.length > 0 &&
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <div className="browser-table">
                                <div className="row list-view-top">
                                    <div className="col-md-4 list-view-pagination">
                                        <SizePerPageDropdownStandalone {...paginationProps} />
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </div>
                                    <div className="col-md-4">
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>
                                </div>
                                <BootstrapTable
                                    id="results-list"
                                    bootstrap4
                                    striped
                                    wrapperClasses="table-responsive"
                                    keyField="id"
                                    data={medRecordData}
                                    columns={columns}
                                    {...paginationTableProps}
                                    selectRow={selectRow}
                                    filterPosition="top"
                                    filtersClasses="filter-wrapper"
                                    filter={filterFactory()}
                                />
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        )
                    }
                </PaginationProvider>
            }
            {medRecordData.length == 0 && searchActive &&
                <p className="text-center"><strong>No records found</strong></p>
            }
        </>
    );
};

export default MedicalRecords;