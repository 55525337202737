import moment from "moment";

const Settings = {

    getGoogleApiKey: () => {
        return "AIzaSyBz64ulxbN1lIOablNyH_ayP7O5EGq164U";
    },

    getReCaptchaSiteKey: () => {
        return "6Ld55M0UAAAAACO2r0rc7lXBX7v8SAkx22ewJuMp";
    },

    enableFeedback: () => {
        return "True";
    },

    getItemId: () => {
        return "8848a613-804d-4dd0-a736-4171642af2cd";
    }, 

    getNumPerPageKey: () => {
        return "numPerPage";
    },

    getCurrentClientKey: () => {
        return "CurrentClient";
    },
    getIsGMMIKey: () => {
        return "isGMMI";
    },

    getClientConfigurationKey: () => {
        return "clientConfiguration";
    },

    getRecentClientKey: () => {
        return "RecentClients";
    },
    getClientMemberKey: () => {
        return "clientMemberConfiguration";
    },

    dateFormatter: (cell) => {
        return moment(cell).format("M/D/YYYY");
    },

    dateTimeFormatter: (cell) => {
        return moment(cell).format("M/D/YYYY h:mm a");
    },

    getInstructionMessage: () => {
        return "You must enter a location and for the search to be valid.";
    },

    getInstructionMessagePublic: () => {
        return "You must enter a location and for the search to be valid.";
    },

    getInvalidSearchMessage: () => {
        return "Please modify your search criteria and try again. You must enter a location for the search to be valid."
    },

    getInvalidSearchMessagePublic: () => {
        return "Please modify your search criteria and try again. You must enter a location for the search to be valid."
    },

    getNoResultsMessage: () => {
        return "No results were found.  Please select new search criteria and try again."
    }
}

export default Settings;