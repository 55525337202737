import axios from "axios";
import AuthService from "./authService";
import ErrorDisplay from "./errorDisplay";
import { useAuth } from "../providers/authProvider";

const ClientProfile = {
    
    getClientProfiles: (token, callback) => {
            axios.get('/api/autocomplete/', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving client profiles');
            }));
    },
}

export default ClientProfile;
