import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, Row, Col, Container, Button, FormLabel, Dropdown, DropdownButton, Accordion, Card, useAccordionToggle, AccordionContext } from 'react-bootstrap';
import { PatchCheckFll } from 'react-bootstrap-icons';
import GoogleMap from 'google-map-react';
import ReactPaginate from 'react-paginate';

import Settings from "../../services/settings";
import preferredPin from "../../assets/img/icons/pin/Map_Pin_Blue_FEP.png"
import otherPin from "../../assets/img/icons/pin/Map_Pin_DarkBlue_FEP.png"


const ProviderSearchResultsMapView = (props) => {
    const googleApiKey = Settings.getGoogleApiKey();

    const [providers, setProviders] = useState([]);
    const [distanceUnit, setDistanceUnit] = useState('mi');
    const [providersForDisplay, setProvidersForDisplay] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [showPreferredProviders, setShowPreferredProvider] = useState(false);
    const [searchByType, setSearchByType] = useState('');
    const [numPerPage, setNumPerPage] = useState(10);
    const [centerCoordinates, setCenterCoordinates] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    const refList = useRef([]);
    const accordionRefList = useRef([]);
    const [mapKey, setMapKey] = useState(0);
    const [allSelected, setAllSelected] = useState(false);
    const [showVOB, setShowVOB] = useState(false);
    const [showSpanishLabels, setShowSpanishLabels] = useState(false);

    const defaultProps = {
        zoom: 10
    };

    useEffect(() => {
        var numPerPageStorage = localStorage.getItem(Settings.getNumPerPageKey());
        if (numPerPageStorage && numPerPageStorage !== '') {
            setNumPerPage(parseInt(numPerPageStorage));
        }
    }, []);

    useEffect(() => {
        setProviders(props.providerDetails);
        setProvidersForDisplay(props.providerDetails.slice(0, numPerPage).map(obj => ({ ...obj, show: false, checked: false })));
        setDistanceUnit(props.distanceUnit);
        setSelectedProviders([]);
        setPageCount(Math.ceil(props.providerDetails.length / numPerPage));
        setSelectedPage(0);
        setMapKey(mapKey + 1);

        if (props.providerDetails.length > 0) {
            setCenterCoordinates({
                lat: props.providerDetails[0].latitude,
                lng: props.providerDetails[0].longitude
            });
        }

        refList.current = refList.current.slice(0, numPerPage);
        accordionRefList.current = accordionRefList.current.slice(0, numPerPage);
    }, [props.providerDetails]);

    useEffect(() => {
        setDistanceUnit(props.distanceUnit);
    }, [props.distanceUnit]);

    useEffect(() => {
        setSearchByType(props.searchByType);
    }, [props.searchByType]);

    useEffect(() => {
        setShowPreferredProvider(props.showPreferredProviders);
    }, [props.showPreferredProviders]);

    useEffect(() => {
        setProvidersForDisplay(props.providerDetails.slice(0, numPerPage).map(obj => ({ ...obj, show: false, checked: false })));
        setMapKey(mapKey + 1);
        setSelectedProviders([]);
        setPageCount(Math.ceil(props.providerDetails.length / numPerPage));
        refList.current = refList.current.slice(0, numPerPage);
        accordionRefList.current = accordionRefList.current.slice(0, numPerPage);
        localStorage.setItem(Settings.getNumPerPageKey(), numPerPage);
    }, [numPerPage]);

    useEffect(() => {
        if (props.providerSearchInfo) {
            setShowVOB(props.providerSearchInfo.isVOB);
        }
    }, [props.providerSearchInfo]);

    useEffect(() => {
        setShowSpanishLabels(props.selectedNetwork.indexOf('aetnaLDS') !== -1);
    }, [props.selectedNetwork]);

    const onPageSizeUpdated = (numPerPage) => {
        setNumPerPage(parseInt(numPerPage));
        setSelectedPage(0);
        setProvidersForDisplay(providers.slice(0, numPerPage).map(obj => ({ ...obj, show: false, checked: false })));
        setMapKey(mapKey + 1);
    }

    const printForm = (e) => {
        e.preventDefault();

        window.print();
    }

    const handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * numPerPage);
        setSelectedPage(selected);
        setProvidersForDisplay(props.providerDetails.slice(offset, offset + numPerPage));
        setMapKey(mapKey + 1);
    };

    const toggleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedProviders(providersForDisplay);
            setAllSelected(true);

            providersForDisplay.forEach((provider) => {
                provider.checked = true;
            });
        } else {
            setSelectedProviders([]);
            setAllSelected(false);

            providersForDisplay.forEach((provider) => {
                provider.checked = false;
            });
        }
    }

    const handleApiLoaded = (map, maps, providers) => {
        const bounds = getMapBounds(map, maps, providers);
        map.fitBounds(bounds);
    }

    const getMapBounds = (map, maps, providers) => {
        const bounds = new maps.LatLngBounds();

        providers.forEach((provider) => {
            bounds.extend(new maps.LatLng(
                provider.latitude,
                provider.longitude,
            ));
        });
        return bounds;
    };

    const getPageResultCount = () => {
        const upperLimit = (selectedPage * numPerPage) + parseInt(numPerPage);
        if (upperLimit < providers.length) {
            return upperLimit;
        } else {
            return providers.length;
        }
    }

    const InfoWindow = (props) => {
        const { provider } = props;
        const infoWindowStyle = {
            position: 'absolute',
            bottom: 55,
            left: '-90px',
            width: 180,
            backgroundColor: 'white',
            boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
            padding: 10,
            fontSize: 14,
            zIndex: 100,
        };

        const closeWindow = () => {
            provider.show = false;
            const center = {
                lat: provider.latitude,
                lng: provider.longitude
            }
            setCenterCoordinates(center);
        }

        return (
            <div style={infoWindowStyle}>
                <button type="button" className="close" aria-label="Close" onClick={closeWindow}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <div>
                    {provider.name}
                </div>
            </div>
        );
    };

    const Marker = ({ provider, index }) => {
        const markerStyle = {
            cursor: 'pointer',
            zIndex: 100,
            position: 'absolute',
            height: '48px',
            width: '48px',
            bottom: '10%',
            right: '-24px',
        };

        const spanStyle = {
            position: 'absolute',
            right: '0',
            top: '8px',
            fontSize: '11px',
            textAlign: 'center',
            width: '100%',
        }

        const handleClick = () => {
            provider.show = !provider.show;
            refList.current[index].click();
            const center = {
                lat: provider.latitude,
                lng: provider.longitude
            }
            setCenterCoordinates(center);
        }

        return (
            <>
                <div title={provider.name} onClick={handleClick} style={markerStyle}>
                    <img alt="" src={provider.preferred ? preferredPin : otherPin} draggable="false" />
                    <span style={spanStyle}>{index + 1}</span>
                </div>
                { provider.show && <InfoWindow provider={provider} />}
            </>
        );
    };

    const ProviderDetail = ({ icon, label, value }) => {
        return (
            <>
                { value &&
                    <p>
                        {icon && <span><i className={icon} /></span>}
                        {label && label + ':'} {value}
                    </p>
                }
            </>
        );
    }

    const ContextAwareToggle = ({ children, eventKey, callback, provider }) => {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const selectForPrint = (e) => {
            if (e.target.checked) {
                provider.checked = true;
                setSelectedProviders([...selectedProviders, provider]);
                return true;
            } else {
                setSelectedProviders(selectedProviders.filter(x => x.id !== provider.id));
                provider.checked = false;
                setAllSelected(false);
                return true;
            }
        }

        const getProviderHeader = (provider) => {
            var displayName = provider.name;

            if (props.selectedNetwork.indexOf("publicDomestic") !== -1) {
                return displayName;
            } else {
                if (provider.tin) {
                    return displayName + ' (Tax ID: ' + provider.tin + ')';
                } else {
                    return displayName + ' (Provider ID: ' + provider.id + ')';
                }
            }
        }

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <Row onClick={decoratedOnClick}>
                <Col md="1" className="text-nowrap">
                    <i
                        ref={el => refList.current[eventKey - 1] = el}
                        className={isCurrentEventKey ? 'fa fa-minus-square' : 'fa fa-plus-square'}
                    ></i>
                    <div className="markerPin">
                        <p className="map-view-header">{eventKey}</p>
                    </div>
                </Col>
                <Col md="10">
                    <h3 className="map-view-header">{getProviderHeader(provider)} {provider.preferred && <PatchCheckFll className="g-color-gmmi-blue preferredProvider" size={19} />}</h3>
                </Col>
                <Col md="1">
                    <input type="checkbox" className="selection-input-4" onClick={(e) => e.stopPropagation()} onChange={selectForPrint} checked={provider.checked} style={{ float: "right" }} />
                </Col>
            </Row>
        );
    }

    const launchFeedback = (provider) => {
        props.onProviderFeedbackOpen(provider);
    }

    const generateDirectionsLink = (provider) => {
        return "https://www.google.com/maps/dir/?api=1&destination=" + encodeURI(provider.name) + "%20" + encodeURI(provider.addressDisplay);
    }

    const createVOB = (provider) => {
        props.onCreateVOBOpen(provider);
    }

    const launchEmail = () => {
        if (selectedProviders.length > 0) {
            props.onEmailProvidersOpen(selectedProviders);
        }
    }

    return (
        <>
            { providers.length > 0 &&
                <>
                    <Row>
                        <Col md="6" className="mapViewSettings">
                            <Row className="hide-for-print">
                                <Col md="5">
                                    <Dropdown className="d-inline-block">
                                        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                                            {numPerPage} per page
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="10" onSelect={onPageSizeUpdated}>10</Dropdown.Item>
                                            <Dropdown.Item eventKey="25" onSelect={onPageSizeUpdated}>25</Dropdown.Item>
                                            <Dropdown.Item eventKey="50" onSelect={onPageSizeUpdated}>50</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <p className="d-inline-block ml-1">Showing {selectedPage * numPerPage + 1} to {getPageResultCount()} of {providers.length} entries</p>
                                </Col>
                                <Col md="7">
                                    <Row>
                                        <Col md="8">

                                            <nav aria-label="Map View Pagination">
                                                <ReactPaginate
                                                    forcePage={selectedPage}
                                                    previousLabel={'previous'}
                                                    nextLabel={'next'}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    nextLinkClassName={'page-link'}
                                                    previousLinkClassName={'page-link'}
                                                />
                                            </nav>
                                        </Col>
                                        <Col md="4">
                                            <div className="float-right print-container">
                                                {props.selectedNetwork.indexOf('domesticLocal') >= 0 &&
                                                    <button className="mr-2" onClick={launchEmail} id="emailProvidersButton" title="Email Selected"><i className="fa fa-envelope"></i></button>
                                                }
                                                <button onClick={printForm} id="print" title="Print Selected"><i className="fa fa-print"></i></button>
                                                <input type="checkbox" className="selection-input-4" onChange={toggleSelectAll} checked={allSelected} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            {showPreferredProviders &&
                                <>
                                    <img alt="" src={preferredPin} draggable="false" height={36} width={36} /> <span className="mr-4">{showSpanishLabels ? "Preferred Provider/Proveedores Preferidos" : "Preferred Provider"}</span>
                                    <img alt="" src={otherPin} draggable="false" height={36} width={36} /> <span className="mr-4">{showSpanishLabels ? "Other Provider/Otros Proveedores" : "Other Provider"}</span>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className="mapViewTable">
                            <div className="accordionWrapper">
                                {
                                    providersForDisplay.map((provider, index) => {
                                        return (
                                            <Accordion key={provider.hash} data-checked={provider.checked}>
                                                <Card>
                                                    <Card.Header>
                                                        <ContextAwareToggle eventKey={Math.ceil(selectedPage * numPerPage) + index + 1} provider={provider} />
                                                    </Card.Header>
                                                    <Accordion.Collapse ref={el => accordionRefList.current[Math.ceil(selectedPage * numPerPage) + index] = el} eventKey={Math.ceil(selectedPage * numPerPage) + index + 1} >
                                                        <Card.Body>
                                                            <Row className="providerRow providerHeader d-none d-md-flex">
                                                                <Col md="6">
                                                                    <h4>{showSpanishLabels ? 'Provider Contact/Proveedor Información' : 'Provider Contact'}</h4>
                                                                </Col>
                                                                <Col md="6">
                                                                    <h4>{showSpanishLabels ? 'Provider Details/Proveedor Detalles' : 'Provider Details'}</h4>
                                                                </Col>
                                                            </Row>
                                                            <Row className="providerRow providerDetails">
                                                                <Col md="6">
                                                                    <ProviderDetail icon="fa fa-thumb-tack" value={provider.addressDisplay} />
                                                                    <ProviderDetail icon="fa fa-phone" value={provider.phone} />
                                                                    <ProviderDetail icon="fa fa-fax" value={provider.fax} />
                                                                    <p>
                                                                        <span><i className="fa fa-car" /></span>
                                                                        <a target="_blank" href={generateDirectionsLink(provider)}>{provider.distance > 0.00 ? provider.distance.toFixed(2) + ' ' + (distanceUnit === 'mi' ? 'miles' : 'km') : 'Get Directions'}</a>
                                                                    </p>
                                                                </Col>
                                                                <Col md="6">
                                                                    {!showSpanishLabels &&
                                                                        <a className="cc-feedback" onClick={() => launchFeedback(provider)}>Leave Feedback</a>
                                                                    }
                                                                    <ProviderDetail icon="fa fa-stethoscope" label={showSpanishLabels ? "Provider Type/Tipo de Proveedor" : "Provider Type"} value={provider.providerType} />
                                                                    <ProviderDetail icon="fa fa-h-square" label={showSpanishLabels ? "Specialty/Especialidad Del Proveedor" : "Specialty"} value={provider.specialty} />
                                                                    {(props.selectedNetwork.indexOf('sagicor') === -1 && props.selectedNetwork.indexOf('publicInternational') === -1) &&
                                                                        <ProviderDetail icon="fa fa-dollar" label="Network Code" value={provider.networkCode} />
                                                                    }
                                                                    {props.selectedNetwork.indexOf("publicDomestic") === -1 &&
                                                                        <ProviderDetail icon="fa fa-id-badge" label="Tax ID" value={provider.tin} />
                                                                    }
                                                                    {showVOB &&
                                                                        <p><a className="create-vob-mapview" onClick={() => createVOB(provider)}>Create VOB</a></p>
                                                                    }
                                                                    {showSpanishLabels &&
                                                                        <p><a className="" style={{ cursor: "pointer" }} onClick={() => launchFeedback(provider)}>Leave Feedback/Comentarios</a></p>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        )
                                    })
                                }
                            </div>
                            <nav aria-label="Map View Pagination" className="hide-for-print">
                                <ReactPaginate
                                    forcePage={selectedPage}
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    nextLinkClassName={'page-link'}
                                    previousLinkClassName={'page-link'}
                                />
                            </nav>
                        </Col>
                        <Col md="6" className="browser-map">
                            <div style={{ height: '600px' }}>
                                <GoogleMap
                                    bootstrapURLKeys={{ key: googleApiKey }}
                                    key={mapKey}
                                    center={centerCoordinates}
                                    defaultZoom={defaultProps.zoom}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, providersForDisplay)}
                                    options={{ zoomControl: true, scrollwheel: true, maxZoom: 18 }}
                                >
                                    {
                                        providersForDisplay.map((provider, index) => (
                                            <Marker
                                                key={provider.hash}
                                                lat={provider.latitude}
                                                lng={provider.longitude}
                                                provider={provider}
                                                index={Math.ceil(selectedPage * numPerPage) + index}
                                                style={{ position: 'absolute' }}
                                            />
                                        ))
                                    }
                                </GoogleMap>
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </>
    );
}

export default ProviderSearchResultsMapView;