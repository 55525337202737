import React, { useEffect, useState } from "react";
import { Layout } from './Layout';
import { useAuth } from "../providers/authProvider";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Settings from '../services/settings';
import logoImage from "../assets/img/GMMI_Building_Picture_Web.gif"
import ClientConfigurationService from "../services/clientConfigurationService";
import CustomerService from "./CustomerService/CustomerService";
import StrapiService from '../services/strapiService';
import ReactNotification from 'react-notifications-component'

const ClientHome = (props) => {

  const { token, clientId } = useAuth();
  const history = useHistory();
  let isPreviousClient = false;
  const location = useLocation();
  const [selectedClient, setSelectedClient] = useState('');
  const [homePageContent, setHomePageContent] = useState('');

  useEffect(() => {
    if (location && location.state) {
      isPreviousClient = location.state?.loadPrevious;
      setSelectedClient(location.state?.currentClient);
      localStorage.setItem(Settings.getCurrentClientKey(), JSON.stringify(location.state?.currentClient));
    } else {
      const currentClient = localStorage.getItem(Settings.getCurrentClientKey());
      if (currentClient) {
        setSelectedClient(JSON.parse(currentClient));
      }
    }
  }, [location]);

  useEffect(() => {
    if (localStorage) {

    }
  }, []);

  useEffect(() => {
    if (clientId && token) {
      StrapiService.getClientHomePage(token, function (data) {
        if (data) {
          setHomePageContent(data);
        }
      })
      //Check if user is GMMI
      const valor = process.env.REACT_APP_GMMI_CLIENT_ID;
      if (process.env.REACT_APP_GMMI_CLIENT_ID == clientId) {
        //If equals go to client selector
        localStorage.setItem(Settings.getIsGMMIKey(), true);

        const clientProfile = localStorage.getItem(Settings.getClientConfigurationKey());
        var recentClientsJson = localStorage.getItem(Settings.getCurrentClientKey());

        if (!recentClientsJson || !clientProfile) {
          let path = `/client-selector`;
          history.push(path);
        }
      }
      else {
        //Load current client configuration
        ClientConfigurationService.getClientConfiguration(clientId, function (data) {
          if (data) {
            //save client
            const currentClient = {
              id: data.clientId,
              label: data.clientName
            };
            localStorage.setItem(Settings.getCurrentClientKey(), JSON.stringify(currentClient));
            setSelectedClient(currentClient);
          }
        });
      }
    }
  }, [token, clientId])

  return (
    <>
      <Layout selectedClient={selectedClient}>
        <ReactNotification />
        <div className="g-ml-25">
          <h1></h1>
        </div>
        <section className="g-bg-cover g-bg-pos-center g-bg-img-hero g-bg-black-opacity-0_3--after g-flex-centered g-py-150" style={{ backgroundImage: "url(" + logoImage + ")" }}>
          <div className="container g-bg-cover__inner">
            <div className="row">
              <div className="col-md-5 align-self-center g-overflow-hidden g-mb-30 g-mb-0--md">
                <h2 className="g-color-white g-fontSize-30 g-fontSize-45--md text-uppercase g-line-height-1 g-mb-5 animated fadeInUp delay-0-2s duration-1-5s">
                </h2>
                <h3 className="g-color-primary g-font-weight-700 g-font-size-40 g-font-size-60--md text-uppercase g-line-height-1 g-mb-20 animated fadeInLeft delay-5s duration-2s">
                  <p style={{ color: 'white' }}>Welcome to GMMI Online</p>
                </h3>
                <p className="g-color-white-opacity-0_8 g-fontSize-28 g-mb-15 animated fadeInUp delay-1-1s duration-1-5s">
                </p>
              </div>
              <div className="col-md-7 g-overflow-hidden">
                <div className="g-brd-around g-brd-7 g-brd-white animated fadeInRight delay-1-7s duration-1-5s">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Lf-UWhqSyrs" frameBorder="0" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="hello-world">
          <div style={{ padding: '40px' }}>
            <div dangerouslySetInnerHTML={{ __html: homePageContent?.content }}></div>
          </div></div>
        <CustomerService></CustomerService>
      </Layout >
    </>
  );
}

export default ClientHome;