import ProviderSearch from "../ProviderSearch";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import StrapiService from '../../../services/strapiService';
import { EmptyLayout } from "../../Layout";

const DomesticLocalProviderSearch = (props) => {
    const [pageContent, setPageContent] = useState('');

    const ipList = [
        "127.0.0.1",
        "52.249.182.87", // Azure
        "170.55.2.30", // GMMI HQ Primary
        "12.108.235.194", // GMMI HQ Secondary
        "208.127.91.188", // NEW GMMI IP
        "73.191.100.243",
        "184.96.20.32",
        "13.92.92.159",
        "40.87.71.58",
        "52.234.178.200",
        "96.245.157.247", // Dane
        "73.191.100.243", // Rob
        "69.140.3.28", // Rob
        "190.113.84.132", // Nelson
        "40.88.151.230",  // Nelson VM
        "52.152.224.151",
        "2601:154:c101:5738:699f:c87e:5778:137",
        "2601:144:c200:75:30fb:9424:88d0:4b56",
        "2601:147:4580:67f2:1814:214:e46:f007"

    ];

    const [isRestricted, setIsRestricted] = useState(true);
    const [ip, setIP] = useState("");

    const getIpData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
    };

    useEffect(() => {
        getIpData();
        if (localStorage) {
            const url = window.location.href.toLowerCase();
            const splitUrl = url.split('/');
            const urlSlug = splitUrl.pop();
            StrapiService.getProviderSearchDetail(urlSlug, "", function (data) {
                if (data) {
                    setPageContent(data);
                }
            })
        }
    }, []);

    useEffect(() => {
        //Check if the user is restricted 
        if (ip) {
            if (ipList.indexOf(ip) === -1) {
                setIsRestricted(true);
            }
            else {
                setIsRestricted(false);
            }
        }
    }, [ip]);

    return (
        <EmptyLayout >
            <div className="search-body g-mx-25">
                <div>
                    <div className="u-heading-v5-3 g-mb-20 u-heading-v5-color-gray-light-v2 text-center">
                        <h2 className="h3 text-uppercase u-heading-v5__title g-color-gmmi-blue">
                            GMMI Local Provider Search
                        </h2>
                    </div>
                </div>
                <div className="lead g-mb-40">
                    <div dangerouslySetInnerHTML={{ __html: pageContent?.content }}></div>
                </div>
            </div>
            {isRestricted
                ?
                <div>
                    <center>Access Restricted</center></div>
                : <ProviderSearch />
            }
        </EmptyLayout>
    );
}

export default DomesticLocalProviderSearch;