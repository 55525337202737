import AuthService from './authService';
import ErrorDisplay from './errorDisplay';
import ClientConfigurationService from './clientConfigurationService'
import axios from 'axios';

const PatientSearchService = {
    getSearchTypes: () => {
        return [
            {
                code: "NAMEL",
                name: "Patient Last Name"
            },
            {
                code: "DOB",
                name: "Date of Birth"
            },
            {
                code: "POLICY",
                name: "Policy #"
            },
            {
                code: "ITN",
                name: "ITN #"
            },
            {
                code: "CLAIMNO",
                name: "Incident #"
            }
        ];
    },

    getPatients: (data, cancelToken, callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.post('/api/patients', data, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.patients);
            }).catch((error => {
                if (axios.isCancel(error)) {
                    console.log("Request canceled.")
                } else {
                    ErrorDisplay.renderError('An error occurred retrieving patient results');
                    callback([]);
                }
            }));
        });
    },

    getCurrentActivity: (data, cancelToken, callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.post('/api/currentActivity', data, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.patients);
            }).catch((error => {
                if (axios.isCancel(error)) {
                    console.log("Current activity request canceled.")
                } else {
                    ErrorDisplay.renderError('An error occurred retrieving current activity results');
                    callback([]);
                }
            }));
        });
    },

    getAvailableClients: (callback) => {
        ClientConfigurationService.getClientConfiguration((data) => {
            callback(data.clientCodes);
        });
    },

    getMedicalRecords: (data, callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.post('/api/medicalrecords', data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.medicalRecords);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving patient medical records');
                callback([]);
            }));
        });
    },

    getCaseDocuments: (data, callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.post('/api/casedocuments', data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.caseDocuments);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving patient case documents');
                callback([]);
            }));
        });
    },
    
    getBills: (data, callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.post('/api/bills', data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                console.log(res);
                callback(res.data.bills);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving patient billing information');
                callback([]);
            }));
        });
    },

    getNotes: (data, callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.post('/api/notes', data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                console.log(res);
                callback(res.data.notes);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving patient notes');
                callback([]);
            }));
        });
    }
}

export default PatientSearchService;