import React from 'react';
import { Route, Switch  } from 'react-router-dom';
import { MsalProvider } from "@azure/msal-react";
import './custom.css';
import { ProviderSearchWrapper, DomesticLocalProviderSearch } from "./components/ProviderSearch";
import PublicProviderSearchWrapper from "./components/ProviderSearch/Wrappers/PublicProviderSearchWrapper";
import Token from './components/Token';
import AuthProvider from "./providers/authProvider";
import ClientSelect from './components/ClientSelect/ClientSelect';
import { AccountManagement, Home, Logout, PdfRenderError } from './components';
import ErrorPage from './components/ErrorPage';
import ClientHome from './components/ClientHome';
import { PatientSearch } from './components/PatientSearch';
import { Reports } from './components/Reports';
import jQuery from 'jquery';
window.jQuery = jQuery;
import './assets/vendor/hs-megamenu/src/hs.megamenu.js'
import './assets/vendor/popper.min.js'
import './assets/vendor/bootstrap/bootstrap.min.js'
import './assets/vendor/bootstrap/offcanvas.js'
import './assets/js/hs.core.js'
import './assets/js/components/hs.header.js'
import './assets/js/helpers/hs.hamburgers.js'
import './assets/js/components/hs.go-to.js'
import './assets/js/app/app.js'
import './assets/vendor/appear.js'
import './assets/vendor/hs-megamenu/src/hs.megamenu.css';
import { ClientMember, ClientMemberLogin } from './components/ClientMember';

const App = ({ pca }) => {
  return (    
    <Switch>
      <>
        <Route path="/domestic-provider-search">
          <PublicProviderSearchWrapper title="Domestic Provider Search" />
        </Route>          
        <Route path="/domestic-local-provider-search">
          <DomesticLocalProviderSearch/>
        </Route>
        <Route path="/international-provider-search">
          <PublicProviderSearchWrapper title="International Provider Search"/>
        </Route>
        <Route path="/sagicor-provider-search">
          <PublicProviderSearchWrapper title="GLOBAL PROVIDER SEARCH"/>
        </Route>
        <Route path="/aetna-international-provider-search">
          <PublicProviderSearchWrapper title="AETNA LDS PROVIDER SEARCH"/>
        </Route>          
        <Route path="/no-data">
          <PdfRenderError />
        </Route>
        <Route path="/client-member-page/login">
          <ClientMemberLogin/>
        </Route>
        <Route path="/client-member-page">
            <ClientMember/>
        </Route>        
        <Route path="/error">
          <ErrorPage />
        </Route>
        <MsalProvider instance={pca}>
          <AuthProvider>
            <Route path="/current-activity">
              <PatientSearch />
            </Route>
            <Route path="/patient-search">
              <PatientSearch />
            </Route>
            <Route path="/domestic-secure-provider-search">
              <ProviderSearchWrapper title="Secure Domestic Provider Search" secure={true} />
            </Route>
            <Route path="/token">
              <Token />
            </Route>
            <Route path="/client-selector">
              <ClientSelect />
            </Route>            
            <Route path="/account-management">
              <AccountManagement/>
            </Route>
            <Route path="/reports">
              <Reports />
            </Route>
              <Route path="/logout">
            <Logout />
              </Route>
            <Route path="/home">
              <ClientHome />
            </Route> 
            
                   
            <Route exact path="/">
              <Home />
            </Route>
          </AuthProvider>
        </MsalProvider>   
      </>
    </Switch>
  );
};

export default App;