import AuthService from './authService';
import ErrorDisplay from './errorDisplay';
import ClientConfigurationService from './clientConfigurationService';

import axios from 'axios';

function isProviderTypeAnonymous(providerSearchType) {
    if (providerSearchType.toLowerCase().indexOf('securedomestic') !== -1) {
        return false;
    }
    return true;
}

const ProviderSearchService = {

    getProviderSearchInfo: (url, pathname, callback) => {
        if (url.indexOf('domestic-secure') !== -1) {
            ClientConfigurationService.getClientConfiguration(function(data) {
                if (data) {
                    var providerSearches = data.providerSearches;
    
                    for (var i = 0; i < providerSearches.length; i++) {
                        var current = providerSearches[i];
                        if (current.url === pathname) {
                            callback(current);
                            return;
                        }
                    }

                    window.location = '/';
                } else {
                    window.location = '/';
                }
            });
        } else {
            AuthService.getAuthToken(true, (token) => {
                axios.get('/api/info/' + url, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(res => {
                    callback(res.data);
                }).catch((error => {
                    ErrorDisplay.renderError('An error occurred retrieving provider search info');
                }));
            });
        } 
    },

    getProviderTypeHeader: () => {
        // read from url
        return "SagicorCore";
    },

    getSagicorNetworks: () => {
        return [
            {
                code: "SagicorCore",
                name: "Core Network"
            },
            {
                code: "SagicorInternational",
                name: "Global Network"
            }
        ];
    },

    getCountries: (providerSearchType, providerSearchCode, callback) => {
        if (providerSearchType === "") {
            return
        }

        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.get('/api/countries', {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.results);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving countries');
            }));
        });
    },

    getStates: (providerSearchType, providerSearchCode, countryId, callback) => {
        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.get('/api/states/' + countryId, {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.results);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving states');
            }));
        });
    },

    getCities: (providerSearchType, providerSearchCode, countryId, stateId, callback) => {
        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.get('/api/cities/' + countryId + '/' + stateId, {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.results);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving cities');
            }));
        });
    },

    getProviderTypes: (providerSearchType, providerSearchCode, stateId, callback) => {
        if (providerSearchType === "") {
            callback([]);
        }


        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.get('/api/providertypes/' + stateId, {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.results);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving provider types');
            }));
        });
    },

    getProviders: (providerSearchType, providerSearchCode, data, cancelToken, callback) => {
        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.post('/api/providers', data, {
                cancelToken: cancelToken.token,
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.results);
            }).catch((error => {
                if (axios.isCancel(error)) {
                    console.log("Request canceled.")
                } else {
                    ErrorDisplay.renderError('An error occurred retrieving providers');
                }
            }));
        });
    },

    sendFeedback: (providerSearchType, providerSearchCode, data, callback) => {
        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.post('/api/sendfeedback', data, {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred sending feedback');
            }));
        });
    },

    getVOBData: (providerSearchType, providerSearchCode, data, callback) => {
        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.post('/api/getVOBData', data, {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred creating VOB');
            }));
        });
    },

    getProviderTypesWithState: (providerSearchType, providerSearchCode, stateId, callback) => {
        if (providerSearchType === "") {
            callback([]);
        }


        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.get('/api/providertypes/' + stateId, {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.results);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving provider types');
            }));
        });
    },

    getVOBPreview: (providerSearchType, providerSearchCode, data, callback) => {
        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.post('/api/VOBPreview', data, {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                },
                responseType: 'blob'
            }).then(res => {
                callback(res.data);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred creating VOB Preview');
            }));
        });
    },

    emailProviders: (providerSearchType, providerSearchCode, data, callback) => {
        AuthService.getAuthToken(isProviderTypeAnonymous(providerSearchType), (token) => {
            axios.post('/api/emailProviders', data, {
                headers: {
                    'X_PROVIDER_TYPE': providerSearchType,
                    'X_PROVIDER_CODE': providerSearchCode,
                    'Authorization': 'Bearer ' + token
                },
            }).then(res => {
                callback(res.data);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred sending providers email');
            }));
        });
    },

    getDomesticStates: () => {
        var stateList = [
            {
                name: "Alabama",
                abbreviation: "AL",
                id: "AL",
                showDisclaimer: false
            },
            {
                name: "Alaska",
                abbreviation: "AK",
                id: "AK",
                showDisclaimer: false
            },
            {
                name: "Arizona",
                abbreviation: "AZ",
                id: "AZ",
                showDisclaimer: false
            },
            {
                name: "Arkansas",
                abbreviation: "AR",
                id: "AR",
                showDisclaimer: false
            },
            {
                name: "California",
                abbreviation: "CA",
                id: "CA",
                showDisclaimer: false
            },
            {
                name: "Colorado",
                abbreviation: "CO",
                id: "CO",
                showDisclaimer: false
            },
            {
                name: "Connecticut",
                abbreviation: "CT",
                id: "CT",
                showDisclaimer: false
            },
            {
                name: "Delaware",
                abbreviation: "DE",
                id: "DE",
                showDisclaimer: false
            },
            {
                name: "District of Columbia",
                abbreviation: "DC",
                id: "DC",
                showDisclaimer: false
            },
            {
                name: "Florida",
                abbreviation: "FL",
                id: "FL",
                showDisclaimer: false
            },
            {
                name: "Georgia",
                abbreviation: "GA",
                id: "GA",
                showDisclaimer: false
            },
            {
                name: "Hawaii",
                abbreviation: "HI",
                id: "HI",
                showDisclaimer: false
            },
            {
                name: "Idaho",
                abbreviation: "ID",
                id: "ID",
                showDisclaimer: false
            },
            {
                name: "Illinois",
                abbreviation: "IL",
                id: "IL",
                showDisclaimer: false
            },
            {
                name: "Indiana",
                abbreviation: "IN",
                id: "IN",
                showDisclaimer: false
            },
            {
                name: "Iowa",
                abbreviation: "IA",
                id: "IA",
                showDisclaimer: false
            },
            {
                name: "Kansas",
                abbreviation: "KS",
                id: "KS",
                showDisclaimer: false
            },
            {
                name: "Kentucky",
                abbreviation: "KY",
                id: "KY",
                showDisclaimer: false
            },
            {
                name: "Louisiana",
                abbreviation: "LA",
                id: "LA",
                showDisclaimer: false
            },
            {
                name: "Maine",
                abbreviation: "ME",
                id: "ME",
                showDisclaimer: false
            },
            {
                name: "Maryland",
                abbreviation: "MD",
                id: "MD",
                showDisclaimer: false
            },
            {
                name: "Massachusetts",
                abbreviation: "MA",
                id: "MA",
                showDisclaimer: false
            },
            {
                name: "Michigan",
                abbreviation: "MI",
                id: "MI",
                showDisclaimer: false
            },
            {
                name: "Minnesota",
                abbreviation: "MN",
                id: "MN",
                showDisclaimer: false
            },
            {
                name: "Mississippi",
                abbreviation: "MS",
                id: "MS",
                showDisclaimer: false
            },
            {
                name: "Missouri",
                abbreviation: "MO",
                id: "MO",
                showDisclaimer: false
            },
            {
                name: "Montana",
                abbreviation: "MT",
                id: "MT",
                showDisclaimer: false
            },
            {
                name: "Nebraska",
                abbreviation: "NE",
                id: "NE",
                showDisclaimer: false
            },
            {
                name: "Nevada",
                abbreviation: "NV",
                id: "NV",
                showDisclaimer: false
            },
            {
                name: "New Hampshire",
                abbreviation: "NH",
                id: "NH",
                showDisclaimer: false
            },
            {
                name: "New Jersey",
                abbreviation: "NJ",
                id: "NJ",
                showDisclaimer: false
            },
            {
                name: "New Mexico",
                abbreviation: "NM",
                id: "NM",
                showDisclaimer: false
            },
            {
                name: "New York",
                abbreviation: "NY",
                id: "NY",
                showDisclaimer: false
            },
            {
                name: "North Carolina",
                abbreviation: "NC",
                id: "NC",
                showDisclaimer: false
            },
            {
                name: "North Dakota",
                abbreviation: "ND",
                id: "ND",
                showDisclaimer: false
            },
            {
                name: "Ohio",
                abbreviation: "OH",
                id: "OH",
                showDisclaimer: false
            },
            {
                name: "Oklahoma",
                abbreviation: "OK",
                id: "OK",
                showDisclaimer: false
            },
            {
                name: "Oregon",
                abbreviation: "OR",
                id: "OR",
                showDisclaimer: false
            },
            {
                name: "Pennsylvania",
                abbreviation: "PA",
                id: "PA",
                showDisclaimer: false
            },
            {
                name: "Rhode Island",
                abbreviation: "RI",
                id: "RI",
                showDisclaimer: false
            },
            {
                name: "South Carolina",
                abbreviation: "SC",
                id: "SC",
                showDisclaimer: false
            },
            {
                name: "South Dakota",
                abbreviation: "SD",
                id: "SD",
                showDisclaimer: false
            },
            {
                name: "Tennessee",
                abbreviation: "TN",
                id: "TN",
                showDisclaimer: false
            },
            {
                name: "Texas",
                abbreviation: "TX",
                id: "TX",
                showDisclaimer: false
            },
            {
                name: "Utah",
                abbreviation: "UT",
                id: "UT",
                showDisclaimer: false
            },
            {
                name: "Vermont",
                abbreviation: "VT",
                id: "VT",
                showDisclaimer: false
            },
            {
                name: "Virginia",
                abbreviation: "VA",
                id: "VA",
                showDisclaimer: false
            },
            {
                name: "Washington",
                abbreviation: "WA",
                id: "WA",
                showDisclaimer: false
            },
            {
                name: "West Virginia",
                abbreviation: "WV",
                id: "WV",
                showDisclaimer: false
            },
            {
                name: "Wisconsin",
                abbreviation: "WI",
                id: "WI",
                showDisclaimer: false
            },
            {
                name: "Wyoming",
                abbreviation: "WY",
                id: "WY",
                showDisclaimer: false
            },
            {
                name: "──────────────────────────────",
                abbreviation: "placeholder",
                id: "placeholder",
                showDisclaimer: false,
                disabled: true
            },
            {
                name: "Guam",
                abbreviation: "GU",
                id: "GU",
                showDisclaimer: true
            },
            {
                name: "Virgin Islands",
                abbreviation: "VI",
                id: "VI",
                showDisclaimer: true
            },
            {
                name: "Puerto Rico",
                abbreviation: "PR",
                id: "PR",
                showDisclaimer: true
            }
        ];

        return stateList;
    }
}

export default ProviderSearchService;